import { Component, EventEmitter, OnInit, Output,ViewChild, ElementRef } from '@angular/core';
import { select, Store } from "@ngrx/store";
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as global from "../../../shared/constants/constants";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, LocalStorageService, OrderReturnService, ValidationService } from '../../../shared/services/index';
import { ScrollingService } from '../../../shared/services/scrolling.service';
import { MatRadioChange } from '@angular/material';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-cancel-step-one',
  templateUrl: './cancel-step-one.component.html',
  styleUrls: ['./cancel-step-one.component.scss']
})
export class CancelStepOneComponent implements OnInit {
  @ViewChild('scroll') private scrollElement: ElementRef;
  isBoxshadow:boolean=false;
  scrollTopPosition:number;
  rupeeSymbol = global.RUPEE_SYMBOL;
  
  userSettings: any;
  footerKey: [string, unknown][];
  leftPanelActive = false;
  getColor: any;
  orderId: any;
  orderItemId: any;
  public cancelItemForm: FormGroup;
  cancelItemRequest: any;
  orderDetails: any;
  addressFormValue: any;
  selectedItem: any;
  originalOrderItemId: any;
  itemsArray = [];
  loading = false;
  showLoader = true;
  ifscShowLoader = false;
  public formControls: any;
  public accountTypes: any = global.ACCOUNT_TYPES;
  public cancelOrderItemDetails = LocalStorageService.get('processOrderItemDetails') ? LocalStorageService.get('processOrderItemDetails') : '';
  public bankDetailsControls = ['accountHolderName', 'accountNumber', 'confirmAccountNumber', 'ifscCode', 'bankName', 'branch', 'accountType'];
  public isHelpOpen = false;
  public isHelpOpendelay = false;
  Helpcolor: boolean;
  bankDetails: boolean;
  refundDetails: { beneficiaryName: any; beneficiaryAccountNo: any; beneficiaryIfscCode: any; beneficiaryBankName: any; beneficiaryBranch: any; beneficiaryAccountType: any; beneficiaryBankCity: any; };
  pickupAddress: {
    customerName: string; addressLine1: any; addressLine2: string; //this.formControls.addressInfo.controls.town.value,
    city: any; state: any; postalCode: any; countryCode: string; countryName: string; contactPhone: any; email: string;
  };
  showFooter: boolean;
  ifarrowTop: boolean = true;
  ifarrowBottom: boolean;
  cancelledItem: any;
  groupOtherItem = JSON.parse(localStorage.getItem('OtherItems'));
  constructor(private preventScroll:ScrollingService,private fb: FormBuilder,private store: Store<any>,private titleService: Title, private router: Router, private route: ActivatedRoute
    ,private orderReturnService: OrderReturnService, private alertService: AlertService, private deviceService: DeviceDetectorService) { }
   

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('top-header');

    this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
    this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';

     /*Get user settings data from state*/
      this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
          if (result) {
                  this.userSettings = result;
                  // console.log("userSettings",this.userSettings)
                  if(this.userSettings.footerLinks){
                    this.footerKey = Object.entries(this.userSettings.footerLinks);        
                  }       
                  this.getColor = this.userSettings.actionColor;
                  this.titleService.setTitle("Cancel Item");    
          }
      });

      /*Get Order Data*/
    this.store.select(state => state.orderPlaced.orderPlaced).subscribe(result =>   {
      if (result) {
              this.orderDetails = result;
              this.addressFormValue = this.orderDetails ? this.orderDetails.shippingAddress : '';   
              this.cancelledItem = this.orderDetails['items'].filter(itm=> itm.orderItemID === this.orderItemId) 
      }
    })

      this.initializeForm();
      
  }

  public onSelectShowOrderItemDetail(item) {
    this.selectedItem=item;   
    if(this.selectedItem.originalOrderItemId && this.selectedItem.originalOrderItemId>0){
      this.originalOrderItemId=this.selectedItem.originalOrderItemId;
    }
  }
  
  /**
   * Method to define cancelItemForm info form group
   * @method initializeForm
   */
   private initializeForm() {
    this.cancelItemForm = this.fb.group({
      // cancelReason: ['', [Validators.required]],
      additionalReason:[''],
      refundMode: ['originalPaymentMode', [Validators.required]],
      accountHolderName: [''],
      accountNumber: [''],
      confirmAccountNumber: [''],
      ifscCode: [''],
      bankName: [''],
      bankCity: [''],
      branch: [''],
      addressInfo: [''],
      accountType: [''],

      
    }, {
      validator: ValidationService.MustMatch('accountNumber', 'confirmAccountNumber')
    });
    this.formControls = this.cancelItemForm.controls;
    // console.log("formControls",this.formControls)
    
  }

  formInitialized(name: string, form: FormGroup) {
    this.cancelItemForm.setControl(name, form);
  }

  /**
   * form submission
   * @method onSubmit
   */
   public onSubmit(): void {
    //  console.log("this.cancelItemForm.",LocalStorageService.get('AdditionalComments'),
    //  LocalStorageService.get('cancellationReasons'),this.cancelItemForm)
     LocalStorageService.flush('cancelItemRequest');
     LocalStorageService.flush('actionType');
     const itemDetails = {
       lineItemSequenceNumber: this.cancelOrderItemDetails.lineItemSequenceNumber?this.cancelOrderItemDetails.lineItemSequenceNumber:this.selectedItem.lineItemSequenceNumber,
       orderItemID: this.cancelOrderItemDetails.orderItemID?this.cancelOrderItemDetails.orderItemID:this.selectedItem.orderItemID,
       itemID: this.cancelOrderItemDetails.itemID?this.cancelOrderItemDetails.itemID:this.selectedItem.itemID,
       quantity: this.cancelOrderItemDetails.quantity?this.cancelOrderItemDetails.quantity.toString():this.selectedItem.quantity.toString(),
       productName: this.cancelOrderItemDetails.productName?this.cancelOrderItemDetails.productName:this.selectedItem.productName,
       remark: 'cancelled by customer',
       additionalInfo: {
         customerUploadImageUrl: "",
         comment: ""
       }
     };

     if (this.formControls.accountNumber.value){
        this.refundDetails = {
          beneficiaryName: this.formControls.accountHolderName.value,
          beneficiaryAccountNo: this.formControls.accountNumber.value,
          beneficiaryIfscCode: this.formControls.ifscCode.value,
          beneficiaryBankName: this.formControls.bankName.value,
          beneficiaryBranch: this.formControls.branch.value,
          beneficiaryAccountType: this.formControls.accountType.value,
          beneficiaryBankCity: this.formControls.bankCity.value
        };
    }

    if (this.formControls.addressInfo.controls){
      this.pickupAddress = {
        "customerName": this.formControls.addressInfo.controls.firstName.value + ' ' + this.formControls.addressInfo.controls.lastName.value,
        "addressLine1": this.formControls.addressInfo.controls.address.value,
        "addressLine2": '', //this.formControls.addressInfo.controls.town.value,
        "city": this.formControls.addressInfo.controls.city.value,
        "state": this.formControls.addressInfo.controls.state.value,
        "postalCode": this.formControls.addressInfo.controls.pinCode.value,
        "countryCode": '',
        "countryName": '',
        "contactPhone": this.formControls.addressInfo.controls.mobile.value,
        "email": ''
      };
    }
    // if (this.cancelItemForm.valid) { }
    // if (this.cancelItemRequest) {
      this.cancelItemRequest = {
      actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail"): LocalStorageService.get('actorcutomername'), 
      actorType: localStorage.getItem("clientPortalemail") ? 'seller': 'buyer',
        additionalReason: LocalStorageService.get('AdditionalComments'),
        customerOrderNumber: this.orderDetails.customerOrderNumber,
        reason: LocalStorageService.get('cancellationReasons'),
        isCOD: this.orderDetails.isCOD.toString(),
        items: this.itemsArray,
        pickupAddress: this.pickupAddress ? this.pickupAddress : null,
        refundBankAccountDetails: this.refundDetails ? this.refundDetails : null,
        refundMode: this.formControls.refundMode.value,
      }

      this.itemsArray.push(itemDetails);
      // console.log("cancelItemRequest",this.cancelItemRequest)
      if(this.cancelledItem[0].latestStatus == 'intransit' || this.cancelledItem[0].latestStatus == 'packed') this.createPayloadForRtsInTransit();
      if(this.cancelItemRequest){
      this.loading = true;
      LocalStorageService.set('actionType', 'cancelProcess');
      LocalStorageService.set('cancelItemRequest', this.cancelItemRequest);
      this.orderReturnService.cancelItemsApiRequest(this.cancelItemRequest)
      .then(data => {
        // console.log("data",data)
        this.loading = false;
        LocalStorageService.set('SuccessResponseData', data);
        LocalStorageService.flush('cancelItemRequest');
        LocalStorageService.set("cancelItemRequestTrue", true);
        this.router.navigate([
          global.ROUTES.ORDER_PLACED,
          this.orderId,
          this.orderItemId
        ]);
        // this.router.navigate([global.ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
      }).catch(err => {
        this.loading = false;
        // console.log("err",err)
        this.alertService.showInfo(err.error.error.message);
        // throw err;
      });
    }

    }      
  displayLeftPanel(value){
    this.leftPanelActive = value;
  }

  createPayloadForRtsInTransit(){
    let itemDetails = {};
    let itemsArray = [];
    if(this.cancelledItem[0].latestStatus == 'packed'){
      itemDetails = {
        lineItemSequenceNumber: this.cancelledItem[0].lineItemSequenceNumber,
        orderItemID: this.cancelledItem[0].orderItemID,
        itemID: this.cancelledItem[0].itemID,
        quantity: this.cancelledItem[0].quantity.toString(),
        productName: this.cancelledItem[0].productName,
        remark: 'cancelled by customer',
        additionalInfo: {
          customerUploadImageUrl: "",
          comment: ""
        },
        latestStatus: this.cancelledItem[0].latestStatus,
        reason: LocalStorageService.get('cancellationReasons'),
      };
      itemsArray.push(itemDetails);
    } else if(this.cancelledItem[0].latestStatus == 'intransit'){
      if(this.groupOtherItem && this.groupOtherItem[0].otherItems){
        this.groupOtherItem[0].otherItems.map(obj=>{
          itemDetails = {
            lineItemSequenceNumber: obj.lineItemSequenceNumber,
            orderItemID: obj.orderItemID,
            itemID: obj.itemID,
            quantity: obj.quantity.toString(),
            productName: obj.productName,
            remark: 'cancelled by customer',
            additionalInfo: {
              customerUploadImageUrl: "",
              comment: ""
            },
            latestStatus: obj.latestStatus,
            reason: LocalStorageService.get('cancellationReasons'),
          };
          itemsArray.push(itemDetails);
        })
      }else{
        itemDetails = {
          lineItemSequenceNumber: this.cancelledItem[0].lineItemSequenceNumber,
          orderItemID: this.cancelledItem[0].orderItemID,
          itemID: this.cancelledItem[0].itemID,
          quantity: this.cancelledItem[0].quantity.toString(),
          productName: this.cancelledItem[0].productName,
          remark: 'cancelled by customer',
          additionalInfo: {
            customerUploadImageUrl: "",
            comment: ""
          },
          latestStatus: this.cancelledItem[0].latestStatus,
          reason: LocalStorageService.get('cancellationReasons'),
        };
        itemsArray.push(itemDetails);
      }
    }
    this.cancelItemRequest = {
      actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail"): LocalStorageService.get('actorcutomername'),
      actorType: localStorage.getItem("clientPortalemail") ? 'seller': 'buyer',
      additionalReason: LocalStorageService.get('AdditionalComments'),
      customerOrderNumber: this.orderDetails.customerOrderNumber,
      isCOD: this.orderDetails.isCOD.toString(),
      items: itemsArray,
      pickupAddress: this.pickupAddress ? this.pickupAddress : null,
      refundBankAccountDetails: this.refundDetails ? this.refundDetails : null,
      refundMode: this.formControls.refundMode.value,
      trackingId: this.cancelledItem[0].forwardTrackingTimeline.trackingID
    };
  }

  onScroll(){
    this.scrollTopPosition = this.scrollElement.nativeElement.scrollTop;
    if( this.scrollTopPosition > 0 ){
      this.isBoxshadow = true;
    }
    else{
      this.isBoxshadow = false;
    }
  }

  setBackToOrder(){
    this.router.navigate([global.ROUTES.ORDER_CANCEL, this.orderId, this.orderItemId]);
  }

  openHelpModal(){
    this.preventScroll.disable();
    this.isHelpOpen = !this.isHelpOpen;
    this.isHelpOpendelay = !this.isHelpOpendelay;
    document.querySelector("body").classList.add("stop-scrolling");
  }

  closeHelpModal() {
    this.isHelpOpen = false;
    // this.preventScroll.enable();
    if(!this.deviceService.isDesktop()) {
      setTimeout(() => {
        this.isHelpOpendelay = false;
      }, 350);
    } else this.isHelpOpendelay = false;
    
    document.querySelector("body").classList.remove("stop-scrolling");
  }

  mouseEnter(value){
    this.Helpcolor=true;
  }
  
  mouseLeave(value){
   this.Helpcolor=false;  
  }

  
  callIfscCodeCheck(event){
    if (event.currentTarget.value.length === 11) {
      this.ifscShowLoader = true;
      this.orderReturnService.getIfscCodeDetails(event.currentTarget.value)
      .then(data => {
        if (data){
          this.ifscShowLoader = false;
          this.formControls.bankName.setValue(data.BANK);
          this.formControls.bankCity.setValue(data.CITY);
          this.formControls.branch.setValue(data.BRANCH);
        }else{
          this.formControls.ifscCode.markAsTouched();
          this.formControls.ifscCode.setErrors({invalid: true});
          this.formControls.bankName.setValue(null);
          this.formControls.bankCity.setValue(null);
          this.formControls.branch.setValue(null);
        }
      }).catch(err => {
        this.formControls.ifscCode.markAsTouched();
        this.formControls.ifscCode.setErrors({invalid: true});
        this.formControls.bankName.setValue(null);
        this.formControls.bankCity.setValue(null);
        this.formControls.branch.setValue(null);
        this.ifscShowLoader = false;
        throw err;
      });
    }
  }

  radioChange($event: MatRadioChange){
    // console.log($event.value);

    if ($event.value === 'bank') {
        // Do whatever you want here
        this.bankDetails = true;

        if ($event.value === "bank") {
          // console.log("this.formControls",this.formControls,this.bankDetailsControls   )
          // this.showBankDetailsForm = true;
          
          // this.cancelItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
          this.formControls.accountHolderName.setValidators([Validators.required]);
          this.formControls.accountNumber.setValidators([Validators.required, Validators.pattern(global.NUMBERS_ONLY_REGEX), Validators.minLength(5), Validators.maxLength(25)]);
          this.formControls.confirmAccountNumber.setValidators([Validators.required, Validators.pattern(global.NUMBERS_ONLY_REGEX)]);
          this.formControls.ifscCode.setValidators([Validators.required, Validators.minLength(11), Validators.pattern(global.IFSC_CODE_REGEX)]);
          // this.formControls.bankName.setValidators([Validators.required]); //, Validators.pattern(global.REGX_NAME)
          // this.formControls.branch.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40)]);
          this.formControls.accountType.setValidators([Validators.required]);  
        }else{
          // this.showBankDetailsForm = false;  
          this.bankDetailsControls.forEach(element => {
            this.formControls[element].setValidators(null);
            this.formControls[element].markAsUntouched();
            this.formControls[element].patchValue('');
            this.formControls[element].setErrors(null);
          });
        }  

    }if($event.value === 'originalPaymentMode' || $event.value === 'storeCredit'){
      this.bankDetails = false;
      this.bankDetailsControls.forEach(element => {
        this.formControls[element].setValidators(null);
        this.formControls[element].markAsUntouched();
        this.formControls[element].patchValue('');
        this.formControls[element].setErrors(null);
      });
    }
  }

    /**
    * Refund Mode 
    * @method onPaymentModeChange
    */
     public onPaymentModeChange(value, onchange: boolean = false) {
      if (value === "bank") {
        // this.showBankDetailsForm = true;
        
        // this.cancelItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
        this.formControls.accountHolderName.setValidators([Validators.required]);
        this.formControls.accountNumber.setValidators([Validators.required, Validators.pattern(global.NUMBERS_ONLY_REGEX), Validators.minLength(5), Validators.maxLength(25)]);
        this.formControls.confirmAccountNumber.setValidators([Validators.required, Validators.pattern(global.NUMBERS_ONLY_REGEX)]);
        this.formControls.ifscCode.setValidators([Validators.required, Validators.minLength(11), Validators.pattern(global.IFSC_CODE_REGEX)]);
        // this.formControls.bankName.setValidators([Validators.required]); //, Validators.pattern(global.REGX_NAME)
        // this.formControls.branch.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40)]);
        this.formControls.accountType.setValidators([Validators.required]);  
      }else{
        // this.showBankDetailsForm = false;  
        this.bankDetailsControls.forEach(element => {
          this.formControls[element].setValidators(null);
          this.formControls[element].markAsUntouched();
          this.formControls[element].patchValue('');
          this.formControls[element].setErrors(null);
        });
      }  
    }

    showFooterClick(){
      this.showFooter = true;
      this.ifarrowTop = false;
      this.ifarrowBottom = true;
    }
    hideFooterClick(){
      this.ifarrowTop = true;
      this.ifarrowBottom = false;
      this.showFooter = false;
    }

}
