<div class="container" [class.left-panel-active]="leftPanelActive">
    <div class="row custom-row">
        <div class="panel-left p_panel-left display-none">
            <!-- <div class="left-panel-hide" (click)="hideLeftPanel()">
                        <figure><img src="assets/images/close.png" alt=""></figure>
                    </div> -->
            <app-order-sidebar (changeleftPanelValue)='displayLeftPanel($event)' (selectedItemData)="onSelectShowOrderItemDetail($event)"></app-order-sidebar>
        </div>
        <div class="panel-right p_panel-right mobile-move mobile-move1 mobile-panel">
            <form [ngClass]="isBulkCancel || showInfoBanner? 'bulk-cancel-form' : '' " [formGroup]="cancellationForm">
                <div class="ordered-items">
                    <div class="item-list">
                        <div class="item-info">
                            <div class="item-name">
                            <button class="close-track" (click)=" setBackToOrder()"></button>
                            <span class="order-heading">
                                <!-- <a (click)=" setBackToOrder()">
                                    <img src="../../../../assets/images/icon-arrow-left.svg" /></a> -->
                                Cancel Item
                            </span>
                            <ng-container *ngIf="(userSettings?.supportPhone && userSettings?.supportPhone!='null') || (userSettings?.supportEmail && userSettings?.supportEmail!='null') || (userSettings?.supportUrl && userSettings?.supportUrl!='null')">
                                <a  (click)="openHelpModal()" (mouseover) ="mouseEnter('addColor') "  (mouseout) ="mouseLeave('removeColor')"  [ngStyle]="{color:Helpcolor?getColor:'#828282'} ">Help ?</a>
                            </ng-container>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="left-panel-show" (click)="showLeftPanel()">
                    <figure><img src="assets/images/arrow-left.png" alt=""></figure>
                    <span> Order </span>
                </div> -->
                <div *ngIf="!isBulkCancel && !showInfoBanner" class="cancel-order-wrp scroll-content" #scroll (scroll)="onScroll()" [class.box-shadowcss]="isBoxshadow">
                    <div class="product-detail-mob" *ngIf="cancelOrderItemDetails">
                        <div class="prod-img">
                            <img src="{{ cancelOrderItemDetails && cancelOrderItemDetails.productImageUrl ? cancelOrderItemDetails.productImageUrl:'assets/images/item-image.jpg' }}"
                                alt="product image">
                        </div>
                        <div class="prod-info">
                            <h4>{{ cancelOrderItemDetails ? cancelOrderItemDetails.productName:''}}</h4>
                            <p>{{rupeeSymbol}} <Span>
                                    {{ cancelOrderItemDetails ? transformDecimal(cancelOrderItemDetails.customerPrice):''}}</Span>
                                    <!-- <span class="size-class" *ngIf="cancelOrderItemDetails.productSize">{{cancelOrderItemDetails ? cancelOrderItemDetails.productSize:''}}</span> -->
                                    <span  *ngIf="cancelOrderItemDetails.productSize"> • Size {{cancelOrderItemDetails ? cancelOrderItemDetails.productSize:''}}</span>

                            </p>
                        </div>
                    </div>
                    <div class=" cancle-order">
                        <!-- Bank Details Start -->
                        <h5>Let us know the reason for cancellation?</h5>
                        <p class="special-para">Please choose the correct reason for canceling the item. This information is used only to improve our services.</p>
                        <div class="cancel-container">
                            <mat-radio-group class="custom-radio-btn-group" formControlName="cancellationReasons">
                                <mat-radio-button (change)="radioChange(i == defaultSelected)" matInput class="custom-radio-btn" value="{{item}}" 
                                *ngFor="let item of userSettings?.cancellationReasons ; let i = index" [checked]="i == defaultSelected">
                                    {{item}}
                                </mat-radio-button>
                            </mat-radio-group>

                            <div class="additional-comments">
                                <p>Additional comments (optional)</p>
                                <mat-form-field >
                                    <textarea name="AdditionalComments"  formControlName="AdditionalComments" matInput placeholder="Enter additional comments for your reason" ></textarea>
                                    <mat-error 
                                    class="error-message" 
                                    *ngIf="formControls.AdditionalComments.invalid"
                                    >
                                    Remarks can be at most 100 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bulk-main-container" *ngIf="isBulkCancel">
                    <div class="bulk-cancel-container">
                        <div *ngFor="let item of groupOtherItem; let i = index" class="checkbox-input-conatiner">
                            <mat-checkbox (change)="radioChangeBulk($event.checked,item,i)" class="custom-radio-btn radio-style">
                                <div class="cancel-item">
                                    <ng-container *ngIf="item && item.productImageUrl">
                                        <img src="{{
                                            item.productImageUrl
                                            ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                                item.productImageUrl +
                                                '?tr=w-300'
                                            : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                                item.productImageUrl +
                                                '?tr=w-300'
                                        }}" alt="" height="70px" width="67px" onerror="style.display='none'" class="img-style" />
                                    </ng-container>
                                    <div class="bulk-item-info">
                                        <div class="item-name">
                                            {{ item ? item.productName : "" }}
                                        </div>
                                        <div class="item-price">
                                            {{ rupeeSymbol }} {{
                                            item?.invoiceTotal
                                            ? transformDecimal(
                                            item?.invoiceTotal
                                            )
                                            : transformDecimal(item?.lineItemTotal)
                                            }}
                                            <span *ngIf="item.productSize">| Size {{ item ? item.productSize : "" }}</span>
                                        </div>
                                        <div>
                                            <span class="bulk-status">{{item.latestStatus == 'intransit' ? 'In transit' : item.latestStatus == 'packed' ? 'Packed' : ''}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="other-item-container" *ngIf="item.otherItems">
                                    <span class="info-span">This cancellation resulted in all items in the order being cancelled.</span>
                                    <div class="other-item-row">
                                        <div class="other-itemm" *ngFor="let item of item.otherItems ; let i = index">
                                            <ng-container *ngIf="item && item.productImageUrl">
                                                <img src="{{
                                                    item.productImageUrl
                                                    ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                                        item.productImageUrl +
                                                        '?tr=w-300'
                                                    : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                                        item.productImageUrl +
                                                        '?tr=w-300'
                                                }}" alt="" height="100px" width="100px" onerror="style.display='none'" class="img-style"/>
                                            </ng-container>
                                            <div class="other-item-name">
                                                {{ item ? item.productName : "" }}
                                            </div>
                                            <div class="item-price-single">
                                                {{ rupeeSymbol }} {{
                                                item?.invoiceTotal
                                                ? transformDecimal(
                                                item?.invoiceTotal
                                                )
                                                : transformDecimal(item?.lineItemTotal)
                                                }}
                                                <span *ngIf="item.productSize">| Size {{ item ? item.productSize : "" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-checkbox>
                            <mat-form-field *ngIf="reasonBulk[item.orderItemID] && !isMobile" class="reason-input-container">
                                <mat-label>Select reason</mat-label>
                                <mat-select placeholder="Select reason" (selectionChange)="onReasonSelection($event,item)">
                                    <mat-option *ngFor="let c of userSettings.cancellationReasons" [value]="c">{{ c }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="mobile-reason" *ngIf="reasonBulk[item.orderItemID] && isMobile" (click)="openReasonModal(item)">
                                <span class="reason-btn-mobile">
                                    {{mobileReasonBulk[item.orderItemID] ? mobileReasonBulk[item.orderItemID] : 'Select reason'}}
                                </span>
                                <img src="https://ik.imagekit.io/2gwij97w0o/Location-dropdown.svg?updatedAt=1676284076711" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bulk-main-container" *ngIf="showInfoBanner">
                    <div class="checkbx-msg">
                        <div>
                            <img
                                src="
                            https://ik.imagekit.io/2gwij97w0o/info-small.svg?updatedAt=1716880624229"
                                alt=""
                            />
                        </div>
                        <div class="esb-fc">This product you're cancelling is part of an order with other items. Cancelling this product will cancel all items in the order.</div>
                    </div>
                    <div class="bulk-cancel-container" *ngFor="let item of groupOtherItem; let i = index">
                        <div class="other-item-container" *ngIf="item.otherItems">
                            <span class="info-span">Items in the package</span>
                            <div class="other-item-row-single">
                                <div class="other-itemm" *ngFor="let item of item.otherItems ; let i = index">
                                    <ng-container *ngIf="item && item.productImageUrl">
                                        <img src="{{
                                            item.productImageUrl
                                            ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                                item.productImageUrl +
                                                '?tr=w-300'
                                            : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                                item.productImageUrl +
                                                '?tr=w-300'
                                        }}" alt="" height="100px" width="100px" onerror="style.display='none'" class="img-style"/>
                                    </ng-container>
                                    <div class="other-item-name-single">
                                        {{ item ? item.productName : "" }}
                                    </div>
                                    <div class="item-price-single">
                                        {{ rupeeSymbol }} {{
                                        item?.invoiceTotal
                                        ? transformDecimal(
                                        item?.invoiceTotal
                                        )
                                        : transformDecimal(item?.lineItemTotal)
                                        }}
                                        <span *ngIf="item.productSize">| Size {{ item ? item.productSize : "" }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
             <div class="btn-bottom" [ngClass]="showFooter ? 'buttonup':''">
                <!-- <a class="arrow-top" (click)="showFooterClick()" *ngIf="ifarrowTop"><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-top.svg" /></a>  
                <a class="arrow-top" (click)="hideFooterClick()" *ngIf="ifarrowBottom"><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-bottom.svg" /></a> -->
               
                <input type="button" value="Cancel" class="btn-cancel" (click)="setBackToOrder()">  
                <!-- <input type="button" value="Continue" class="btn-continue" disabled> -->
                <ng-container *ngIf="loading;else continueButton" >
                    <figure class="d-flex btn-loader btn-continue" [ngStyle]="{background:!cancellationForm.valid?'#E0E0E0':getColor} ">
                        <div class="loader-css"></div>
                    </figure>
                </ng-container>
                <ng-template #continueButton>
                    <input *ngIf="!(isBulkCancel || showInfoBanner)" class="btn-continue" type="button" (click)="onSubmitReason()" [disabled]="!cancellationForm.valid"
                        value="NEXT">
                    <input *ngIf="showInfoBanner" type="button" value="CONFIRM" class="btn-continue" (click)="confirmClick()">
                    <input *ngIf="isBulkCancel" class="btn-continue" type="button" (click)="onSubmitReason()" [disabled]="bulkPackedConfirm" value="NEXT">
                </ng-template>            
            </div>
        </form>
    </div>
</div>
<!-- <div class="mob-footer">
    <p> Need further assistance? <a href="mailto:{{ (userSettings && userSettings.contactMailId) ? userSettings.contactMailId:'' }}"> Contact us </a> </p>
    <app-mob-header></app-mob-header>
</div> -->
</div>
  <!-- <div class="mob-footer" *ngIf="showFooter">
        <div class="footer-underline">
            <p>
                <ng-container *ngFor="let keys of footerKey;let i=index" >
                    <a target="_blank"    (click)="open_ace_com(keys[1])">
                        {{keys[0]}}
                      </a>
                    </ng-container>
             
            </p>
          

        </div>

        <div *ngIf="userSettings?.websiteUrl">
            <p class="second-para">
                <a (click)="open_ace_com(userSettings?.websiteUrl)">
                     <span class="mobile-store-link"> Go to store </span> </a>

            </p>
</div>
</div> -->
<!-- 
<div class="poweredby-mobile" *ngIf="showFooter">
    <a href="https://www.eshopbox.com/" target="_blank">
        <p style="word-wrap: no-wrap;"><a href="https://www.eshopbox.com/" target="_blank">   <img src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg" alt=""></a>Powered by Eshopbox</p>

    </a>
    
 
</div> -->


<div class="help-box" (click)="closeHelpModal()" [class.helpShow]="isHelpOpendelay">
</div>
<div class="main-helpbox" [ngStyle]="{display:isHelpOpendelay ? 'block': ''} " [class.helpShow]="isHelpOpen">
    <div class="help-top-box">
        <h2>Help ?</h2>
        <button (click)="closeHelpModal()"></button>
            </div>

            <div class="help-bottom-box">
                <div class="text-block" *ngIf="userSettings?.supportUrl && userSettings?.supportUrl!='null'">
                    <h1>Support URL</h1>
                    <a (click)="openSite(userSettings?.supportUrl)">{{userSettings?.supportUrl}}</a>
                </div>
                <div class="text-block" *ngIf="userSettings?.supportEmail && userSettings?.supportEmail!='null'">
                    <h1>Support email</h1>
                    <a href="mailto:{{ (userSettings && userSettings.supportEmail) ? userSettings.supportEmail:'' }}">{{userSettings?.supportEmail}}</a>
                </div>
                <div class="text-block" *ngIf="userSettings?.supportPhone && userSettings?.supportPhone!='null'">
                    <h1>Support phone number</h1>
                    <a  href="tel:{{userSettings?.supportPhone}}">{{userSettings?.supportPhone}}</a>
                </div>
            </div>
</div>

<div class="help-box" (click)="closeReasonModal()" [class.helpShow]="isReasonOpendelay">
</div>
<div class="main-helpbox" [ngStyle]="{display:isReasonOpendelay ? 'block': ''} " [class.helpShow]="isReasonOpen">
    <div class="help-top-box">
        <h2>Reason</h2>
        <button (click)="closeReasonModal()"></button>
            </div>

            <div class="help-bottom-box">
                <div *ngFor="let c of userSettings.cancellationReasons" class="text-block modal-reason-row" (click)="onReasonSelection(c, selectedItemMobile)">
                    <h1>{{c}}</h1>
                </div>
            </div>
</div>
  
