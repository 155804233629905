"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var store_1 = require("@ngrx/store");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var router_2 = require("@angular/router");
var global = require("../../../shared/constants/constants");
var cancelProductReasonList = require("../../../shared/interceptors/mockdata/index");
var productPaymentOption = require("../../../shared/interceptors/mockdata/index");
var index_1 = require("../../../shared/services/index");
var common_1 = require("@angular/common");
var validation_service_1 = require("../../../shared/services/validation.service");
var order_return_service_1 = require("../../../shared/services/order-return.service");
var services_1 = require("../../../shared/services");
var shared_service_1 = require("../../../shared/services/shared.service");
var scrolling_service_1 = require("../../../shared/services/scrolling.service");
var order_placed_actions_1 = require("../../../order-placed/actions/order-placed.actions");
var selectors_1 = require("../../../order-placed/reducers/selectors");
var ngx_device_detector_1 = require("ngx-device-detector");
var ls = require("lodash");
var OrderCancelComponent = /** @class */ (function () {
    function OrderCancelComponent(preventScroll, fb, router, datePipe, store, orderReturnService, alertService, route, titleService, decimalPipe, sharedService, deviceService) {
        this.preventScroll = preventScroll;
        this.fb = fb;
        this.router = router;
        this.datePipe = datePipe;
        this.store = store;
        this.orderReturnService = orderReturnService;
        this.alertService = alertService;
        this.route = route;
        this.titleService = titleService;
        this.decimalPipe = decimalPipe;
        this.sharedService = sharedService;
        this.deviceService = deviceService;
        this.isBoxshadow = false;
        this.rupeeSymbol = global.RUPEE_SYMBOL;
        this.leftPanelActive = false;
        this.cancelReasonList = cancelProductReasonList;
        this.paymentOptionList = productPaymentOption;
        this.accountTypes = global.ACCOUNT_TYPES;
        this.itemsArray = [];
        this.cancelOrderItemDetails = index_1.LocalStorageService.get('processOrderItemDetails') ? index_1.LocalStorageService.get('processOrderItemDetails') : '';
        this.showBankDetailsForm = false;
        this.currentDate = new Date();
        this.cancelItemRequest = index_1.LocalStorageService.get('cancelItemRequest') ? index_1.LocalStorageService.get('cancelItemRequest') : '';
        this.showAddressForm = false;
        this.showRefundMode = false;
        // public showOtpForm = false;
        this.bankDetailsControls = ['accountHolderName', 'accountNumber', 'confirmAccountNumber', 'ifscCode', 'bankName', 'branch', 'accountType'];
        this.showAddressFormHtml = false;
        this.showLoader = false;
        this.loading = false;
        this.isHelpOpen = false;
        this.isHelpOpendelay = false;
        this.ifarrowTop = true;
        this.defaultSelected = 0;
        this.selectedItemBulk = [];
        this.bulkPackedConfirm = true;
        this.isReasonOpen = false;
        this.isReasonOpendelay = false;
        this.changeleftPanelValue = new core_1.EventEmitter();
    }
    OrderCancelComponent.prototype.ngOnInit = function () {
        var _this = this;
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('top-header');
        this.sharedService.changeMessage("");
        index_1.LocalStorageService.flush('cancelItemRequest');
        this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
        this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
        this.isBulkCancel = localStorage.getItem('bulkCancel');
        this.isMobile = this.deviceService.isMobile();
        /*Get user settings data from state*/
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                // console.log("this.",this.userSettings.cancellationReasons[0])
                if (_this.userSettings.footerLinks) {
                    _this.footerKey = Object.entries(_this.userSettings.footerLinks);
                }
                _this.getColor = _this.userSettings.actionColor;
                _this.titleService.setTitle("Cancel Item");
                _this.initializeForm();
            }
        });
        /*Get Order Data*/
        this.store.select(function (state) { return state.orderPlaced.orderPlaced; }).subscribe(function (result) {
            if (result) {
                _this.orderDetails = result;
                // console.log("this.orderDetails ",this.orderDetails.isCOD )
                _this.addressFormValue = _this.orderDetails ? _this.orderDetails.shippingAddress : '';
                _this.cancelledItem = _this.orderDetails['items'].filter(function (itm) { return itm.orderItemID === _this.orderItemId; });
                if (_this.isBulkCancel) {
                    _this.createBulkCancelData(false);
                }
                else if (_this.cancelledItem[0].latestStatus == 'intransit') {
                    _this.singleIntransitCancel();
                }
            }
        });
        this.checkLocalData();
    };
    OrderCancelComponent.prototype.singleIntransitCancel = function () {
        var _this = this;
        var itemCount = 0;
        this.orderDetails.items.map(function (obj) {
            if (obj.shipmentId == _this.cancelledItem[0].shipmentId) {
                itemCount++;
            }
        });
        if (itemCount > 1) {
            this.showInfoBanner = true;
        }
        this.createBulkCancelData(true);
    };
    OrderCancelComponent.prototype.createBulkCancelData = function (isSingleTransit) {
        var _this = this;
        var groupShipmentArray = [];
        var tempOrderArray = ls.cloneDeep(this.orderDetails); // Deep clone to start with fresh data
        tempOrderArray['items'].forEach(function (item) {
            if (item.latestStatus === 'intransit') {
                var isMatched_1 = false;
                groupShipmentArray.forEach(function (existingItem) {
                    if (existingItem.latestStatus === 'intransit' &&
                        item.latestStatus === 'intransit' &&
                        existingItem.shipmentID === item.shipmentID) {
                        // Clone item to prevent circular references
                        var clonedItem = ls.cloneDeep(item);
                        existingItem.otherItems.push(clonedItem);
                        isMatched_1 = true;
                    }
                });
                if (!isMatched_1) {
                    // Clone item and initialize otherItems array
                    var clonedItem = ls.cloneDeep(item);
                    clonedItem.otherItems = [ls.cloneDeep(item)];
                    groupShipmentArray.push(clonedItem);
                }
            }
            else {
                // Push non-in-transit items directly after deep cloning
                groupShipmentArray.push(ls.cloneDeep(item));
            }
        });
        if (isSingleTransit) {
            var singleItem_1 = [];
            groupShipmentArray.forEach(function (item) {
                if (item.otherItems) {
                    item.otherItems.forEach(function (obj) {
                        if (obj.orderItemID === _this.cancelledItem[0].orderItemID) {
                            singleItem_1.push(item);
                        }
                    });
                }
            });
            this.groupOtherItem = singleItem_1;
        }
        else {
            this.groupOtherItem = groupShipmentArray;
        }
        // Serialize to JSON before saving to avoid circular reference issues
        localStorage.setItem('OtherItems', JSON.stringify(this.groupOtherItem));
        this.createReasonArra();
    };
    OrderCancelComponent.prototype.createReasonArra = function () {
        var tempReasonArr = ls.cloneDeep(this.groupOtherItem);
        this.reasonBulk = tempReasonArr.reduce(function (acc, obj) {
            acc[obj.orderItemID] = false;
            return acc;
        }, {});
        this.mobileReasonBulk = tempReasonArr.reduce(function (acc, obj) {
            acc[obj.orderItemID] = '';
            return acc;
        }, {});
    };
    OrderCancelComponent.prototype.onSelectShowOrderItemDetail = function (item) {
        this.selectedItem = item;
        if (this.selectedItem.originalOrderItemId && this.selectedItem.originalOrderItemId > 0) {
            this.originalOrderItemId = this.selectedItem.originalOrderItemId;
        }
    };
    /**
     * Method to define cancelItemForm info form group
     * @method initializeForm
     */
    OrderCancelComponent.prototype.initializeForm = function () {
        var _this = this;
        this.cancellationForm = this.fb.group({
            AdditionalComments: ['', [forms_1.Validators.maxLength(100)]],
            cancellationReasons: [this.userSettings.cancellationReasons[0], [forms_1.Validators.required]],
        });
        this.cancelItemForm = this.fb.group({
            cancelReason: ['', [forms_1.Validators.required]],
            additionalReason: [''],
            refundMode: [''],
            accountHolderName: [''],
            accountNumber: [''],
            confirmAccountNumber: [''],
            ifscCode: [''],
            bankName: [''],
            bankCity: [''],
            branch: [''],
            accountType: [''],
            addressInfo: [''],
        }, {
            validator: validation_service_1.ValidationService.MustMatch('accountNumber', 'confirmAccountNumber')
        });
        this.formControls = this.cancellationForm.controls;
        // console.log("this.cancellationForm",this.cancellationForm.valid);
        this.formControls['AdditionalComments'].valueChanges.subscribe(function (check) {
            _this.cancellationForm.controls['AdditionalComments'].markAsTouched();
        });
    };
    OrderCancelComponent.prototype.formInitialized = function (name, form) {
        this.cancellationForm.setControl(name, form);
        // console.log("this.cancellationForm",this.cancellationForm);
    };
    OrderCancelComponent.prototype.onSubmitReasons = function () {
        if (this.orderDetails.isCOD) {
        }
        index_1.LocalStorageService.set('AdditionalComments', this.formControls.AdditionalComments.value);
        index_1.LocalStorageService.set('cancellationReasons', this.formControls.cancellationReasons.value);
        // this.router.navigate([global.ROUTES.ORDER_CANCEL_STEP_ONE, this.orderId, this.orderItemId]);
    };
    OrderCancelComponent.prototype.radioChange = function (item) {
        // console.log("item",item)
    };
    OrderCancelComponent.prototype.radioChangeBulk = function ($event, item) {
        var tempItem = ls.cloneDeep(item);
        if ($event) {
            this.selectedItemBulk.push(tempItem);
            this.reasonBulk[tempItem.orderItemID] = true;
        }
        else {
            var updated = this.selectedItemBulk.filter(function (id) { return id.orderItemID !== tempItem.orderItemID; });
            this.selectedItemBulk = updated;
            this.reasonBulk[tempItem.orderItemID] = false;
            this.mobileReasonBulk[tempItem.orderItemID] = '';
        }
        this.bulkPackedBtn();
    };
    OrderCancelComponent.prototype.onReasonSelection = function (event, item) {
        var _this = this;
        var tempItem = ls.cloneDeep(item);
        this.selectedItemBulk.map(function (obj) {
            if (obj.orderItemID == tempItem.orderItemID) {
                obj['selectedCancellationReason'] = _this.isMobile ? event : event.value;
            }
        });
        this.bulkPackedBtn();
        if (this.isMobile) {
            this.mobileReasonBulk[tempItem.orderItemID] = event;
            this.closeReasonModal();
        }
    };
    OrderCancelComponent.prototype.bulkPackedBtn = function () {
        var reasonCount = 0;
        this.selectedItemBulk.map(function (obj) {
            if (obj['selectedCancellationReason']) {
                reasonCount++;
            }
        });
        if (reasonCount > 0 && this.selectedItemBulk.length > 0 && reasonCount == this.selectedItemBulk.length) {
            this.bulkPackedConfirm = false;
        }
        else {
            this.bulkPackedConfirm = true;
        }
    };
    OrderCancelComponent.prototype.confirmClick = function () {
        this.singleflow = this.showInfoBanner;
        this.showInfoBanner = false;
    };
    // (COD  = 1 && items.previousOrderItemId)
    OrderCancelComponent.prototype.onreasonChange = function (value, onchange) {
        var _this = this;
        if (onchange === void 0) { onchange = false; }
        if (value) {
            if (value === "Need to change shipping address") {
                this.showAddressForm = true;
                this.showBankDetailsForm = false;
                this.formControls.refundMode.setValidators(null);
                this.showRefundMode = false;
                this.onPaymentModeChange(false);
            }
            else {
                this.showAddressForm = false;
                var currentOrderItem_1;
                this.orderDetails.items.forEach(function (element, index) {
                    if (element.orderItemID === _this.orderItemId) {
                        currentOrderItem_1 = _this.orderDetails.items[index];
                    }
                });
                if ((this.orderDetails.isCOD === 0 && !this.showAddressForm) ||
                    (this.orderDetails.isCOD === 1 && !!currentOrderItem_1.previousOrderItemId)) {
                    this.formControls.refundMode.setValidators([forms_1.Validators.required]);
                    this.showRefundMode = true;
                    this.onPaymentModeChange(this.formControls.refundMode.value);
                }
            }
        }
        else {
            this.formControls.refundMode.setValidators(null);
            this.showRefundMode = false;
        }
    };
    /**
     * Method to define cancelItemForm form group
     * @method checkLocalData
     */
    OrderCancelComponent.prototype.checkLocalData = function () {
        if (this.cancelItemRequest) {
            this.cancelItemForm.patchValue({
                cancelReason: this.cancelItemRequest.reason,
                additionalReason: this.cancelItemRequest.additionalReason,
                refundMode: this.cancelItemRequest.refundMode,
                accountHolderName: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryName : '',
                accountNumber: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryAccountNo : '',
                ifscCode: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryIfscCode : '',
                bankName: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryBankName : '',
                bankCity: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryBankCity : '',
                branch: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryBranch : '',
                accountType: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryAccountType : '',
            });
            if (this.cancelItemRequest.refundMode) {
                this.showRefundMode = true;
                this.showBankDetailsForm = (this.cancelItemRequest.refundMode === 'bank') ? true : false;
            }
            if (this.cancelItemRequest.reason === "Need to change shipping address") {
                this.addressFormValue = this.cancelItemRequest.pickupAddress;
                this.showAddressForm = true;
                this.showAddressFormHtml = true;
                this.showBankDetailsForm = false;
                this.formControls.refundMode.setValidators(null);
                this.showRefundMode = false;
                this.onPaymentModeChange(false);
            }
            else {
                this.addressFormValue = '';
            }
        }
    };
    /**
      * Refund Mode
      * @method onPaymentModeChange
    */
    OrderCancelComponent.prototype.onCancelReasonChange = function (value, onchange) {
        if (onchange === void 0) { onchange = false; }
        if (value === "Need to change shipping address") {
            this.showAddressForm = true;
            this.showBankDetailsForm = false;
            this.formControls.refundMode.setValidators(null);
        }
        else {
            this.showAddressForm = false;
        }
    };
    /**
      * Refund Mode
      * @method onPaymentModeChange
      */
    OrderCancelComponent.prototype.onPaymentModeChange = function (value, onchange) {
        var _this = this;
        if (onchange === void 0) { onchange = false; }
        if (value === "bank") {
            this.showBankDetailsForm = true;
            // this.cancelItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
            this.formControls.accountHolderName.setValidators([forms_1.Validators.required]);
            this.formControls.accountNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.NUMBERS_ONLY_REGEX), forms_1.Validators.minLength(5), forms_1.Validators.maxLength(25)]);
            this.formControls.confirmAccountNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.NUMBERS_ONLY_REGEX)]);
            this.formControls.ifscCode.setValidators([forms_1.Validators.required, forms_1.Validators.minLength(11), forms_1.Validators.pattern(global.IFSC_CODE_REGEX)]);
            // this.formControls.bankName.setValidators([Validators.required]); //, Validators.pattern(global.REGX_NAME)
            // this.formControls.branch.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40)]);
            this.formControls.accountType.setValidators([forms_1.Validators.required]);
        }
        else {
            this.showBankDetailsForm = false;
            this.bankDetailsControls.forEach(function (element) {
                _this.formControls[element].setValidators(null);
                _this.formControls[element].markAsUntouched();
                _this.formControls[element].patchValue('');
                _this.formControls[element].setErrors(null);
            });
        }
    };
    OrderCancelComponent.prototype.createPayloadForRtsInTransit = function () {
        var itemDetails = {};
        var itemsArray = [];
        if (this.cancelledItem[0].latestStatus == 'packed' && !this.isBulkCancel) {
            itemDetails = {
                lineItemSequenceNumber: this.cancelledItem[0].lineItemSequenceNumber,
                orderItemID: this.cancelledItem[0].orderItemID,
                itemID: this.cancelledItem[0].itemID,
                quantity: this.cancelledItem[0].quantity.toString(),
                productName: this.cancelledItem[0].productName,
                remark: 'cancelled by customer',
                additionalInfo: {
                    customerUploadImageUrl: "",
                    comment: ""
                },
                latestStatus: this.cancelledItem[0].latestStatus,
                reason: index_1.LocalStorageService.get('cancellationReasons'),
            };
            itemsArray.push(itemDetails);
        }
        else if (this.cancelledItem[0].latestStatus == 'packed' && this.isBulkCancel) {
            this.selectedItemBulk.map(function (obj) {
                itemDetails = {
                    lineItemSequenceNumber: obj.lineItemSequenceNumber,
                    orderItemID: obj.orderItemID,
                    itemID: obj.itemID,
                    quantity: obj.quantity.toString(),
                    productName: obj.productName,
                    remark: 'cancelled by customer',
                    additionalInfo: {
                        customerUploadImageUrl: "",
                        comment: ""
                    },
                    latestStatus: obj.latestStatus,
                    reason: obj.selectedCancellationReason,
                };
                itemsArray.push(itemDetails);
            });
        }
        else if (this.cancelledItem[0].latestStatus == 'intransit' && !this.isBulkCancel) {
            if (this.groupOtherItem && this.groupOtherItem[0].otherItems) {
                this.groupOtherItem[0].otherItems.map(function (obj) {
                    itemDetails = {
                        lineItemSequenceNumber: obj.lineItemSequenceNumber,
                        orderItemID: obj.orderItemID,
                        itemID: obj.itemID,
                        quantity: obj.quantity.toString(),
                        productName: obj.productName,
                        remark: 'cancelled by customer',
                        additionalInfo: {
                            customerUploadImageUrl: "",
                            comment: ""
                        },
                        latestStatus: obj.latestStatus,
                        reason: index_1.LocalStorageService.get('cancellationReasons'),
                    };
                    itemsArray.push(itemDetails);
                });
            }
            else {
                itemDetails = {
                    lineItemSequenceNumber: this.cancelledItem[0].lineItemSequenceNumber,
                    orderItemID: this.cancelledItem[0].orderItemID,
                    itemID: this.cancelledItem[0].itemID,
                    quantity: this.cancelledItem[0].quantity.toString(),
                    productName: this.cancelledItem[0].productName,
                    remark: 'cancelled by customer',
                    additionalInfo: {
                        customerUploadImageUrl: "",
                        comment: ""
                    },
                    latestStatus: this.cancelledItem[0].latestStatus,
                    reason: index_1.LocalStorageService.get('cancellationReasons'),
                };
                itemsArray.push(itemDetails);
            }
        }
        else if (this.cancelledItem[0].latestStatus == 'intransit' && this.isBulkCancel) {
            this.selectedItemBulk.map(function (item) {
                item.otherItems.map(function (obj) {
                    itemDetails = {
                        lineItemSequenceNumber: obj.lineItemSequenceNumber,
                        orderItemID: obj.orderItemID,
                        itemID: obj.itemID,
                        quantity: obj.quantity.toString(),
                        productName: obj.productName,
                        remark: 'cancelled by customer',
                        additionalInfo: {
                            customerUploadImageUrl: "",
                            comment: ""
                        },
                        latestStatus: obj.latestStatus,
                        reason: item.selectedCancellationReason,
                    };
                    itemsArray.push(itemDetails);
                });
            });
        }
        this.cancelItemRequest = {
            actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail") : index_1.LocalStorageService.get('actorcutomername'),
            actorType: localStorage.getItem("clientPortalemail") ? 'seller' : 'buyer',
            additionalReason: index_1.LocalStorageService.get('AdditionalComments'),
            customerOrderNumber: this.orderDetails.customerOrderNumber,
            isCOD: this.orderDetails.isCOD.toString(),
            items: itemsArray,
            pickupAddress: this.pickupAddress ? this.pickupAddress : null,
            trackingId: this.cancelledItem[0].forwardTrackingTimeline.trackingID
        };
    };
    /**
     * form submission
     * @method onSubmit
     */
    OrderCancelComponent.prototype.onSubmitReason = function () {
        var _this = this;
        index_1.LocalStorageService.set('AdditionalComments', this.formControls.AdditionalComments.value);
        index_1.LocalStorageService.set('cancellationReasons', this.formControls.cancellationReasons.value);
        var count = 0;
        for (var key in this.userSettings.cancellationRefundPolicy) {
            if (this.userSettings.cancellationRefundPolicy[key] == true) {
                count++;
            }
        }
        if (this.orderDetails.isCOD || (count == 1 && !this.userSettings.cancellationRefundPolicy.bank)) {
            // console.log("item",this.orderDetails.isCOD)          
            // console.log("this.cancelItemForm",this.cancelItemForm)
            // if (this.cancelItemForm.valid) { 
            this.loading = true;
            index_1.LocalStorageService.flush('cancelItemRequest');
            index_1.LocalStorageService.flush('actionType');
            var itemDetails = {
                lineItemSequenceNumber: this.cancelOrderItemDetails.lineItemSequenceNumber ? this.cancelOrderItemDetails.lineItemSequenceNumber : this.selectedItem.lineItemSequenceNumber,
                orderItemID: this.cancelOrderItemDetails.orderItemID ? this.cancelOrderItemDetails.orderItemID : this.selectedItem.orderItemID,
                itemID: this.cancelOrderItemDetails.itemID ? this.cancelOrderItemDetails.itemID : this.selectedItem.itemID,
                quantity: this.cancelOrderItemDetails.quantity ? this.cancelOrderItemDetails.quantity.toString() : this.selectedItem.quantity.toString(),
                productName: this.cancelOrderItemDetails.productName ? this.cancelOrderItemDetails.productName : this.selectedItem.productName,
                remark: 'cancelled by customer',
                additionalInfo: {
                    customerUploadImageUrl: "",
                    comment: ""
                }
            };
            // console.log("item",itemDetails)
            this.itemsArray.push(itemDetails);
            this.cancelItemRequest = {
                actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail") : index_1.LocalStorageService.get('actorcutomername'),
                actorType: localStorage.getItem("clientPortalemail") ? 'seller' : 'buyer',
                additionalReason: index_1.LocalStorageService.get('AdditionalComments'),
                customerOrderNumber: this.orderDetails.customerOrderNumber,
                reason: index_1.LocalStorageService.get('cancellationReasons'),
                isCOD: this.orderDetails.isCOD.toString(),
                items: this.itemsArray,
                pickupAddress: this.pickupAddress ? this.pickupAddress : null,
            };
            if (this.cancelledItem[0].latestStatus == 'intransit' || this.cancelledItem[0].latestStatus == 'packed')
                this.createPayloadForRtsInTransit();
            // console.log("cancelItemRequest",this.cancelItemRequest)
            index_1.LocalStorageService.set('actionType', 'cancelProcess');
            index_1.LocalStorageService.set('cancelItemRequest', this.cancelItemRequest);
            if (this.cancelItemRequest) {
                if (count == 1) {
                    var refundObj = {
                        refundBankAccountDetails: this.refundDetails ? this.refundDetails : null,
                        refundMode: this.userSettings.cancellationRefundPolicy.originalPaymentMode ? 'originalPaymentMode' : this.userSettings.cancellationRefundPolicy.storeCredit ? 'storeCredit' : null
                    };
                    this.cancelItemRequest = tslib_1.__assign({}, this.cancelItemRequest, refundObj);
                }
                this.orderReturnService.cancelItemsApiRequest(this.cancelItemRequest)
                    .then(function (data) {
                    // this.loading = false;
                    index_1.LocalStorageService.set('SuccessResponseData', data);
                    index_1.LocalStorageService.flush('cancelItemRequest');
                    if (_this.orderId && data) {
                        _this.store.dispatch(new order_placed_actions_1.GetOrder(_this.orderId));
                        // this.showLoader = false;
                    }
                    _this.store.pipe(store_1.select(selectors_1.getCurrentOrder)).subscribe(function (result1) {
                        if (result1) {
                            // console.log("result1",result1)
                            index_1.LocalStorageService.set('cancelItemRequestTrue', true);
                            _this.router.navigate([global.ROUTES.ORDER_PLACED, _this.orderId, _this.orderItemId]);
                        }
                    });
                    // this.router.navigate([global.ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
                }).catch(function (err) {
                    _this.loading = false;
                    _this.alertService.showInfo(err.error.error.message);
                    // throw err;
                });
            }
            // }
        }
        else {
            this.router.navigate([global.ROUTES.ORDER_CANCEL_STEP_ONE, this.orderId, this.orderItemId]);
        }
    };
    OrderCancelComponent.prototype.setBackToOrder = function () {
        this.sharedService.setOpen(true);
        index_1.LocalStorageService.flush('cancelItemRequest');
        localStorage.setItem('foo', 'no reload');
        localStorage.removeItem('bulkCancel');
        if (this.singleflow) {
            this.showInfoBanner = true;
            this.singleflow = false;
        }
        else if (this.bulkflow) {
            this.isBulkCancel = true;
            this.bulkflow = false;
        }
        else {
            if (this.isMobile && (this.singleflow || this.isBulkCancel)) {
                localStorage.setItem('isJumpToFirst', 'true');
            }
            this.router.navigate([global.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
        }
    };
    OrderCancelComponent.prototype.callIfscCodeCheck = function (event) {
        var _this = this;
        if (event.currentTarget.value.length === 11) {
            this.showLoader = true;
            this.orderReturnService.getIfscCodeDetails(event.currentTarget.value)
                .then(function (data) {
                if (data) {
                    _this.showLoader = false;
                    _this.formControls.bankName.setValue(data.BANK);
                    _this.formControls.bankCity.setValue(data.CITY);
                    _this.formControls.branch.setValue(data.BRANCH);
                }
                else {
                    _this.formControls.ifscCode.setErrors({ invalid: true });
                    _this.formControls.bankName.setValue(null);
                    _this.formControls.bankCity.setValue(null);
                    _this.formControls.branch.setValue(null);
                }
            }).catch(function (err) {
                _this.formControls.ifscCode.setErrors({ invalid: true });
                _this.formControls.bankName.setValue(null);
                _this.formControls.bankCity.setValue(null);
                _this.formControls.branch.setValue(null);
                _this.showLoader = false;
                throw err;
            });
        }
    };
    OrderCancelComponent.prototype.onScroll = function () {
        this.scrollTopPosition = this.scrollElement.nativeElement.scrollTop;
        if (this.scrollTopPosition > 0) {
            this.isBoxshadow = true;
        }
        else {
            this.isBoxshadow = false;
        }
    };
    // Help Modal
    OrderCancelComponent.prototype.openHelpModal = function () {
        this.preventScroll.disable();
        this.isHelpOpen = !this.isHelpOpen;
        this.isHelpOpendelay = !this.isHelpOpendelay;
        document.querySelector("body").classList.add("stop-scrolling");
    };
    OrderCancelComponent.prototype.openReasonModal = function (item) {
        this.selectedItemMobile = item;
        this.preventScroll.disable();
        this.isReasonOpen = !this.isReasonOpen;
        this.isReasonOpendelay = !this.isReasonOpendelay;
        document.querySelector("body").classList.add("stop-scrolling");
    };
    OrderCancelComponent.prototype.closeReasonModal = function () {
        var _this = this;
        this.isReasonOpen = false;
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.isReasonOpendelay = false;
            }, 350);
        }
        else
            this.isReasonOpendelay = false;
        document.querySelector("body").classList.remove("stop-scrolling");
    };
    OrderCancelComponent.prototype.closeHelpModal = function () {
        var _this = this;
        this.isHelpOpen = false;
        // this.preventScroll.enable();
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.isHelpOpendelay = false;
            }, 350);
        }
        else
            this.isHelpOpendelay = false;
        document.querySelector("body").classList.remove("stop-scrolling");
    };
    // ngDoCheck()
    // {
    //   this.cancelItemForm.controls;
    // }
    /*Left Panel Show Hide*/
    OrderCancelComponent.prototype.showLeftPanel = function () {
        this.leftPanelActive = !this.leftPanelActive;
    };
    OrderCancelComponent.prototype.displayLeftPanel = function (value) {
        this.leftPanelActive = value;
    };
    OrderCancelComponent.prototype.transformDecimal = function (num) {
        return this.decimalPipe.transform(num, '2.');
    };
    OrderCancelComponent.prototype.openSite = function (siteUrl) {
        window.open(siteUrl, '_blank');
    };
    OrderCancelComponent.prototype.open_ace_com = function (siteUrl) {
        siteUrl = siteUrl.replace('www.', 'https://');
        window.open(siteUrl, '_blank');
    };
    OrderCancelComponent.prototype.mouseEnter = function (value) {
        this.Helpcolor = true;
    };
    OrderCancelComponent.prototype.mouseLeave = function (value) {
        this.Helpcolor = false;
    };
    OrderCancelComponent.prototype.showFooterClick = function () {
        this.showFooter = true;
        this.ifarrowTop = false;
        this.ifarrowBottom = true;
    };
    OrderCancelComponent.prototype.hideFooterClick = function () {
        this.ifarrowTop = true;
        this.ifarrowBottom = false;
        this.showFooter = false;
    };
    return OrderCancelComponent;
}());
exports.OrderCancelComponent = OrderCancelComponent;
