"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var order_return_actions_1 = require("./actions/order-return.actions");
var validation_service_1 = require("../shared/services/validation.service");
var order_return_service_1 = require("../shared/services/order-return.service");
var global = require("../shared/constants/constants");
var index_1 = require("../shared/services/index");
var common_1 = require("@angular/common");
var selectors_1 = require("./reducers/selectors");
var productPaymentOption = require("../shared/interceptors/mockdata/cancel-product-payment-option.json");
var services_1 = require("../shared/services");
var ngx_device_detector_1 = require("ngx-device-detector");
var shared_service_1 = require("../shared/services/shared.service");
var scrolling_service_1 = require("../shared/services/scrolling.service");
var OrderReturnComponent = /** @class */ (function () {
    function OrderReturnComponent(preventScroll, fb, router, store, orderReturnService, alertService, route, titleService, decimalPipe, deviceService, sharedService) {
        this.preventScroll = preventScroll;
        this.fb = fb;
        this.router = router;
        this.store = store;
        this.orderReturnService = orderReturnService;
        this.alertService = alertService;
        this.route = route;
        this.titleService = titleService;
        this.decimalPipe = decimalPipe;
        this.deviceService = deviceService;
        this.sharedService = sharedService;
        // @ViewChildren('itemID') itemsElement:QueryList<ElementRef>
        this.isBoxshadow = false;
        this.rupeeSymbol = global.RUPEE_SYMBOL;
        this.showLoader = true;
        this.ifscShowLoader = false;
        this.loading = false;
        this.showAddressFormHtml = false;
        this.showAddressForm = false;
        this.showBankDetailsForm = false;
        this.formType = 'return';
        this.showExchangeOptions = false;
        this.subReason = [];
        this.showSubReason = false;
        this.showAction = false;
        this.showBankDropdown = false;
        this.paymentOptionList = productPaymentOption;
        this.subscriptions$ = new rxjs_1.Subscription();
        this.accountTypes = global.ACCOUNT_TYPES;
        this.returnItemRequest = index_1.LocalStorageService.get('returnItemRequest') ? index_1.LocalStorageService.get('returnItemRequest') : '';
        this.returnOrderItemDetails = index_1.LocalStorageService.get('processOrderItemDetails') ? index_1.LocalStorageService.get('processOrderItemDetails') : '';
        this.bankDetailsControls = ['accountHolderName', 'accountNumber', 'confirmAccountNumber', 'ifscCode', 'bankName', 'branch', 'accountType'];
        this.leftPanelActive = false;
        this.exchangeOptionData = [];
        this.radioSelected = index_1.LocalStorageService.get('exchangeOption') ? index_1.LocalStorageService.get('exchangeOption') : '';
        this.size = index_1.LocalStorageService.get('size') ? index_1.LocalStorageService.get('size') : '';
        this.isHelpOpen = false;
        this.isHelpOpendelay = false;
        this.productSize = index_1.LocalStorageService.get('productSize') ? index_1.LocalStorageService.get('productSize') : '';
    }
    // ngAfterViewInit()
    // {
    //   this.itemsElement.changes.subscribe(res=>{
    //     console.log(res)
    //     setTimeout(()=>{
    //     res.last.nativeElement.focus();
    //     })
    //   })
    // }
    OrderReturnComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedService.changeMessage("");
        this.orderTotal = index_1.LocalStorageService.get('orderTotal');
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        index_1.LocalStorageService.flush('returnItemRequest');
        this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
        this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
        /*Get user settings data from state*/
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.getColor = _this.userSettings.actionColor;
                _this.footerKey = Object.entries(_this.userSettings.footerLinks);
                // this.titleService.setTitle("Return Item");    
            }
        });
        this.initializeForm();
        /*Get Order Data*/
        this.store.select(function (state) { return state.orderPlaced.orderPlaced; }).subscribe(function (result) {
            if (result) {
                _this.orderDetails = result;
                _this.orderType = _this.orderDetails ? _this.orderDetails.isCOD : '0';
                _this.getReasonData();
                _this.addressFormValue = _this.orderDetails ? _this.orderDetails.shippingAddress : '';
                //if order is cod then remove original payment mode option
                // if (this.orderType === 1) { 
                //   this.removeOriginalPaymentMode();
                //   // console.log(this.paymentOptionList.productPaymentOption);
                // }
            }
        });
    };
    OrderReturnComponent.prototype.onSelectShowOrderItemDetail = function (item) {
        this.selectedItem = item;
    };
    //this method for cod case 
    // removeOriginalPaymentMode(){
    //   const index = this.paymentOptionList.productPaymentOption.findIndex(option => option.value === 'originalPaymentMode');
    //   if (index !== -1) {
    //     this.paymentOptionList.productPaymentOption.splice(index, 1);
    //   }
    // }
    /* Get Reason Data*/
    OrderReturnComponent.prototype.getReasonData = function () {
        var _this = this;
        this.params = {
            'customerOrderNumber': this.orderDetails.customerOrderNumber,
            'orderItemId': this.returnOrderItemDetails.orderItemID
        };
        this.store.select(function (state) { return state.returnReason; }).subscribe(function (result) {
            if (result.returnReason) {
                _this.showLoader = false;
                _this.returnReason = result.returnReason.data;
                _this.checkLocalData();
            }
            else {
                _this.store.dispatch(new order_return_actions_1.GetReturnReason(_this.params));
                _this.subscriptions$.add(_this.store.pipe(store_1.select(selectors_1.getReturnReason)).subscribe(function (result1) {
                    if (result1) {
                        _this.showLoader = false;
                        _this.returnReason = result1.data;
                        _this.checkLocalData();
                    }
                    else {
                        _this.returnReason = [];
                    }
                }));
            }
        });
    };
    OrderReturnComponent.prototype.onChangeReturnReason = function (value, boolean) {
        if (boolean === void 0) { boolean = false; }
        this.showSubReason = true;
        if (boolean === true) {
            this.resetForm();
            this.formControls.returnReason.setValue(value);
        }
        if (1) {
            var j = 0;
            this.subReason = [];
            this.action = [];
            // for (let i = 0; i < this.returnReason.length ; i++) {
            //   if (this.returnReason[i].reason.toLowerCase() === value.toLowerCase() && j === 0) {
            //       this.subReason.push(this.returnReason[i].subReason);
            //       this.action = this.returnReason[i].action;
            //       j++;
            //   }
            // }
            //subreason
            this.subReason = this.subReason[0];
            this.showSubReason = true;
            /* Hit Related Product API */
            // if (value.toLowerCase() === global.SIZE_FIT_ISSUE.toLowerCase()) {
            this.exchangeProductData();
            // }else 
            // if (value.toLowerCase() === global.ITEM_NO_LONGER_WANTED.toLowerCase()) { 
            //   this.action = this.action; 
            // }else {
            this.replacementProductData();
            // }
            // this.formControls.returnSubReason.setValidators([Validators.required]);
            /*End Here*/
        }
        else {
            this.subReason = [];
            this.action = [];
            this.showAction = false;
            this.showSubReason = false;
            this.formControls.returnSubReason.setValidators(null);
            this.formControls.size.setValidators(null);
            this.formControls.returnSubReason.markAsUntouched();
            this.formControls.returnSubReason.patchValue('');
            this.formControls.returnSubReason.setErrors(null);
        }
    };
    OrderReturnComponent.prototype.onChangeSubReason = function (value, onchange) {
        if (onchange === void 0) { onchange = false; }
        if (value.trim()) {
            this.action = this.action;
            this.showAction = true;
            this.formControls.returnAction.setValidators([forms_1.Validators.required]);
        }
        else {
            this.action = [];
            this.formControls.returnAction.setValidators(null);
            this.formControls.returnAction.markAsUntouched();
            this.formControls.returnAction.patchValue('');
            this.formControls.returnAction.setErrors(null);
        }
    };
    OrderReturnComponent.prototype.exchangeProductData = function () {
        var _this = this;
        this.orderReturnService.getExchangeRelatedProducts(this.returnOrderItemDetails.sku ? this.returnOrderItemDetails.sku : this.selectedItem.sku)
            .then(function (data) {
            if (data && data.data.length > 0) {
                _this.exchangeOptionData = [];
                _this.exchangeOptionData = data.data;
            }
            else {
                if (_this.action.length > 1) {
                    _this.action.splice(_this.action.indexOf(global.PROVIDE_EXCHANGE), 1);
                }
                _this.exchangeOptionData = [];
            }
        }).catch(function (err) {
            throw err;
        });
    };
    OrderReturnComponent.prototype.replacementProductData = function () {
        var _this = this;
        this.orderReturnService.getStock(this.returnOrderItemDetails.itemID ? this.returnOrderItemDetails.itemID : this.selectedItem.itemID).then(function (data) {
            if (data.stock > 0) {
                _this.isStockAvailable = true;
            }
            if (data && data.stock === 0) {
                if (_this.action.includes(global.PROVIDE_REPLACEMENT)) {
                    _this.action.splice(_this.action.indexOf(global.PROVIDE_REPLACEMENT), 1);
                }
            }
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Method to define returnItemForm info form group
     * @method initializeForm
     */
    OrderReturnComponent.prototype.initializeForm = function () {
        this.returnItemForm = this.fb.group({
            returnReason: ['', [forms_1.Validators.required]],
            size: ['', [forms_1.Validators.required]],
            // returnReason: [''],
            returnAction: ['', [forms_1.Validators.required]],
            returnSubReason: [''],
            // returnAction: [''],
            refundMode: [''],
            accountHolderName: [''],
            accountNumber: [''],
            confirmAccountNumber: [''],
            ifscCode: [''],
            bankName: [''],
            bankCity: [''],
            branch: [''],
            accountType: [''],
            exchangeOption: [''],
            addressInfo: ['', [forms_1.Validators.required]]
        }, {
            validator: validation_service_1.ValidationService.MustMatch('accountNumber', 'confirmAccountNumber')
        });
        this.formControls = this.returnItemForm.controls;
    };
    OrderReturnComponent.prototype.formInitialized = function (name, form) {
        if (this.returnItemForm.controls['addressInfo'].status == 'INVALID') {
            this.showToolTip = true;
        }
        else {
            this.showToolTip = false;
        }
        this.returnItemForm.setControl(name, form);
    };
    /**
     * Method to define returnItemForm form group
     * @method checkLocalData
     */
    OrderReturnComponent.prototype.checkLocalData = function () {
        if (this.returnItemRequest) {
            //reason
            if (this.returnItemRequest.item && this.returnItemRequest.item.returnReason) {
                this.onChangeReturnReason(this.returnItemRequest.item.returnReason, false);
            }
            //subreason
            if (this.returnItemRequest.item && this.returnItemRequest.item.returnSubReason) {
                this.onChangeSubReason(this.returnItemRequest.item.returnSubReason);
            }
            if (this.returnItemRequest.item && this.returnItemRequest.item.size) {
                this.onChangeSubReason(this.returnItemRequest.item.size);
            }
            //actions
            if (this.returnItemRequest.isExchange) {
                var value = (this.returnItemRequest.isExchange === 0) ? global.REFUND : (this.returnItemRequest.exchangeItem ? global.PROVIDE_EXCHANGE : global.PROVIDE_REPLACEMENT);
                this.onActionChange(value);
            }
            this.returnItemForm.patchValue({
                returnReason: this.returnItemRequest.item ? this.returnItemRequest.item.returnReason : '',
                returnSubReason: this.returnItemRequest.item ? this.returnItemRequest.item.returnSubReason : '',
                // size: this.returnItemRequest.item ? this.returnItemRequest.item.size : '',
                // returnAction: (this.returnItemRequest.isExchange === 0) ? global.REFUND : (this.returnItemRequest.exchangeItem ? global.PROVIDE_EXCHANGE : global.PROVIDE_REPLACEMENT),
                refundMode: this.returnItemRequest.refundMode,
                accountHolderName: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryName : '',
                accountNumber: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryAccountNo : '',
                confirmAccountNumber: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.confirmAccountNumber : '',
                ifscCode: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryIfscCode : '',
                bankName: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryBankName : '',
                bankCity: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryBankCity : '',
                branch: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryBranch : '',
                accountType: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryAccountType : '',
                exchangeOption: index_1.LocalStorageService.get('exchangeOption') ? index_1.LocalStorageService.get('exchangeOption') : '',
                returnAction: index_1.LocalStorageService.get('returnAction') ? index_1.LocalStorageService.get('returnAction') : '',
                // size: LocalStorageService.get('size') ? LocalStorageService.get('size') : '',
                size: ' ',
            });
            // console.log(this.returnItemForm.value);
            //refund mode
            this.showBankDropdown = this.returnItemRequest.refundMode ? true : false;
            //bank details
            if (this.returnItemRequest.refundMode.toLowerCase() === global.REFUND_MODE.toLowerCase()) {
                this.showBankDetailsForm = true;
            }
            this.addressFormValue = this.returnItemRequest.pickupAddress;
            this.showAddressForm = true;
            this.showAddressFormHtml = true;
            if (this.returnItemRequest.exchangeItem) {
                this.handleExchangeEvent(this.returnItemRequest.exchangeItem, false);
                // const ng = this.exchangeOptionData.filter((el) => el.itemID === event.itemID);
                // console.log("filter", ng);
            }
        }
    };
    /**
      * Refund Action
      * @method onRefundActionChange
      */
    OrderReturnComponent.prototype.onActionChange = function (value, onchange) {
        if (onchange === void 0) { onchange = false; }
        if (value.trim().toLowerCase() === global.REFUND.toLowerCase()) {
            this.showBankDropdown = true;
            this.showExchangeOptions = false;
            this.showAddressForm = false;
            this.formControls.refundMode.setValidators([forms_1.Validators.required]);
            this.formControls.exchangeOption.setValidators(null);
            // this.formControls.size.setValue(0);
            this.formControls.size.setValidators(null);
        }
        else {
            if (value.trim().toLowerCase() === global.PROVIDE_EXCHANGE.toLowerCase()) {
                this.showExchangeOptions = true;
                this.formControls.exchangeOption.setValidators([forms_1.Validators.required]);
            }
            else {
                this.showExchangeOptions = false;
                this.formControls.exchangeOption.setValidators(null);
                this.formControls.size.setValidators(null);
            }
            this.showBankDetailsForm = false;
            this.showBankDropdown = false;
            this.showAddressForm = true;
            this.formControls.refundMode.setValidators(null);
            this.formControls.refundMode.markAsUntouched();
            this.formControls.refundMode.patchValue('');
            this.formControls.refundMode.setErrors(null);
            this.bankValidationSetNull();
        }
    };
    OrderReturnComponent.prototype.onPaymentModeChange = function (value, onchange) {
        if (onchange === void 0) { onchange = false; }
        if (value.trim().toLowerCase() === global.REFUND_MODE.toLowerCase()) {
            // this.returnItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
            this.showBankDetailsForm = true;
            this.showAddressForm = true;
            // this.returnItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
            this.formControls.accountHolderName.setValidators([forms_1.Validators.required]);
            this.formControls.accountNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.NUMBERS_ONLY_REGEX), forms_1.Validators.minLength(5), forms_1.Validators.maxLength(25)]);
            this.formControls.confirmAccountNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.NUMBERS_ONLY_REGEX)]);
            this.formControls.ifscCode.setValidators([forms_1.Validators.required, forms_1.Validators.minLength(11), forms_1.Validators.pattern(global.IFSC_CODE_REGEX)]);
            //  this.formControls.bankName.setValidators([Validators.required]); //, Validators.pattern(global.REGX_NAME)
            //  this.formControls.branch.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40)]); //Validators.pattern(global.REGX_NAME)
            this.formControls.accountType.setValidators([forms_1.Validators.required]);
        }
        else {
            this.showBankDetailsForm = false;
            this.showAddressForm = true;
            this.bankValidationSetNull();
        }
    };
    OrderReturnComponent.prototype.bankValidationSetNull = function () {
        var _this = this;
        this.bankDetailsControls.forEach(function (element) {
            _this.formControls[element].setValidators(null);
            _this.formControls[element].markAsUntouched();
            _this.formControls[element].patchValue('');
            _this.formControls[element].setErrors(null);
        });
    };
    OrderReturnComponent.prototype.handleExchangeEvent = function (event, condition) {
        if (condition === void 0) { condition = true; }
        this.formControls.size.setValue(event.size);
        if (condition) {
            index_1.LocalStorageService.flush('exchangeOption');
        }
        this.exchangeItemData = event;
    };
    /**
     * form submission
     * @method onSubmit
     */
    OrderReturnComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.returnItemForm.valid) {
            this.loading = true;
            index_1.LocalStorageService.flush('returnItemRequest');
            index_1.LocalStorageService.flush('actionType');
            index_1.LocalStorageService.flush('returnFlowType');
            index_1.LocalStorageService.set('exchangeOption', this.formControls.exchangeOption.value);
            index_1.LocalStorageService.set('returnAction', this.formControls.returnAction.value);
            index_1.LocalStorageService.set('size', this.formControls.size.value);
            var itemDetails = {
                lineItemSequenceNumber: this.returnOrderItemDetails.lineItemSequenceNumber ? this.returnOrderItemDetails.lineItemSequenceNumber : this.selectedItem.lineItemSequenceNumber,
                orderItemID: this.returnOrderItemDetails.orderItemID ? this.returnOrderItemDetails.orderItemID : this.selectedItem.orderItemID,
                itemID: this.returnOrderItemDetails.itemID ? this.returnOrderItemDetails.itemID : this.selectedItem.itemID,
                quantity: this.returnOrderItemDetails.quantity ? this.returnOrderItemDetails.quantity.toString() : this.selectedItem.quantity.toString(),
                productName: this.returnOrderItemDetails.productName ? this.returnOrderItemDetails.productName : this.selectedItem.productName,
                returnReason: this.formControls.returnReason.value,
                returnSubReason: this.formControls.returnSubReason.value,
                exchangeSize: this.formControls.size.value,
                additionalInfo: {
                    customerUploadImageUrl: "",
                    comment: ""
                }
            };
            this.refundDetails = {
                beneficiaryName: this.formControls.accountHolderName.value,
                beneficiaryAccountNo: this.formControls.accountNumber.value,
                confirmAccountNumber: this.formControls.confirmAccountNumber.value,
                beneficiaryIfscCode: this.formControls.ifscCode.value,
                beneficiaryBankName: this.formControls.bankName.value,
                beneficiaryBranch: this.formControls.branch.value,
                beneficiaryAccountType: this.formControls.accountType.value,
                beneficiaryBankCity: this.formControls.bankCity.value
            };
            //Exchange Item object
            if (this.formControls.returnAction.value.toLowerCase() === global.PROVIDE_EXCHANGE.toLowerCase()) {
                if (this.orderDetails.isPoeOldFlow) {
                    this.exchangeItemDetails = {
                        "sku": this.exchangeItemData.sku,
                        "itemID": this.exchangeItemData.itemID,
                        "exchangeSize": this.formControls.size.value,
                    };
                }
                else {
                    this.exchangeItemDetails = {
                        // "sku" : this.exchangeItemData.sku,
                        // "itemID" : this.exchangeItemData.itemID,
                        "exchangeSize": this.formControls.size.value,
                    };
                }
            }
            if (this.formControls.addressInfo.controls.notes.value && this.formControls.addressInfo.controls.notes.value != undefined) {
                var add2 = this.formControls.addressInfo.controls.addressLine2.value + ' , ' + this.formControls.addressInfo.controls.notes.value;
            }
            else {
                add2 = this.formControls.addressInfo.controls.addressLine2.value;
            }
            var pickupAddress = {
                "customerName": this.formControls.addressInfo.controls.firstName.value + ' ' + this.formControls.addressInfo.controls.lastName.value,
                "addressLine1": this.formControls.addressInfo.controls.address.value,
                // "addressLine2": '', //this.orderDetails.shippingAddress.town.value,
                "addressLine2": add2 ? add2 : ' ',
                "city": this.formControls.addressInfo.controls.city.value,
                "state": this.formControls.addressInfo.controls.state.value,
                "postalCode": this.formControls.addressInfo.controls.pinCode.value,
                "countryCode": this.orderDetails.shippingAddress.countryCode,
                "countryName": this.orderDetails.shippingAddress.countryName,
                "contactPhone": this.formControls.addressInfo.controls.mobile.value,
                "email": this.formControls.addressInfo.controls.email.value,
            };
            this.returnItemRequest = {
                customerOrderNumber: this.orderDetails.customerOrderNumber,
                isCOD: this.orderDetails.isCOD,
                isExchange: (this.formControls.returnAction.value.toLowerCase() === global.REFUND.toLowerCase()) ? 0 : 1,
                refundMode: this.formControls.refundMode.value,
                refundBankAccountDetails: (this.formControls.refundMode.value.toLowerCase() === global.REFUND_MODE.toLowerCase()) ? this.refundDetails : '',
                exchangeItem: this.exchangeItemDetails ? this.exchangeItemDetails : null,
                item: itemDetails,
                pickupAddress: pickupAddress,
            };
            var returnFlowType = (this.formControls.returnAction.value.toLowerCase() === global.REFUND.toLowerCase()) ? 'refund' : (this.exchangeItemDetails ? 'exchange' : 'replacement');
            index_1.LocalStorageService.set('returnFlowType', returnFlowType);
            if (this.orderDetails.isPoeOldFlow == 'false') {
                var returnFlowType_1 = 'Return';
                index_1.LocalStorageService.set('returnFlowType', returnFlowType_1);
            }
            index_1.LocalStorageService.set('actionType', 'returnProcess');
            index_1.LocalStorageService.set('returnItemRequest', this.returnItemRequest);
            //  console.log(this.returnItemRequest);
            if (this.returnItemRequest) {
                // this.router.navigate([global.ROUTES.OTP_CONFIRMATION, LocalStorageService.get('ORDER_ID'), LocalStorageService.get('ORDER_ITEM_ID')]);
                this.orderReturnService.returnItemApiRequest(this.returnItemRequest).then(function (data) {
                    _this.loading = false;
                    index_1.LocalStorageService.set('SuccessResponseData', data);
                    index_1.LocalStorageService.flush('returnItemRequest');
                    _this.router.navigate([global.ROUTES.REQUEST_SUBMISSION, _this.orderId, _this.orderItemId]);
                }).catch(function (err) {
                    _this.loading = false;
                    // throw err;
                });
            }
        }
    };
    OrderReturnComponent.prototype.setBackToOrder = function () {
        this.sharedService.setOpen(true);
        index_1.LocalStorageService.flush('returnItemRequest');
        index_1.LocalStorageService.flush('exchangeOption');
        index_1.LocalStorageService.flush('size');
        this.router.navigate([global.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
    };
    /*Left Panel Show Hide*/
    OrderReturnComponent.prototype.showLeftPanel = function () {
        this.leftPanelActive = !this.leftPanelActive;
    };
    OrderReturnComponent.prototype.displayLeftPanel = function (value) {
        this.leftPanelActive = value;
    };
    OrderReturnComponent.prototype.callIfscCodeCheck = function (event) {
        var _this = this;
        if (event.currentTarget.value.length === 11) {
            this.ifscShowLoader = true;
            this.orderReturnService.getIfscCodeDetails(event.currentTarget.value)
                .then(function (data) {
                if (data) {
                    _this.ifscShowLoader = false;
                    _this.formControls.bankName.setValue(data.BANK);
                    _this.formControls.bankCity.setValue(data.CITY);
                    _this.formControls.branch.setValue(data.BRANCH);
                }
                else {
                    _this.formControls.ifscCode.markAsTouched();
                    _this.formControls.ifscCode.setErrors({ invalid: true });
                    _this.formControls.bankName.setValue(null);
                    _this.formControls.bankCity.setValue(null);
                    _this.formControls.branch.setValue(null);
                }
            }).catch(function (err) {
                _this.formControls.ifscCode.markAsTouched();
                _this.formControls.ifscCode.setErrors({ invalid: true });
                _this.formControls.bankName.setValue(null);
                _this.formControls.bankCity.setValue(null);
                _this.formControls.branch.setValue(null);
                _this.ifscShowLoader = false;
                throw err;
            });
        }
    };
    // Help Modal
    OrderReturnComponent.prototype.openHelpModal = function () {
        this.isHelpOpen = !this.isHelpOpen;
        this.preventScroll.disable();
        this.isHelpOpendelay = !this.isHelpOpendelay;
        document.querySelector("body").classList.add("stop-scrolling");
    };
    OrderReturnComponent.prototype.closeHelpModal = function () {
        var _this = this;
        this.isHelpOpen = false;
        // this.preventScroll.enable();
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.isHelpOpendelay = false;
            }, 350);
        }
        else
            this.isHelpOpendelay = false;
        document.querySelector("body").classList.remove("stop-scrolling");
    };
    OrderReturnComponent.prototype.onScroll = function () {
        this.scrollTopPosition = this.scrollElement.nativeElement.scrollTop;
        if (this.scrollTopPosition > 0) {
            this.isBoxshadow = true;
        }
        else {
            this.isBoxshadow = false;
        }
    };
    OrderReturnComponent.prototype.resetForm = function () {
        this.returnItemForm.reset();
        this.showSubReason = false;
        this.showAction = false;
        this.showExchangeOptions = false;
        this.showBankDropdown = false;
        this.showBankDetailsForm = false;
        this.showAddressForm = false;
        this.showAddressFormHtml = false;
    };
    OrderReturnComponent.prototype.transformDecimal = function (num) {
        return this.decimalPipe.transform(num, '2.');
    };
    // ngOnDestory() {
    //   this.formControls();
    // }
    OrderReturnComponent.prototype.openSite = function (siteUrl) {
        window.open(siteUrl, '_blank');
    };
    OrderReturnComponent.prototype.open_ace_com = function (siteUrl) {
        siteUrl = siteUrl.replace('www.', 'https://');
        window.open(siteUrl, '_blank');
    };
    OrderReturnComponent.prototype.mouseEnter = function (value) {
        this.Helpcolor = true;
    };
    OrderReturnComponent.prototype.mouseLeave = function (value) {
        this.Helpcolor = false;
    };
    OrderReturnComponent.prototype.getValue = function (value) {
        this.formControls.exchangeOption.setValue(value);
        // this.exchangeItemDetails = {
        //   "sku" : this.exchangeItemData.sku,
        //   "itemID" : this.exchangeItemData.itemID,
        //   "exchangeSize" : this.formControls.size.value,
        //  }
    };
    return OrderReturnComponent;
}());
exports.OrderReturnComponent = OrderReturnComponent;
function typeOf(addressLine2) {
    throw new Error('Function not implemented.');
}
