"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var ngx_device_detector_1 = require("ngx-device-detector");
var rxjs_1 = require("rxjs");
var order_placed_actions_1 = require("../../../order-placed/actions/order-placed.actions");
var selectors_1 = require("../../../order-placed/reducers/selectors");
var constants_1 = require("../../constants/constants");
var services_1 = require("../../services");
var index_1 = require("../../services/index");
var scrolling_service_1 = require("../../services/scrolling.service");
var OtpConfirmationComponent = /** @class */ (function () {
    function OtpConfirmationComponent(preventScroll, router, store, orderReturnService, location, route, titleService, decimalPipe, deviceService, orderService, authsvc) {
        this.preventScroll = preventScroll;
        this.router = router;
        this.store = store;
        this.orderReturnService = orderReturnService;
        this.location = location;
        this.route = route;
        this.titleService = titleService;
        this.decimalPipe = decimalPipe;
        this.deviceService = deviceService;
        this.orderService = orderService;
        this.authsvc = authsvc;
        this.otpChecked = false;
        this.rupeeSymbol = constants_1.RUPEE_SYMBOL;
        this.formAction = index_1.LocalStorageService.get("actionType")
            ? index_1.LocalStorageService.get("actionType")
            : "";
        this.returnFormValue = index_1.LocalStorageService.get("returnItemRequest")
            ? index_1.LocalStorageService.get("returnItemRequest")
            : "";
        this.cancelFormValue = index_1.LocalStorageService.get("cancelItemRequest")
            ? index_1.LocalStorageService.get("cancelItemRequest")
            : "";
        this.currentSelectedItem = index_1.LocalStorageService.get("processOrderItemDetails")
            ? index_1.LocalStorageService.get("processOrderItemDetails")
            : "";
        this.returnCancelFormValue = index_1.LocalStorageService.get("returnCancelItemFormRequest")
            ? index_1.LocalStorageService.get("returnCancelItemFormRequest")
            : "";
        this.failedDeliveryFormValue = index_1.LocalStorageService.get("failedDeliveryFormReq")
            ? index_1.LocalStorageService.get("failedDeliveryFormReq")
            : "";
        this.editdetailsaction = index_1.LocalStorageService.get("editdetailsaction")
            ? index_1.LocalStorageService.get("editdetailsaction")
            : "";
        this.failedPickupFormValue = index_1.LocalStorageService.get("failedPickupFormRequest")
            ? index_1.LocalStorageService.get("failedPickupFormRequest")
            : "";
        this.markAsDeliveredFormValue = index_1.LocalStorageService.get("markAsDeliveredFormRequest")
            ? index_1.LocalStorageService.get("markAsDeliveredFormRequest")
            : "";
        this.otpInput = "";
        this.showLoader = false;
        this.loading = false;
        this.subscriptions$ = new rxjs_1.Subscription();
        this.showResendOtp = false;
        this.leftPanelActive = false;
        this.showOtpComponent = true;
        this.disableButton = false;
        this.ifarrowTop = true;
        this.verifyOtpscreen = false;
        this.config = {
            allowNumbersOnly: true,
            length: 4,
            isPasswordInput: false,
            disableAutoFocus: false,
            placeholder: "",
            inputStyles: {
                width: "43px",
                height: "48px"
            }
        };
    }
    OtpConfirmationComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    };
    OtpConfirmationComponent.prototype.ngOnInit = function () {
        var _this = this;
        index_1.LocalStorageService.flush("actorcutomername");
        index_1.LocalStorageService.flush("clientPortalemail");
        document.querySelector("body").classList.remove("hide-footer");
        this.preventScroll.disablescroll();
        this.orderId = this.route.snapshot.paramMap.get("orderId")
            ? this.route.snapshot.paramMap.get("orderId")
            : "";
        this.orderItemId = this.route.snapshot.paramMap.get("orderItemId")
            ? this.route.snapshot.paramMap.get("orderItemId")
            : "";
        /*Get user settings data from state*/
        this.store
            .select(function (state) { return state.orderPlaced.userSettings; })
            .subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.getColor = _this.userSettings.actionColor;
                if (_this.userSettings && _this.userSettings.footerLinks) {
                    _this.footerKey = Object.entries(_this.userSettings.footerLinks);
                }
                // this.titleService.setTitle(this.userSettings.pageTitle.enter_otp);
            }
        });
        //Page Title
        if (this.formAction === "returnProcess") {
            this.pageTitle = "Confirm Return";
        }
        else if (this.formAction === "returnCancelProcess" ||
            this.formAction === "cancelProcess" ||
            this.formAction === "failedDeliveryProcess") {
            this.pageTitle = "Confirm action";
        }
        else if (this.formAction === "markAsDelivered") {
            this.pageTitle = "Confirm Delivery";
        }
        else {
            this.pageTitle = "Confirmation";
        }
        //order state data
        this.store
            .select(function (state) { return state.orderPlaced.orderPlaced; })
            .subscribe(function (result) {
            if (result) {
                _this.orderDetails = result;
                _this.orderDetails.shippingAddress.emailmask = _this.maskEmail(_this.orderDetails.shippingAddress.email);
                _this.orderDetails.shippingAddress.contactPhonemask = _this.maskPhone(_this.orderDetails.shippingAddress.contactPhone);
                _this.countdown(constants_1.OTP_TIMER);
                if (_this.authsvc.getTokenType() == 'partial' && _this.router.url.includes('verifyotp')) {
                    _this.verifyOtpscreen = true;
                    _this.pageTitle = "Verify OTP";
                    // localStorage.setItem('partialVisit','false');
                    // LocalStorageService.set('partialVisit' ,false);
                    // this.orderDetails.shippingAddress.emailmask = this.maskEmail(this.orderDetails.shippingAddress.email);
                    // this.orderDetails.shippingAddress.contactPhonemask = this.maskPhone(this.orderDetails.shippingAddress.contactPhone);
                    _this.orderReturnService.generateOtp(_this.orderDetails.shippingAddress.contactPhone).subscribe(function (data) {
                        if (data) {
                            _this.countdown(constants_1.OTP_TIMER);
                            // this.otpSentinfo = true;
                            // this.showLoader = false;
                            // this.showResendOtp = true;
                        }
                    });
                }
            }
        });
        //generate otp
        // this.sendOtp();
    };
    /**
     * Method to verify OTP entered by customer
     * @method verifyOtp
     */
    OtpConfirmationComponent.prototype.verifyOtp = function (otpValue) {
        // this.showLoader = true;
        // this.editdetailsRequest(this.editdetailsaction);
        var _this = this;
        var OtpValue = { otp: this.otpvalue, otpPhoneNumber: this.orderDetails.shippingAddress.contactPhone };
        console.log(OtpValue);
        this.loading = true;
        this.orderReturnService
            .verifyOtp(OtpValue)
            .then(function (data) {
            if (data) {
                index_1.LocalStorageService.set("token", data.accessToken);
                index_1.LocalStorageService.set("otpVerified", "true");
                _this.config["inputClass"] = "valid-input-class";
                _this.otpChecked = true;
                if (_this.authsvc.getTokenType() == "partial" &&
                    _this.router.url.includes("verifyotp")) {
                    _this.authsvc.setTokenType(data);
                    _this.formAction = "verifyotp";
                    localStorage.removeItem("partialVisit");
                }
                if (_this.formAction === "verifyotp") {
                    _this.router.navigate([
                        constants_1.ROUTES.ORDER_PLACED,
                        _this.orderId,
                        _this.orderItemId
                    ]);
                }
                if (_this.formAction === "returnProcess") {
                    //request to return item
                    _this.returnItemRequest(_this.returnFormValue);
                }
                else if (_this.formAction === "cancelProcess") {
                    //request to cancel items
                    _this.cancelItemRequest(_this.cancelFormValue);
                }
                else if (_this.formAction === "returnCancelProcess") {
                    //request to cancel return item process
                    _this.returnCancelItemRequest(_this.returnCancelFormValue);
                }
                else if (_this.formAction === "failedDeliveryProcess") {
                    //request for failed delivery
                    _this.failedDeliveryItemRequest(_this.failedDeliveryFormValue);
                }
                else if (_this.formAction === "editdetailsaction") {
                    //request for failed delivery
                    _this.editdetailsRequest(_this.editdetailsaction);
                }
                else if (_this.formAction === "failedPickupProcess") {
                    //request for failed pickup
                    _this.failedPickupItemRequest(_this.failedPickupFormValue);
                }
                else if (_this.formAction === "markAsDelivered") {
                    //request for failed pickup
                    _this.markAsDeliveredRequest(_this.markAsDeliveredFormValue);
                }
            }
        })
            .catch(function (err) {
            _this.otpInput = "";
            _this.otpChecked = false;
            _this.showLoader = false;
            _this.disableButton = false;
            _this.loading = false;
            _this.otpMessage = "Invalid or incorrect OTP.  Please try again.";
            _this.config["inputClass"] = "error-input-class";
        });
    };
    /**
     * Method to send OTP to customer
     * @method sendOtp
     */
    OtpConfirmationComponent.prototype.sendOtp = function () {
        var _this = this;
        this.orderReturnService
            .generateOtp(this.orderDetails.shippingAddress.contactPhone)
            .subscribe(function (data) {
            if (data) {
                _this.countdown(constants_1.OTP_TIMER);
                _this.otpSentinfo = true;
                // this.showLoader = false;
                // this.showResendOtp = true;
            }
        });
    };
    /**
     * Method to send OTP to customer
     * @method sendOtp
     */
    OtpConfirmationComponent.prototype.resendOtp = function () {
        this.otpInput = "";
        this.showResendOtp = false;
        this.showLoader = true;
        this.otpMessage = "";
        this.disableButton = true;
        this.ngOtpInputRef.setValue(""); //yourvalue can be any string or number eg. 1234 or '1234'
        this.sendOtp();
    };
    /**
     * Method to return item request by customer
     * @method returnItemRequest
     */
    OtpConfirmationComponent.prototype.returnItemRequest = function (formValue) {
        var _this = this;
        this.orderReturnService
            .returnItemApiRequest(formValue)
            .then(function (data) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("SuccessResponseData", data);
            index_1.LocalStorageService.flush("returnItemRequest");
            index_1.LocalStorageService.set("returnRequest", true);
            _this.resetStore();
            // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
        })
            .catch(function (err) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("ErrorResponseData", err.error.error.message);
            _this.resetStore();
            // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
            throw err;
        });
    };
    /**
     * Method to cancel item request by customer
     * @method cancelItemRequest
     */
    OtpConfirmationComponent.prototype.cancelItemRequest = function (formValue) {
        var _this = this;
        // console.log("formValue",formValue);
        this.orderReturnService
            .cancelItemsApiRequest(formValue)
            .then(function (data) {
            // console.log("data",data);
            // this.showLoader = false;
            index_1.LocalStorageService.set("SuccessResponseData", data);
            index_1.LocalStorageService.flush("cancelItemRequest");
            if (_this.orderId && data) {
                // console.log("this.orderId",this.orderId)
                _this.store.dispatch(new order_placed_actions_1.GetOrder(_this.orderId));
                // this.showLoader = false;
            }
            _this.store.pipe(store_1.select(selectors_1.getCurrentOrder)).subscribe(function (result1) {
                if (result1) {
                    // console.log("result1",result1)
                    index_1.LocalStorageService.set("cancelItemRequestTrue", true);
                    // this.resetStore();
                    _this.router.navigate([
                        constants_1.ROUTES.ORDER_PLACED,
                        _this.orderId,
                        _this.orderItemId
                    ]);
                }
            });
        })
            .catch(function (err) {
            // console.log("ERR",err)
            _this.showLoader = false;
            _this.loading = false;
            index_1.LocalStorageService.set("ErrorResponseData", err.error.error.message);
            // this.router.navigate([
            //   ROUTES.REQUEST_SUBMISSION,
            //   this.orderId,
            //   this.orderItemId
            // ]);
            throw err;
        });
    };
    /**
     * Method to cancel return process request by customer
     * @method returnCancelItemRequest
     */
    OtpConfirmationComponent.prototype.returnCancelItemRequest = function (formValue) {
        var _this = this;
        this.orderReturnService
            .returnCancelItemApiRequest(formValue)
            .then(function (data) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("SuccessResponseData", data);
            index_1.LocalStorageService.flush("returnCancelItemFormRequest");
            _this.router.navigate([
                constants_1.ROUTES.REQUEST_SUBMISSION,
                _this.orderId,
                _this.orderItemId
            ]);
        })
            .catch(function (err) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("ErrorResponseData", err.error.error.message);
            _this.router.navigate([
                constants_1.ROUTES.REQUEST_SUBMISSION,
                _this.orderId,
                _this.orderItemId
            ]);
            throw err;
        });
    };
    /**
     * Method to edit details  request by customer
     * @method editdetailsRequest
     */
    OtpConfirmationComponent.prototype.editdetailsRequest = function (formValue) {
        var _this = this;
        var editdetailspayload = index_1.LocalStorageService.get("editdetailspayload");
        this.orderReturnService
            .deliveryDetailsApiRequest(editdetailspayload)
            .then(function (data) {
            //  LocalStorageService.set('SuccessResponseData', data);
            index_1.LocalStorageService.set("editdetailsTostermsg", true);
            _this.router.navigate([
                constants_1.ROUTES.ORDER_PLACED,
                _this.orderId,
                _this.orderItemId
            ]);
        })
            .catch(function (err) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("ErrorResponseData", err.error.error.message);
            //  this.router.navigate([ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
            throw err;
        });
    };
    /**
     * Method for failed delivery request
     * @method failedDeliveryItemRequest
     */
    OtpConfirmationComponent.prototype.failedDeliveryItemRequest = function (formValue) {
        var _this = this;
        this.orderReturnService
            .failedDeliveryApiRequest(formValue)
            .then(function (data) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("SuccessResponseData", data);
            index_1.LocalStorageService.flush("failedDeliveryFormRequest");
            index_1.LocalStorageService.flush("failedDeliveryFormReq");
            index_1.LocalStorageService.set("failedDeliverTostermsg", true);
            _this.resetStore();
            // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
            // this.router.navigate([ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
        })
            .catch(function (err) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("failedDelivernegativeMsg", true);
            index_1.LocalStorageService.set("ErrorResponseData", err.error.error.message);
            _this.resetStore();
            // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
            throw err;
        });
    };
    /* Method to mark mark as delivered*/
    OtpConfirmationComponent.prototype.markAsDeliveredRequest = function (data) {
        var _this = this;
        this.orderService
            .markAsDelivered(data)
            .then(function (res) {
            console.log(res);
            _this.showLoader = false;
            _this.router.navigate([
                constants_1.ROUTES.ORDER_PLACED,
                _this.orderId,
                _this.orderItemId
            ]);
        })
            .catch(function (err) {
            console.log(err);
            _this.showLoader = false;
        });
    };
    OtpConfirmationComponent.prototype.resetStore = function () {
        var _this = this;
        this.store.dispatch({ type: order_placed_actions_1.OrderPlacedActionTypes.RESET_STATE });
        this.store.pipe(store_1.select(selectors_1.getCurrentOrder)).subscribe(function (result1) {
            if (result1 == null) {
                _this.router.navigate([
                    constants_1.ROUTES.ORDER_PLACED,
                    _this.orderId,
                    _this.orderItemId
                ]);
            }
        });
    };
    /**
     * Method for failed pickup request
     * @method failedPickupItemRequest
     */
    OtpConfirmationComponent.prototype.failedPickupItemRequest = function (formValue) {
        var _this = this;
        this.orderReturnService
            .failedPickupApiRequest(formValue)
            .then(function (data) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("SuccessResponseData", data);
            index_1.LocalStorageService.flush("failedPickupFormRequest");
            _this.router.navigate([
                constants_1.ROUTES.REQUEST_SUBMISSION,
                _this.orderId,
                _this.orderItemId
            ]);
        })
            .catch(function (err) {
            _this.showLoader = false;
            index_1.LocalStorageService.set("ErrorResponseData", err.error.error.message);
            _this.router.navigate([
                constants_1.ROUTES.REQUEST_SUBMISSION,
                _this.orderId,
                _this.orderItemId
            ]);
            throw err;
        });
    };
    /**
     * Method to call verify otp when customer enter 4 digits verification code
     * @method callVerifyOtp
     */
    OtpConfirmationComponent.prototype.callVerifyOtp = function (event) {
        this.config["inputClass"] = " ";
        this.otpMessage = "";
        this.otp = event;
        if (event.length === 0) {
            this.disableButton = false;
        }
        if (event.length === 4) {
            this.otpvalue = event;
            this.disableButton = true;
            this.otpSentinfo = false;
            // this.verifyOtp(event);
        }
    };
    OtpConfirmationComponent.prototype.setBackToOrder = function () {
        // if (this.formAction === 'returnProcess'){
        //   this.router.navigate([ROUTES.ORDER_RETURN, this.orderId, this.orderItemId]);
        // }else if (this.formAction === 'returnCancelProcess'){
        //   this.router.navigate([ROUTES.ORDER_RETURN_CANCELLATION, this.orderId, this.orderItemId]);
        // } else if (this.formAction === 'failedDeliveryProcess'){
        //   this.router.navigate([ROUTES.ORDER_FAILED_DELIVERY, this.orderId, this.orderItemId]);
        // }else if (this.formAction === 'failedPickupProcess'){
        //   this.router.navigate([ROUTES.ORDER_FAILED_PICKUP, this.orderId, this.orderItemId]);
        // }else if(this.orderDetails.isCOD && this.formAction === 'cancelProcess'){
        //   this.router.navigate([ROUTES.ORDER_CANCEL, this.orderId, this.orderItemId]);
        // }else {
        //   this.router.navigate([ROUTES.ORDER_CANCEL_STEP_ONE, this.orderId, this.orderItemId]);
        // }
        if (!this.verifyOtpscreen) {
            this.location.back();
        }
        else {
            localStorage.removeItem("partialVisit");
            this.router.navigate([
                constants_1.ROUTES.ORDER_PLACED,
                this.orderId,
                this.orderItemId
            ]);
            return;
        }
        localStorage.removeItem("partialVisit");
        document.querySelector("body").classList.remove("hide-footer");
    };
    /*Left Panel Show Hide*/
    OtpConfirmationComponent.prototype.showLeftPanel = function () {
        this.leftPanelActive = !this.leftPanelActive;
    };
    OtpConfirmationComponent.prototype.displayLeftPanel = function (value) {
        this.leftPanelActive = value;
    };
    OtpConfirmationComponent.prototype.countdown = function (remainingTime) {
        var _this = this;
        // Clear any existing timer before starting a new one
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.timerId = setInterval(function () {
            _this.showLoader = false;
            if (remainingTime > 0) {
                remainingTime--;
                _this.countDownValue =
                    "00:" + (remainingTime < 10 ? "0" + remainingTime : remainingTime);
                if (remainingTime == 0) {
                    _this.otpSentinfo = false;
                }
            }
            else {
                clearInterval(_this.timerId);
                _this.countDownValue = "";
                _this.showResendOtp = true;
            }
        }, 1000);
    };
    // onOtpChange(otp) {
    //   this.otp = otp;
    // }
    OtpConfirmationComponent.prototype.onConfigChange = function () {
        var _this = this;
        this.showOtpComponent = false;
        this.otp = null;
        setTimeout(function () {
            _this.showOtpComponent = true;
        }, 0);
    };
    OtpConfirmationComponent.prototype.transformDecimal = function (num) {
        return this.decimalPipe.transform(num, "2.");
    };
    OtpConfirmationComponent.prototype.open_ace_com = function (siteUrl) {
        siteUrl = siteUrl.replace('www.', 'https://');
        window.open(siteUrl, "_blank");
    };
    OtpConfirmationComponent.prototype.openHelpModal = function () {
        this.preventScroll.disable();
        this.isHelpOpen = !this.isHelpOpen;
        this.isHelpOpendelay = !this.isHelpOpendelay;
        document.querySelector("body").classList.add("stop-scrolling");
    };
    OtpConfirmationComponent.prototype.closeHelpModal = function () {
        var _this = this;
        this.isHelpOpen = false;
        // this.preventScroll.enable();
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.isHelpOpendelay = false;
            }, 350);
        }
        else
            this.isHelpOpendelay = false;
        document.querySelector("body").classList.remove("stop-scrolling");
    };
    OtpConfirmationComponent.prototype.showFooterClick = function () {
        this.showFooter = true;
        this.ifarrowTop = false;
        this.ifarrowBottom = true;
    };
    OtpConfirmationComponent.prototype.hideFooterClick = function () {
        this.ifarrowTop = true;
        this.ifarrowBottom = false;
        this.showFooter = false;
    };
    OtpConfirmationComponent.prototype.getStyleWithoutOpacity = function () {
        return {
            border: "1px solid" + this.userSettings.actionColor,
            color: this.userSettings.actionColor
            //  opacity:0.6,
        };
    };
    OtpConfirmationComponent.prototype.getStyleWithOpacity = function () {
        return {
            background: this.userSettings.actionColor,
            opacity: 0.05
        };
    };
    OtpConfirmationComponent.prototype.maskEmail = function (email) {
        var _a = email.split("@"), localPart = _a[0], domain = _a[1];
        var maskedLocalPart = localPart.slice(0, 3) + "*".repeat(localPart.length - 3);
        return maskedLocalPart + "@" + domain;
    };
    OtpConfirmationComponent.prototype.maskPhone = function (phone) {
        return "*".repeat(phone.length - 3) + phone.slice(-3);
    };
    return OtpConfirmationComponent;
}());
exports.OtpConfirmationComponent = OtpConfirmationComponent;
