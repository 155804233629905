import { DecimalPipe, Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from "rxjs";
import { AppState } from "../../../app.state";
import {
  GetOrder,
  OrderPlacedActionTypes
} from "../../../order-placed/actions/order-placed.actions";
import { getCurrentOrder } from "../../../order-placed/reducers/selectors";
import { OTP_TIMER, ROUTES, RUPEE_SYMBOL } from "../../constants/constants";
import { OrderReturnService } from "../../services";
import {
  AuthService,
  LocalStorageService,
  OrderService
} from "../../services/index";
import { ScrollingService } from "../../services/scrolling.service";

@Component({
  selector: "app-otp-confirmation",
  templateUrl: "./otp-confirmation.component.html",
  styleUrls: ["./otp-confirmation.component.scss"],
  providers: [DecimalPipe]
})
export class OtpConfirmationComponent implements OnInit {
  otpChecked: any = false;
  rupeeSymbol = RUPEE_SYMBOL;
  formAction = LocalStorageService.get("actionType")
    ? LocalStorageService.get("actionType")
    : "";
  returnFormValue = LocalStorageService.get("returnItemRequest")
    ? LocalStorageService.get("returnItemRequest")
    : "";
  cancelFormValue = LocalStorageService.get("cancelItemRequest")
    ? LocalStorageService.get("cancelItemRequest")
    : "";
  currentSelectedItem = LocalStorageService.get("processOrderItemDetails")
    ? LocalStorageService.get("processOrderItemDetails")
    : "";
  returnCancelFormValue = LocalStorageService.get("returnCancelItemFormRequest")
    ? LocalStorageService.get("returnCancelItemFormRequest")
    : "";
  failedDeliveryFormValue = LocalStorageService.get("failedDeliveryFormReq")
    ? LocalStorageService.get("failedDeliveryFormReq")
    : "";
  editdetailsaction = LocalStorageService.get("editdetailsaction")
    ? LocalStorageService.get("editdetailsaction")
    : "";
  failedPickupFormValue = LocalStorageService.get("failedPickupFormRequest")
    ? LocalStorageService.get("failedPickupFormRequest")
    : "";
  markAsDeliveredFormValue = LocalStorageService.get(
    "markAsDeliveredFormRequest"
  )
    ? LocalStorageService.get("markAsDeliveredFormRequest")
    : "";
  otpInput = "";
  showLoader = false;
  loading = false;
  subscriptions$ = new Subscription();
  otpMessage: any;
  pageTitle: any;
  orderDetails: any;
  showResendOtp = false;
  orderId: any;
  orderItemId: any;
  leftPanelActive = false;
  userSettings: any;
  timerId: any;
  countDownValue: any;
  otp: string;
  showOtpComponent = true;
  @ViewChild("ngOtpInput") ngOtpInputRef: any; //Get reference using ViewChild and the specified hash
  footerKey: [string, unknown][];
  getColor: any;
  disableButton: boolean = false;
  isHelpOpen: boolean;
  isHelpOpendelay: boolean;
  otpvalue: any;
  showFooter: boolean;
  ifarrowTop: boolean = true;
  ifarrowBottom: boolean;
  otpSentinfo: boolean;
  verifyOtpscreen : boolean = false;

  constructor(
    private preventScroll: ScrollingService,
    private router: Router,
    private store: Store<AppState>,
    private orderReturnService: OrderReturnService,
    private location: Location,
    private route: ActivatedRoute,
    private titleService: Title,
    private decimalPipe: DecimalPipe,
    private deviceService: DeviceDetectorService,
    private orderService: OrderService,
    private authsvc :AuthService
  ) {
    
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    LocalStorageService.flush("actorcutomername");
    LocalStorageService.flush("clientPortalemail");
    document.querySelector("body").classList.remove("hide-footer");
    this.preventScroll.disablescroll();
    this.orderId = this.route.snapshot.paramMap.get("orderId")
      ? this.route.snapshot.paramMap.get("orderId")
      : "";
    this.orderItemId = this.route.snapshot.paramMap.get("orderItemId")
      ? this.route.snapshot.paramMap.get("orderItemId")
      : "";

    /*Get user settings data from state*/
    this.store
      .select(state => state.orderPlaced.userSettings)
      .subscribe(result => {
        if (result) {
          this.userSettings = result;
          this.getColor = this.userSettings.actionColor;
          if(this.userSettings && this.userSettings.footerLinks){
            this.footerKey = Object.entries(this.userSettings.footerLinks);
          }

          // this.titleService.setTitle(this.userSettings.pageTitle.enter_otp);
        }
      });

    //Page Title
    if (this.formAction === "returnProcess") {
      this.pageTitle = "Confirm Return";
    } else if (
      this.formAction === "returnCancelProcess" ||
      this.formAction === "cancelProcess" ||
      this.formAction === "failedDeliveryProcess"
    ) {
      this.pageTitle = "Confirm action";
    } else if (this.formAction === "markAsDelivered") {
      this.pageTitle = "Confirm Delivery";
    } else {
      this.pageTitle = "Confirmation";
    }
    //order state data
    this.store
      .select(state => state.orderPlaced.orderPlaced)
      .subscribe(result => {
        if (result) {
          this.orderDetails = result;
          this.orderDetails.shippingAddress.emailmask = this.maskEmail(
            this.orderDetails.shippingAddress.email
          );
          this.orderDetails.shippingAddress.contactPhonemask = this.maskPhone(
            this.orderDetails.shippingAddress.contactPhone
          );
          this.countdown(OTP_TIMER);
          if(this.authsvc.getTokenType() == 'partial' && this.router.url.includes('verifyotp')) {
            this.verifyOtpscreen = true;
            this.pageTitle = "Verify OTP";
            // localStorage.setItem('partialVisit','false');
            // LocalStorageService.set('partialVisit' ,false);
            // this.orderDetails.shippingAddress.emailmask = this.maskEmail(this.orderDetails.shippingAddress.email);
            // this.orderDetails.shippingAddress.contactPhonemask = this.maskPhone(this.orderDetails.shippingAddress.contactPhone);
            this.orderReturnService.generateOtp(this.orderDetails.shippingAddress.contactPhone).subscribe(data => {
              if (data) {
                this.countdown(OTP_TIMER);
                // this.otpSentinfo = true;
                // this.showLoader = false;
                // this.showResendOtp = true;
              }
            });
          }
        }
      });

    //generate otp
    // this.sendOtp();
  }

  /**
   * Method to verify OTP entered by customer
   * @method verifyOtp
   */
  public verifyOtp(otpValue: any) {
    // this.showLoader = true;
    // this.editdetailsRequest(this.editdetailsaction);

    const OtpValue = { otp: this.otpvalue , otpPhoneNumber:this.orderDetails.shippingAddress.contactPhone};
    console.log(OtpValue);
    this.loading = true;
    this.orderReturnService
      .verifyOtp(OtpValue)
      .then(data => {
        if (data) {
          LocalStorageService.set("token", data.accessToken);
          LocalStorageService.set("otpVerified", "true");
          this.config["inputClass"] = "valid-input-class";
          this.otpChecked = true;
          if (
            this.authsvc.getTokenType() == "partial" &&
            this.router.url.includes("verifyotp")
          ) {
            this.authsvc.setTokenType(data);
            this.formAction = "verifyotp";
            localStorage.removeItem("partialVisit");
          }
          if (this.formAction === "verifyotp") {
            this.router.navigate([
              ROUTES.ORDER_PLACED,
              this.orderId,
              this.orderItemId
            ]);
          }
          if (this.formAction === "returnProcess") {
            //request to return item
            this.returnItemRequest(this.returnFormValue);
          } else if (this.formAction === "cancelProcess") {
            //request to cancel items
            this.cancelItemRequest(this.cancelFormValue);
          } else if (this.formAction === "returnCancelProcess") {
            //request to cancel return item process
            this.returnCancelItemRequest(this.returnCancelFormValue);
          } else if (this.formAction === "failedDeliveryProcess") {
            //request for failed delivery
            this.failedDeliveryItemRequest(this.failedDeliveryFormValue);
          } else if (this.formAction === "editdetailsaction") {
            //request for failed delivery
            this.editdetailsRequest(this.editdetailsaction);
          } else if (this.formAction === "failedPickupProcess") {
            //request for failed pickup
            this.failedPickupItemRequest(this.failedPickupFormValue);
          } else if (this.formAction === "markAsDelivered") {
            //request for failed pickup
            this.markAsDeliveredRequest(this.markAsDeliveredFormValue);
          }
        }
      })
      .catch(err => {
        this.otpInput = "";
        this.otpChecked = false;
        this.showLoader = false;
        this.disableButton = false;
        this.loading = false;
        this.otpMessage = "Invalid or incorrect OTP.  Please try again.";
        this.config["inputClass"] = "error-input-class";
      });
  }

  /**
   * Method to send OTP to customer
   * @method sendOtp
   */
  public sendOtp() {
    this.orderReturnService
      .generateOtp(this.orderDetails.shippingAddress.contactPhone)
      .subscribe(data => {
        if (data) {
          this.countdown(OTP_TIMER);
          this.otpSentinfo = true;
          // this.showLoader = false;
          // this.showResendOtp = true;
        }
      });
  }

  /**
   * Method to send OTP to customer
   * @method sendOtp
   */
  public resendOtp() {
    this.otpInput = "";
    this.showResendOtp = false;
    this.showLoader = true;
    this.otpMessage = "";
    this.disableButton = true;
    this.ngOtpInputRef.setValue(""); //yourvalue can be any string or number eg. 1234 or '1234'

    this.sendOtp();
  }

  /**
   * Method to return item request by customer
   * @method returnItemRequest
   */
  public returnItemRequest(formValue) {
    this.orderReturnService
      .returnItemApiRequest(formValue)
      .then(data => {
        this.showLoader = false;
        LocalStorageService.set("SuccessResponseData", data);
        LocalStorageService.flush("returnItemRequest");
        LocalStorageService.set("returnRequest", true);
        this.resetStore();
        // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
      })
      .catch(err => {
        this.showLoader = false;
        LocalStorageService.set("ErrorResponseData", err.error.error.message);
        this.resetStore();
        // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
        throw err;
      });
  }

  /**
   * Method to cancel item request by customer
   * @method cancelItemRequest
   */
  public cancelItemRequest(formValue) {
    // console.log("formValue",formValue);
    this.orderReturnService
      .cancelItemsApiRequest(formValue)
      .then(data => {
        // console.log("data",data);
        // this.showLoader = false;
        LocalStorageService.set("SuccessResponseData", data);
        LocalStorageService.flush("cancelItemRequest");
        if (this.orderId && data) {
          // console.log("this.orderId",this.orderId)
          this.store.dispatch(new GetOrder(this.orderId));
          // this.showLoader = false;
        }
        this.store.pipe(select(getCurrentOrder)).subscribe(result1 => {
          if (result1) {
            // console.log("result1",result1)
            LocalStorageService.set("cancelItemRequestTrue", true);
            // this.resetStore();
            this.router.navigate([
              ROUTES.ORDER_PLACED,
              this.orderId,
              this.orderItemId
            ]);
          }
        });
      })
      .catch(err => {
        // console.log("ERR",err)
        this.showLoader = false;
        this.loading = false;
        LocalStorageService.set("ErrorResponseData", err.error.error.message);
        // this.router.navigate([
        //   ROUTES.REQUEST_SUBMISSION,
        //   this.orderId,
        //   this.orderItemId
        // ]);
        throw err;
      });
  }

  /**
   * Method to cancel return process request by customer
   * @method returnCancelItemRequest
   */
  public returnCancelItemRequest(formValue) {
    this.orderReturnService
      .returnCancelItemApiRequest(formValue)
      .then(data => {
        this.showLoader = false;
        LocalStorageService.set("SuccessResponseData", data);
        LocalStorageService.flush("returnCancelItemFormRequest");
        this.router.navigate([
          ROUTES.REQUEST_SUBMISSION,
          this.orderId,
          this.orderItemId
        ]);
      })
      .catch(err => {
        this.showLoader = false;
        LocalStorageService.set("ErrorResponseData", err.error.error.message);
        this.router.navigate([
          ROUTES.REQUEST_SUBMISSION,
          this.orderId,
          this.orderItemId
        ]);
        throw err;
      });
  }

  /**
   * Method to edit details  request by customer
   * @method editdetailsRequest
   */
  public editdetailsRequest(formValue) {
    const editdetailspayload = LocalStorageService.get("editdetailspayload");
    this.orderReturnService
      .deliveryDetailsApiRequest(editdetailspayload)
      .then(data => {
        //  LocalStorageService.set('SuccessResponseData', data);
        LocalStorageService.set("editdetailsTostermsg", true);
        this.router.navigate([
          ROUTES.ORDER_PLACED,
          this.orderId,
          this.orderItemId
        ]);
      })
      .catch(err => {
        this.showLoader = false;
        LocalStorageService.set("ErrorResponseData", err.error.error.message);
        //  this.router.navigate([ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
        throw err;
      });
  }

  /**
   * Method for failed delivery request
   * @method failedDeliveryItemRequest
   */
  public failedDeliveryItemRequest(formValue) {
    this.orderReturnService
      .failedDeliveryApiRequest(formValue)
      .then(data => {
        this.showLoader = false;
        LocalStorageService.set("SuccessResponseData", data);
        LocalStorageService.flush("failedDeliveryFormRequest");
        LocalStorageService.flush("failedDeliveryFormReq");
        LocalStorageService.set("failedDeliverTostermsg", true);
        this.resetStore();
        // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
        // this.router.navigate([ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
      })
      .catch(err => {
        this.showLoader = false;
        LocalStorageService.set("failedDelivernegativeMsg", true);
        LocalStorageService.set("ErrorResponseData", err.error.error.message);
        this.resetStore();
        // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
        throw err;
      });
  }

  /* Method to mark mark as delivered*/

  public markAsDeliveredRequest(data) {
    this.orderService
      .markAsDelivered(data)
      .then(res => {
        console.log(res);
        this.showLoader = false;
        this.router.navigate([
          ROUTES.ORDER_PLACED,
          this.orderId,
          this.orderItemId
        ]);
      })
      .catch(err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  resetStore() {
    this.store.dispatch({ type: OrderPlacedActionTypes.RESET_STATE });
    this.store.pipe(select(getCurrentOrder)).subscribe(result1 => {
      if (result1 == null) {
        this.router.navigate([
          ROUTES.ORDER_PLACED,
          this.orderId,
          this.orderItemId
        ]);
      }
    });
  }
  /**
   * Method for failed pickup request
   * @method failedPickupItemRequest
   */
  public failedPickupItemRequest(formValue) {
    this.orderReturnService
      .failedPickupApiRequest(formValue)
      .then(data => {
        this.showLoader = false;
        LocalStorageService.set("SuccessResponseData", data);
        LocalStorageService.flush("failedPickupFormRequest");
        this.router.navigate([
          ROUTES.REQUEST_SUBMISSION,
          this.orderId,
          this.orderItemId
        ]);
      })
      .catch(err => {
        this.showLoader = false;
        LocalStorageService.set("ErrorResponseData", err.error.error.message);
        this.router.navigate([
          ROUTES.REQUEST_SUBMISSION,
          this.orderId,
          this.orderItemId
        ]);
        throw err;
      });
  }

  /**
   * Method to call verify otp when customer enter 4 digits verification code
   * @method callVerifyOtp
   */
  public callVerifyOtp(event) {
    this.config["inputClass"] = " ";
    this.otpMessage = "";
    this.otp = event;
    if (event.length === 0) {
      this.disableButton = false;
    }
    if (event.length === 4) {
      this.otpvalue = event;
      this.disableButton = true;
      this.otpSentinfo = false;
      // this.verifyOtp(event);
    }
  }

  setBackToOrder() {
    // if (this.formAction === 'returnProcess'){
    //   this.router.navigate([ROUTES.ORDER_RETURN, this.orderId, this.orderItemId]);
    // }else if (this.formAction === 'returnCancelProcess'){
    //   this.router.navigate([ROUTES.ORDER_RETURN_CANCELLATION, this.orderId, this.orderItemId]);
    // } else if (this.formAction === 'failedDeliveryProcess'){
    //   this.router.navigate([ROUTES.ORDER_FAILED_DELIVERY, this.orderId, this.orderItemId]);
    // }else if (this.formAction === 'failedPickupProcess'){
    //   this.router.navigate([ROUTES.ORDER_FAILED_PICKUP, this.orderId, this.orderItemId]);
    // }else if(this.orderDetails.isCOD && this.formAction === 'cancelProcess'){
    //   this.router.navigate([ROUTES.ORDER_CANCEL, this.orderId, this.orderItemId]);
    // }else {
    //   this.router.navigate([ROUTES.ORDER_CANCEL_STEP_ONE, this.orderId, this.orderItemId]);
    // }
    if (!this.verifyOtpscreen) {
      this.location.back();
    } else {
      localStorage.removeItem("partialVisit");
      this.router.navigate([
        ROUTES.ORDER_PLACED,
        this.orderId,
        this.orderItemId
      ]);
      return;
    }
    localStorage.removeItem("partialVisit");
    document.querySelector("body").classList.remove("hide-footer");
  }

  /*Left Panel Show Hide*/
  showLeftPanel() {
    this.leftPanelActive = !this.leftPanelActive;
  }

  displayLeftPanel(value) {
    this.leftPanelActive = value;
  }

  countdown(remainingTime) {
    // Clear any existing timer before starting a new one
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    this.timerId = setInterval(() => {
      this.showLoader = false;
      if (remainingTime > 0) {
        remainingTime--;
        this.countDownValue =
          "00:" + (remainingTime < 10 ? "0" + remainingTime : remainingTime);
        if (remainingTime == 0) {
          this.otpSentinfo = false;
        }
      } else {
        clearInterval(this.timerId);
        this.countDownValue = "";
        this.showResendOtp = true;
      }
    }, 1000);
  }

  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "43px",
      height: "48px"
    }
  };
  // onOtpChange(otp) {
  //   this.otp = otp;
  // }

  onConfigChange() {
    this.showOtpComponent = false;
    this.otp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  transformDecimal(num) {
    return this.decimalPipe.transform(num, "2.");
  }
  open_ace_com(siteUrl) {
    siteUrl = siteUrl.replace('www.', 'https://');
    window.open(siteUrl, "_blank");
  }

  openHelpModal() {
    this.preventScroll.disable();
    this.isHelpOpen = !this.isHelpOpen;
    this.isHelpOpendelay = !this.isHelpOpendelay;
    document.querySelector("body").classList.add("stop-scrolling");
  }

  closeHelpModal() {
    this.isHelpOpen = false;
    // this.preventScroll.enable();
    if (!this.deviceService.isDesktop()) {
      setTimeout(() => {
        this.isHelpOpendelay = false;
      }, 350);
    } else this.isHelpOpendelay = false;
    document.querySelector("body").classList.remove("stop-scrolling");
  }

  showFooterClick() {
    this.showFooter = true;
    this.ifarrowTop = false;
    this.ifarrowBottom = true;
  }
  hideFooterClick() {
    this.ifarrowTop = true;
    this.ifarrowBottom = false;
    this.showFooter = false;
  }
  getStyleWithoutOpacity() {
    return {
      border: "1px solid" + this.userSettings.actionColor,
      color: this.userSettings.actionColor
      //  opacity:0.6,
    };
  }
  getStyleWithOpacity() {
    return {
      background: this.userSettings.actionColor,
      opacity: 0.05
    };
  }

  public maskEmail(email: string): string {
    const [localPart, domain] = email.split("@");
    const maskedLocalPart =
      localPart.slice(0, 3) + "*".repeat(localPart.length - 3);
    return maskedLocalPart + "@" + domain;
  }

  public maskPhone(phone: string): string {
    return "*".repeat(phone.length - 3) + phone.slice(-3);
  }
}
