"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var FooterComponent = /** @class */ (function () {
    function FooterComponent(store, router) {
        this.store = store;
        this.router = router;
        this.showLoader = true;
        this.currentRoute = window.location.href;
        this.currentOrigin = window.location.origin + '/';
        this.currentYear = new Date().getFullYear();
        this.imgSrc = "https://ik.imagekit.io/2gwij97w0o/POE/link-icon.svg";
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        /*Get user settings data from state*/
        this.store
            .select(function (state) { return state.orderPlaced.userSettings; })
            .subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                if (_this.userSettings.footerLinks) {
                    _this.footerKey = Object.entries(_this.userSettings.footerLinks);
                }
                _this.getColor = _this.userSettings.actionColor;
            }
        });
    };
    FooterComponent.prototype.open_ace_com = function (siteUrl) {
        siteUrl = siteUrl.replace('www.', 'https://');
        window.open(siteUrl, "_blank");
    };
    FooterComponent.prototype.mouseEnter = function (value) {
        this.value = value;
    };
    FooterComponent.prototype.mouseLeave = function (value) {
        this.Helpcolor = false;
        this.value = null;
        //  console.log(value);
    };
    FooterComponent.prototype.onMouseOver = function () {
        this.imgSrc = "https://ik.imagekit.io/2gwij97w0o/POE/link-icon-hover.svg";
    };
    FooterComponent.prototype.onMouseOut = function () {
        this.imgSrc = "https://ik.imagekit.io/2gwij97w0o/POE/link-icon.svg";
    };
    return FooterComponent;
}());
exports.FooterComponent = FooterComponent;
