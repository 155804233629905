export const CONSTANTS = {
  MESSAGES: {
    START_PICKING_POPUP:
      "Pick-list will be generated and no more changes will be allowed. Please click OK to confirm or cancel to go back and revise the route plan. Once you click on OK, it is not possible to change the plan.",
    CANCEL_POH:
      "Do you want to continue ? If yes, shipments will be released from this picklist and become ready to be picked again from new orders tab.",
    UPLOAD_SUCCESS: "Image Uploaded Successfully.",
    ACCOUNT_NO_ERROR: "Please select the Account Number",
    REF_NUMBER_ERROR: "Please enter Ref Consignment Number",
    DUPLICATE_DA_ON_SIMULATE:
      "You have assigned two or more routes to the same Associate. This will combine the routes. Please click OK if this is what you wanted or CANCEL to reassign.",
    MARK_RECEIVE_SUCCESS: "Consignment Received Successfully"
  },
  HEADERS: {
    START_PICKING_POPUP: "Confirmation",
    START_SIMULATE_POPUP: "Confirmation",
    CONFIRMATION: "Confirmation"
  },
  ALERT_MESSAGES: {
    ADDPICKLIST_ASSOCIATE_SUCCESS: "Updated Successfully.",
    ADDPICKLIST_ASSOCIATE_FAILURE: "Update Unsuccessful, Please try again.",
    DELETEPICKLIST_ASSOCIATE_SUCCESS:
      "Delivery Associate deleted Successfully.",
    DELETEPICKLIST_ASSOCIATE_FAILURE:
      "Delivery Associate deletion unsuccessful, Please try again.",
    POH_UPLOAD_SUCCESS: "Document uploaded successfully.",
    POH_UPLOAD_FAILURE: "Document upload unsuccessfully, Please try again.",
    DATE_RANGE_ERROR: "Please select end date greater than start date",
    NO_INTERNET_CONNECTION: "Please Check Internet Connectivity. ",
    CANCEL_ITEM_INTRANSIT_STATUS_MESSAGE:
      "Your Order is shipped now; we are not able to cancel it. We request you to deny the delivery"
  }
};

export const ROUTES = {
  ORDER_CANCEL: "/cancel",
  ORDER_CANCEL_STEP_ONE: "/cancel-step-one",
  ORDER_RETURN: "/return",
  ORDER_REFUND_EXCHANGE: "/refund-exchange",
  ORDER_PLACED: "/track",
  OTP_CONFIRMATION: "/otp",
  REQUEST_SUBMISSION: "/success",
  ORDER_LISTING: "/order-list",
  ORDER_RETURN_CANCELLATION: "/return-cancel",
  ORDER_FAILED_DELIVERY: "/failed-delivery",
  // ORDER_FAILED_DELIVERY: "/failed-delivery-new",
  ORDER_FAILED_PICKUP: "/failed-pickup",
  VERIFY_OTP_CONFIRMATION: "/verifyotp",

};

export const ACCOUNT_TYPES = {
  s: "Savings",
  c: "Current"
};

export const NUMBERS_ONLY_REGEX: any = /^[0-9]*$/;
export const IFSC_CODE_REGEX: any = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
export const LETTERS_ONLY_REGEX: any = /^[A-Za-z]+$/;
export const REGX_NAME = /^(([A-Za-z\'\.\-]{2,25})|([A-Za-z\'\.\-]{2,25}[\s]{1}[A-Za-z\'\.\-]{2,25})|([A-Za-z\'\.\-]+[\s]{1}[A-Za-z\'\.\-]{2,25}[\s]{1}[A-Za-z\'\.\-]{2,25}))$/;
export const REGX_PINCODE: any = /^[1-9][0-9]{5}$/;
export const REGX_MOBILE = "[6-9]{1}[0-9]{9}";
export const REGX_MOBILE_NEW = "^([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})$";
export const REGX_ADDRESS: any = /^[A-Za-z0-9\/\.\-\,]+([A-Za-z0-9\/\.\-\, ]+)*$/;
export const REGX_ADDRESS_With_NUM: any = /[0-9][a-zA-Z0-9\s\-\#\.\,\ \-\_\/\ ]*$/;
export const REGX_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const STATUS_ACTIVE_CLASS: any = {
  "2": "active-two",
  "3": "active-three",
  "4": "active-four"
};
export const SECRET = "1a0e3695c35ce65d67fc3688f873ad51";
export const PROXY_HEADER: any = {
  proxyHost: 'clarkspreprod',
  orderId: '81008316',
  mobile: '8867595833'
}

export const FORWARD_STATUS = {

  FORWARD_PLACED_STATUS: [
    'created',
    "accepted",
    'picking',
    'picked',
    'failed_to_rts'
    
  ],
  CANCEL:[
    "packed",
    "intransit"
  ],

  FORWARD_PACKED_STATUS: [
    'packed',
    'ready_to_ship',
    'manifested',
    'out_for_pickup',
    'pickup_cancelled',
    'failed_to_handover',
    'pickup_failed'
    // ,
    // 'pickup_pending'
  ],
  FORWARD_SHIPPED_STATUS: [
    "dispatched",
    "picked_up",
    "pickup_pending",
    "intransit",
    "lost",
    "Lost"
  ],
  FORWARD_SHIPPED_DELIVERYFAILD_STATUS: [
    "delivery_failed",
    "failed_delivery"
  ],
  FORWARD_OUTFORDILEVRY_STATUS: [
    "out_for_delivery",
  ],
  FORWARD_SHIPMENT_DELAY_STATUS: [
    "shipment_delayed",
  ],

  FORWARD_ALL_INTRANSIT_STATUS: [
    "dispatched",
    "picked_up",
    "pickup_pending",
    "intransit",
    "delivery_failed",
    "failed_delivery",
    "out_for_delivery",
    "lost",
    "Lost",
    "shipment_delayed",
    "ndr_resolution_submitted",
    "shipment_held",
    "contact_customer_care"
  ],

  FORWARD_DELIVERED_STATUS: [
    "completed",
    "delivered",
  ],

  /* ------------------END--------------------------*/


  FORWARD_ORDER_STATUS_TIMELINE: [
    "created",
    "packed",
    "intransit",
    "delivered"
  ],
  INTRANSIT_ALL_STATUS: [
    "pickup_pending",
    "manifested",
    "intransit",
    "out_for_delivery",
    "failed_delivery",
    "ndr_resolution_submitted",
    "delivery_failed",
    "lost",
    "Lost"
  ],
  CANCEL_STATUS: [
    "rto",
    "cancelled",
    "rto_intransit",
    "rto_delivered_warehouse",
    "rto_out_for_delivery",
    "pickup_cancelled",
    "rto_created",
    "rto_delivered",
    "rto_requested",
    "received",
    "cancelled_order"

  ],
  CANCEL_STATUS_NEW: [
    "rto",
    // "cancelled",
    "rto_intransit",
    "rto_delivered_warehouse",
    "rto_out_for_delivery",
    "pickup_cancelled",
    "rto_created",
    "cancelled_order",
    'rto_shipment_delay',
    "rto_delivered",
    "rto_requested",
    "received",
    "cancelled_order"
  ]
};

export const REVERSE_STATUS = {

/*-------------------------------Start------------------------------*/
  ORDER_PLACED: ['order_placed'],
  REVERSE_RETURN_REQUESTED_STATUS: [
    "created"
  ],
  REVERSE_RETURN_APPROVED_STATUS: [
    "approved",

  ],
  REVERSE_RETURN_OUTFOR_PICKUP_STATUS: [
    "out_for_pickup",
    "carrier_out_for_pick_up_from_customer"
  ],
  REVERSE_RETURN_PICK_UP_FAILED_STATUS: [
    "failed_delivery",
    "pickup_cancelled"
  ],
  REVERSE_RETURN_PICK_UP_PENDING_STATUS: [
    "pickup_pending",
    "order_placed"
  ],

  REVERSE_STATUS_TIMELINE: [
    "created",
    "approved",
    "out_for_pickup",
    "failed_delivery",
    "pickup_pending", 
    "pickup_cancelled",
    'order_placed',
    'carrier_out_for_pick_up_from_customer',
    'pickup_failed'
  ],

 
  REVERSE_ORDER_STATUS_TIMELINE: [
    "created",
    "approved",
    "picked_up",
    // "delivered"
    "delivered_warehouse",
    'order_placed'
  ],

  
  INTRANSIT_ALL_STATUS: [
    "pickup_pending",
    "manifested",
    "intransit",
    "out_for_delivery",
    "failed_delivery",
    "ndr_resolution_submitted",
    "delivery_failed",
  ],
/*-------------------------------End------------------------------*/



  REVERSE_ORDER_STATUS_RENAME: {
    created: "Return Initiated",
    approved: "Return Approved",
    picked_up: "Pick-Up",
    delivered: "Returned",  //Refund
    warehouse_delivered: "Returned",
    delivered_warehouse: "Returned"
  },
  REMARK_REVERSE_STATUS: ["delivered", "intransit", "delivered_warehouse", "warehouse_delivered","picked_up"], //"warehouse_delivered", "delivered_warehouse"
  REMARK_REVERSE_STATUS_DUMMY: ["delivered", "delivered_warehouse", "warehouse_delivered"], //"warehouse_delivered", "delivered_warehouse"

  WAREHOUSE_STATUS: ["delivered_warehouse", "warehouse_delivered","delivered"], //"warehouse_delivered", "delivered_warehouse"

  REVERSE_STATUS_FOR_CANCEL_RETURN_BUTTON: ["created", "approved", "order_placed", "out_for_pickup", "pickup_pending"],
  RETURN_CANCELLED_STATUS: ['return_cancelled', 'cancelled_order']
};

export const REFUND_STATUS = {
  REFUND_STATUS_TIMELINE: ["created", "initiated"]
};
export const DATE_FORMAT = "E d MMM, y";
export const PARTIAL_STATUS_CODE = "120";
//Dropdown values
export const SIZE_FIT_ISSUE = "Size Fit Issue";
export const REFUND = "Refund";
export const REFUND_MODE = "bank";
export const ITEM_NO_LONGER_WANTED = "Item no longer wanted";
export const PROVIDE_EXCHANGE = "Exchange";
export const PROVIDE_REPLACEMENT = "Replacement";
export const RUPEE_SYMBOL = "Rs. ";
export const OTP_TIMER = 30;
export const RETURN_TYPE_EXCHANGE = 'exchange';
export const RETURN_TYPE_REPLACE = 'replace';

export const RESOLUTION_CODE = {
  // REATTEMPT_DELIVERY: "ACTF001",
  // DELIVERY_DATE: "ACTF002",
  // CANCELLATION_REASON: "ACTF003",
  // // REMARK_VAL: "ACTF004",
  // DIFFERENT_ADDRESS: "ACTF004",
  // SHIPMENT_RTO: "ACTF006",
 
  // LANDMARK: "ACTF007"

  REATTEMPT_DELIVERY: "ACTF001",
  DELIVERY_DATE: "ACTF002",
  DIFFERENT_ADDRESS: "ACTF003",
  CONTACT_NUMBER: "ACTF004",
  SHIPMENT_RTO: "ACTF006",
  FALSE_REASON: "ACTF005",
  LANDMARK: "ACTF007"

};
