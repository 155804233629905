import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from "@ngrx/store";
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from "rxjs";
import { AppState } from '../../../app.state';
import { GetOrder, GetUserSettings } from "../../../order-placed/actions/order-placed.actions";
import { getCurrentOrder, getUserSettings } from "../../../order-placed/reducers/selectors";
import { FORWARD_STATUS, RETURN_TYPE_EXCHANGE, RETURN_TYPE_REPLACE, REVERSE_STATUS, ROUTES, RUPEE_SYMBOL } from "../../constants/constants";
import { AlertService } from "../../services";
import { AuthService, LocalStorageService } from '../../services/index';

@Component({
  selector: 'app-order-sidebar',
  templateUrl: './order-sidebar.component.html',
  styleUrls: ['./order-sidebar.component.scss'],
  providers: [DatePipe,DecimalPipe]
})
export class OrderSidebarComponent implements OnInit, OnDestroy {
  rupeeSymbol = RUPEE_SYMBOL;
  @Output() selectedItemData = new EventEmitter<any>();
  @Output() editdetailsevent: EventEmitter<any> = new EventEmitter();
  @Input() poeSetting;

  orderData: any;
  subscriptions$ = new Subscription();
  orderId: any;
  // remarkStatusArray = REVERSE_STATUS.REMARK_REVERSE_STATUS;
    remarkStatusArray = REVERSE_STATUS.REMARK_REVERSE_STATUS_DUMMY;
    warehouse_status=REVERSE_STATUS.WAREHOUSE_STATUS
  // forwardIntransitStatus = FORWARD_STATUS.INTRANSIT_ALL_STATUS;
  // reverseIntransitStatus = REVERSE_STATUS.INTRANSIT_ALL_STATUS;
  reverseIntransitStatus = REVERSE_STATUS.INTRANSIT_ALL_STATUS;
  forwardIntransitStatus = FORWARD_STATUS.FORWARD_SHIPPED_STATUS;
  REMARK_REVERSE_STATUS_DUMMY
  cancellationStatusArray = FORWARD_STATUS.CANCEL_STATUS;
  selectedItemId: any;
  selectedItemDetails: any;
  showLoader: boolean;
  orderItemId: any;
  orderItemExist: boolean;
  isMobile: boolean;
  isTablet: boolean;
  deviceInfo: any;
  isDesktopDevice:any;
  returnTypeExchange = RETURN_TYPE_EXCHANGE;
  returnTypeReplace  = RETURN_TYPE_REPLACE;
  @Output() changeleftPanelValue = new EventEmitter();
  userSettings: any;
  updatedAddr: boolean = false;
  addressFormValue: any;
  deliveryPopupdelay: boolean = false;
  deliveryPopup: boolean = false;
  customerOrderNumber: any;
  trackingID: boolean;
  cancellededit: boolean;
  packededit: boolean;
  itemclickcheckdetails: boolean;
  editaddresbutton: boolean;
  verifyOTP: boolean = false;
  allowCancel = FORWARD_STATUS.CANCEL;
  constructor(private decimalPipe: DecimalPipe,private router: Router, private store: Store<AppState>,
    private alertService: AlertService, private route: ActivatedRoute, private deviceService: DeviceDetectorService, private datePipe: DatePipe,
    private auth :AuthService) {
          this.alertService.openPopup$.subscribe((data) => {
            // console.log(this.deliveryPopupdelay, this.deliveryPopup,"data");
            if( this.deliveryPopupdelay == false && this.deliveryPopup == false && data == true) {
            // console.log(data,"data");

              this.showEditDetails();
              // this.alertService.openAddressPopup(false);
            }
            // And he have data here too!
          });
          this.verifyOTP = this.auth.getTokenType() == 'partial' && !this.auth.isFromShopify ? true : false;
      // localStorage.removeItem('partialVisit')
    }

  ngOnInit() {
    // LocalStorageService.set('otpVerified','true')
    if(LocalStorageService.get('otpVerified')) {
      this.updatedAddr = true;
      setTimeout(() => {;
      this.updatedAddr = false;
      LocalStorageService.flush('otpVerified');
      }, 5000);
    }
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isDesktopDevice = this.deviceService.isDesktop();
    // console.log('isDesktopDevice',this.isDesktopDevice);
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    // console.log(this.deviceInfo);
    // console.log(this.deviceInfo.os);
    this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
    this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';

    if (this.orderId)
    {
        this.selectedItemId = this.orderItemId;

        //Fetch data from state if already exist
        this.store.select(state => state.orderPlaced.orderPlaced).subscribe(result =>   {
          if (result) {
              this.orderData = result;

              this.settrackingid(this.orderData);
              this.customerOrderNumber = this.orderData.customerOrderNumber
              this.addressFormValue = this.orderData.shippingAddress
              LocalStorageService.set('actorcutomername',this.orderData.billingAddress.customerName)
              LocalStorageService.set('channelSlug', this.orderData.channelSlug)
            if(this.isMobile  && this.auth.getTokenType() == 'partial' && localStorage.getItem('partialVisit')== null && localStorage.getItem('partialVisit') != 'true') {
              localStorage.setItem('partialVisit','true');
              this.onClickItems(this.orderData['items'][0]);
            }
              this.defaultSelectedItem(this.orderData);
              if(localStorage.getItem("action")){
                this.navigateNdrpage(this.orderData)
            }
          }else {
            this.store.dispatch(new GetOrder(this.orderId));
            this.subscriptions$.add(
            this.store.pipe(select(getCurrentOrder)).subscribe(result1 => {
                if (result1) {
                  this.orderData = result1;
                  this.settrackingid(this.orderData);
                  LocalStorageService.set('actorcutomername',this.orderData.billingAddress.customerName)
                  LocalStorageService.set('channelSlug',this.orderData.channelSlug);
                  this.defaultSelectedItem(this.orderData);
                  if(this.isMobile && this.auth.getTokenType() == 'partial' && localStorage.getItem('partialVisit')== null && localStorage.getItem('partialVisit') != 'true') {
                    localStorage.setItem('partialVisit','true');
                    this.onClickItems(this.orderData['items'][0]);
                  }
                  if(localStorage.getItem("action")){
                      this.navigateNdrpage(this.orderData)
                  }
                }else {
                  this.orderData = [];
                  this.selectedItemDetails = [];
                }
              })
            );
          }
        });
    }else{
      this.alertService.showError("Invalid Url");
    }

  //detect device LOC added on 29-Nov-2019
    // if (this.isMobile || this.isTablet) {
    //     this.changeleftPanelValue.emit(false);
    // }else {
    //     this.changeleftPanelValue.emit(true);
    // }

    this.store.dispatch(new GetUserSettings());
    this.subscriptions$.add(
      this.store.pipe(select(getUserSettings)).subscribe(result => {
        if (result) {
          this.userSettings = result;
        }else {
          this.userSettings = [];
        }
      })
  );
  }

  isEmptyKeys(obj){
    return Object.values(obj).every(x => x === "");
  }

  defaultSelectedItem(orderData) {
      this.selectedItemId = this.selectedItemId ? this.selectedItemId : orderData.items[0].orderItemID;
      for (let i = 0; i < orderData.items.length ; i++ ){
          if (orderData.items[i].orderItemID === this.selectedItemId){
            this.selectedItemData.emit(orderData.items[i]);
            if(orderData.items[i].latestStatus == 'cancelled' || orderData.items[i].latestStatus == 'packed'){
              this.trackingID= false
            }
            this.orderItemExist = true;
            this.selectedItemDetails = orderData.items[i];
            // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.selectedItemId]);
            LocalStorageService.set("processOrderItemDetails", orderData.items[i]);
          }
      }
      //if no order item id matched then show first item of order
      if (!this.orderItemExist) {
        this.selectedItemData.emit(orderData.items[0]);
        this.selectedItemId = orderData.items[0].orderItemID;
        this.selectedItemDetails = orderData.items[0];
        // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.selectedItemId]);

      }
  }

  settrackingid(orderData){
// New  = created
// Processing = accepted
// Failed To RTS = failed_to_rts
// Unfulfillable = Unfulfillable
if(orderData && orderData.length > 0 ){
  orderData.items.filter(element => {
    if(element.latestStatus == 'created' || element.latestStatus == 'accepted' ||
        element.latestStatus == 'failed_to_rts' || element.latestStatus == 'picked'){
            this.editaddresbutton = true
      }
      const trackingid =  element.forwardTrackingTimeline.trackingID
       if(trackingid){
         this.trackingID = false
       }else{
        this.trackingID = true
       }
    })
}

    // orderData.items.forEach(element => {
    //   if(element.latestStatus == 'cancelled' || element.latestStatus == 'packed'){
    //     this.trackingID= false
    //   }
    //    const trackingid =  element.forwardTrackingTimeline.trackingID
    //    if(trackingid){
    //     this.trackingID = false
    //    }else{
    //     this.trackingID = true
    //    }
    //  });
  }
  ngOnDestroy() {
    if (this.subscriptions$) {
      this.subscriptions$.unsubscribe();
    }
  }

  onClickItems(item){
    if(item.latestStatus == 'created' || item.latestStatus == 'accepted' ||
    item.latestStatus == 'failed_to_rts' || item.latestStatus == 'picked'){
        this.editaddresbutton = true
      }else{
        this.editaddresbutton = false
      }
    if(item.latestStatus == 'cancelled' || item.latestStatus == 'packed'){
      this.trackingID= false
    }else{
      this.trackingID= true
    }
    const trackingid =  item.forwardTrackingTimeline.trackingID
      if(trackingid){
        this.trackingID = false
      }else{
      this.trackingID = true
      }
    LocalStorageService.flush("defaultSelected");
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('top-header');
    // if (this.deviceInfo.browser === 'Safari' && (this.deviceInfo.device === 'iPad' || this.deviceInfo.os === 'Mac')){  //this.deviceInfo.os === 'Mac' &&
    //   this.changeleftPanelValue.emit(false);
    // }else if (this.isMobile  || this.isTablet){
    //   this.changeleftPanelValue.emit(false);
    // }else {
    //   this.changeleftPanelValue.emit(true);
    // }
    if (this.deviceInfo.browser === 'Safari' && this.isDesktopDevice && this.deviceInfo.os === 'Mac'){
      this.changeleftPanelValue.emit(false);
    }else if (this.isDesktopDevice){
      this.changeleftPanelValue.emit(true);
    }else {
      this.changeleftPanelValue.emit(false);
    }
    this.selectedItemId = item.orderItemID;
    this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.selectedItemId]);
    this.selectedItemDetails = item;
    this.selectedItemData.emit(item);
  }


  replaceDateOld(date) {
    return this.datePipe.transform(date, "d MMM , y");
  }
  replaceDate(date) {
    // console.log("Order date",date);
    let dateNew = date.split(" ")[0];
    return this.datePipe.transform(dateNew, "d MMM, y");
  }

  isBadInventory(item) {
    item.reverseTrackingTimeline.statusLog.forEach(element => {
      if (element.status === 'delivered' && item.qc && item.qcReason == 'bad_inventory') {
        return true;
      } else {
        return false;
      }
    });
  }

  showEditDetails(){
    if(this.auth.getTokenType() == 'partial') {
      this.sendOTP();
      return;
    }
    this.deliveryPopupdelay = true;
    this.deliveryPopup = true;
    LocalStorageService.set("editdetails", true);
    LocalStorageService.set("Orderdetails", this.orderData);
    document.querySelector("body").classList.add("hide-footer");
  }

  closeEditAddresspopup(event){
    document.querySelector("body").classList.remove("hide-footer");
    // console.log("ok")
    this.deliveryPopup = false;
    document.querySelector("body").classList.remove("stop-scrolling");
    if(!this.deviceService.isDesktop()) {
      setTimeout(() => {
        this.deliveryPopupdelay = false;
      }, 350);
    } else this.deliveryPopupdelay = false;
  }
  editdetailspaydataevent(event){
    if (this.orderId){
      this.selectedItemId = this.orderItemId;
        //Fetch data from state if already exist
        this.store.dispatch(new GetOrder(this.orderId));
        this.store.select(state => state.orderPlaced.orderPlaced).subscribe(result =>   {
          if (result) {
             this.orderData = result;

             this.settrackingid(this.orderData);
             if(this.orderData) {
              LocalStorageService.set('otpVerified', 'true');
              this.updatedAddr = true;
              LocalStorageService.set('editdetailsTostermsg', true);
              this.editdetailsevent.emit();
              setTimeout(() => {;
              this.updatedAddr = false;
              LocalStorageService.flush('otpVerified');
              }, 5000);
            }
             LocalStorageService.set('actorcutomername',this.orderData.billingAddress.customerName)
             LocalStorageService.set('channelSlug',this.orderData.channelSlug)
            //  this.defaultSelectedItem(this.orderData);
          }else {
            this.store.dispatch(new GetOrder(this.orderId));
            this.subscriptions$.add(
            this.store.pipe(select(getCurrentOrder)).subscribe(result1 => {
                if (result1) {
                  this.orderData = result1;

                  this.settrackingid(this.orderData);
                  if(this.orderData) {
                    this.updatedAddr = true;
                    LocalStorageService.set('editdetailsTostermsg', true);
                    this.editdetailsevent.emit();
                    setTimeout(() => {;
                    this.updatedAddr = false;
                    LocalStorageService.flush('otpVerified');
                    // LocalStorageService.flush('editdetailsTostermsg');
                    }, 5000);
                  }
                  LocalStorageService.set('actorcutomername',this.orderData.billingAddress.customerName)
                  LocalStorageService.set('channelSlug',this.orderData.channelSlug);
                }else {
                  this.orderData = [];
                  this.selectedItemDetails = [];
                }
              })
            );
          }
        });
    }else{
      this.alertService.showError("Invalid Url");
    }

}
transformDecimal(num) {
  return this.decimalPipe.transform(num, "2.");
}
// navigateNdrpage(DATA){
//   LocalStorageService.set("Orderdetails", DATA);
//   const shipmentId = localStorage.getItem('shipmentId')
//   DATA.items.filter(itemid => {
//     if(itemid.shipmentId = shipmentId){
//         this.router.navigate([ROUTES.ORDER_FAILED_DELIVERY, this.orderId, itemid.orderItemID]);
//     }
//   })
// }

navigateNdrpage(DATA){
  if(localStorage.getItem('orderIdURL') && localStorage.getItem('action')){
    DATA.items.filter(element => {
      if(element.latestStatus == 'created' || element.latestStatus == 'accepted' ||
          element.latestStatus == 'failed_to_rts' || element.latestStatus == 'picked'){
                // console.log("++++======",this.orderData)
                this.showEditDetails();
        }
      })
    }

  // console.log(DATA)
  LocalStorageService.set("Orderdetails", DATA);
  const shipmentId = localStorage.getItem('shipmentId')
  if(DATA.items.length > 1){
    DATA.items.filter(itemid => {
      if(itemid.shipmentId == shipmentId && (itemid.latestStatus == 'delivery_failed' || itemid.latestStatus == 'failed_delivery')){
          this.router.navigate([ROUTES.ORDER_FAILED_DELIVERY, this.orderId, itemid.orderItemID]);
      }
    })
  }else{
    DATA.items.filter(itemid => {
      if(itemid.shipmentId == shipmentId && (itemid.latestStatus == 'delivery_failed' || itemid.latestStatus == 'failed_delivery')){
          this.router.navigate([ROUTES.ORDER_FAILED_DELIVERY, this.orderId, itemid.orderItemID]);
      }
    })
  }
}

sendOTP() {
  this.router.navigate([ROUTES.VERIFY_OTP_CONFIRMATION, this.orderId]);
}

getStyleWithoutOpacity() {
  return {
    border: "1px solid" + this.userSettings.actionColor,
    color: this.userSettings.actionColor
  };
}
getStyleWithOpacity() {
  return {
    background: this.userSettings.actionColor,
    opacity: 0.05
  };
}
public moveToCancellation(itemId) {
  this.router.navigate([ROUTES.ORDER_CANCEL, this.orderId, itemId, ]);
  localStorage.setItem('bulkCancel','true');
}
}
