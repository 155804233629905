<style>
  .overflow-lenght {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
<div class="cancel-request-toster w-315 width330" [ngClass]="editdetailsRequest ? 'open-toster' : ''"
  *ngIf="editdetailsRequest">
  <span>
    <img class="check-circle" src="../../../../assets/images/check-circle.svg" />
    Your delivery address is updated.
  </span>
  <a class="close" (click)="closetosterclick()"><img src="../../../../assets/images/close-white.svg" /></a>
</div>

<div class="cancel-request-toster" [ngClass]="CancelItemToster ? 'open-toster' : ''" *ngIf="CancelItemToster"
  style="max-width: 400px;">
  <span>
    <img class="check-circle" src="../../../../assets/images/check-circle.svg" />
    Your order item is cancelled.
  </span>
  <a class="close" (click)="closetosterclick()"><img src="../../../../assets/images/close-white.svg" /></a>
</div>
<div class="cancel-request-toster" [ngClass]="returnItemToster ? 'open-toster' : ''" *ngIf="returnItemToster">
  <span>
    <img class="check-circle" src="../../../../assets/images/check-circle.svg" />
    Your return request has been submitted
  </span>
  <a class="close" (click)="closetosterclick()"><img src="../../../../assets/images/close-white.svg" /></a>
</div>

<div class="cancel-request-toster" [ngClass]="failedDeliveryRequest ? 'open-toster' : ''" *ngIf="failedDeliveryRequest">
  <span>
    <img class="check-circle" src="../../../../assets/images/check-circle.svg" />
    <ng-container *ngIf="resolutionCode == 'ACTF001'">
      Your request to re-attempt delivery has been submitted.
    </ng-container>
    <ng-container *ngIf="resolutionCode == 'ACTF002'">
      Your request to change delivery date has been submitted.
    </ng-container>
    <ng-container *ngIf="resolutionCode == 'ACTF003'">
      You have successfully cancelled this order.
    </ng-container>
    <ng-container *ngIf="resolutionCode == 'ACTF004'">
      Your request to report the courier mention reason as false has been
      submitted.
    </ng-container>
  </span>
  <a class="close" (click)="closetosterclick()"><img src="../../../../assets/images/close-white.svg" /></a>
</div>

<div class="cancel-request-toster failed_pop" [ngClass]="failedDeliverynegative ? 'open-toster' : ''"
  *ngIf="failedDeliverynegative">
  <span>
    <img class="check-circle"
      src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/alert-triangle-wht.svg?updatedAt=1637911673623" />
    Your desired action could not be submitted. Please try again.
  </span>
  <a class="close" (click)="closetosterclick()"><img src="../../../../assets/images/close-white.svg" /></a>
</div>

<div class="container">
  <div class="reason"
    *ngIf="(orderData?.riskScore | lowercase) == 'high' && orderData?.riskScoreReasons.includes('Address issue') && orderData?.isPincodeServiceable && selectedItemDetails && selectedItemDetails.productName && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0' ">
    Your delivery address is incomplete or inaccurate.&nbsp; <a (click)="verifyOTP ? sendOTP() : showEditDetails()"> {{
      verifyOTP ? 'Login to update address' :'Update address'}} </a>&nbsp;&nbsp;
    <span class="question" (click)="userdetailclick()">
      <ng-container *ngTemplateOutlet="loading"></ng-container>
    </span>
  </div>
  <div class="reason"
    *ngIf="!orderData?.isPincodeServiceable && ((orderData?.riskScore | lowercase) != 'high' || !orderData?.riskScoreReasons.includes('Address issue')) && selectedItemDetails && selectedItemDetails.productName && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'">
    Your delivery address is not serviceable.&nbsp; <a (click)="verifyOTP ? sendOTP() : showEditDetails()"> {{ verifyOTP
      ? 'Login to update address' :'Update address'}} </a>&nbsp;&nbsp;
    <span class="question" (click)="userdetailclick()">
      <ng-container *ngTemplateOutlet="loading"></ng-container>
    </span>
  </div>
  <div class="reason"
    *ngIf="(!orderData?.isPincodeServiceable) && (orderData?.riskScore | lowercase) == 'high' && orderData?.riskScoreReasons.includes('Address issue') && selectedItemDetails && selectedItemDetails.productName && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'">
    Your delivery address is incorrect/inaccurate, and the pincode provided is not serviceable.&nbsp; <a
      (click)="verifyOTP ? sendOTP() : showEditDetails()"> {{ verifyOTP ? 'Login to update address' :'Update address'}}
    </a>&nbsp;&nbsp;
    <span class="question" (click)="userdetailclick()">
      <ng-container *ngTemplateOutlet="loading"></ng-container>
    </span>
  </div>

  <ng-template #loading>
    <!-- <app-information-popover></app-information-popover> -->
    <div class="user-deatil mobile-none"
      [ngClass]="{'width321': !orderData?.isPincodeServiceable && ((orderData?.riskScore | lowercase) != 'high' || !orderData?.riskScoreReasons.includes('Address issue')) && selectedItemDetails.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'}">
      <h3>More information</h3>
      <a class="user-close-btn" (click)="userdetailclose($event)"><img
          src="https://ik.imagekit.io/2gwij97w0o/POE/close-icon.svg" /></a>
      <p
        *ngIf="(orderData?.riskScore | lowercase) == 'high' && orderData?.riskScoreReasons.includes('Address issue') && orderData?.isPincodeServiceable && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'">
        It appears that the delivery address you have provided is incomplete or inaccurate, possibly due to missing
        information. Please ensure that your address includes the following details:
      </p>
      <p
        *ngIf="!orderData?.isPincodeServiceable && ((orderData?.riskScore | lowercase) != 'high' || !orderData?.riskScoreReasons.includes('Address issue')) && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'">
        It appears that the delivery address you have provided is currently not serviceable. Please update the delivery
        address.
      </p>
      <p
        *ngIf="(!orderData?.isPincodeServiceable) && (orderData?.riskScore | lowercase) == 'high' && orderData?.riskScoreReasons.includes('Address issue') && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'">
        It appears that your delivery address is incorrect/inaccurate, and the pincode provided is not serviceable.
        Please ensure that your address includes the following details:
      </p>
      <div class="row"
        *ngIf="!(!orderData?.isPincodeServiceable && ((orderData?.riskScore | lowercase) != 'high' || !orderData?.riskScoreReasons.includes('Address issue')) && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0')">
        <div class="infoAddress">
          <div class="landmark">
          </div>
          A landmark
        </div>
        <div class="infoAddress">
          <div class="street">
          </div>
          Street name
        </div>
        <div class="infoAddress">
          <div class="building">
          </div>
          Building or apartment number
        </div>
        <div class="infoAddress">
          <div class="city">
          </div>
          City, town, or village
        </div>
      </div>
      <hr>
      <div class="button">
        <a (click)="verifyOTP ? sendOTP() : showEditDetails()">
          <div class="edit"></div> Update address
        </a>
      </div>
    </div>
  </ng-template>


  <div class="user-overlay mobile-user-detail" [ngClass]="userdetailsinfo ? 'user-deatil-show' : 'user-deatil-hide' ">
  </div>
  <div class="user-deatil mobile-user-detail" [ngClass]="userdetailsinfo ? 'user-deatil-show' : 'user-deatil-hide' ">
    <h3>More information</h3>
    <a class="user-close-btn" (click)="userdetailclose($event)"><img
        src="https://ik.imagekit.io/2gwij97w0o/POE/close-icon.svg" /></a>
    <p
      *ngIf="(orderData?.riskScore | lowercase) == 'high' && orderData?.riskScoreReasons.includes('Address issue') && orderData?.isPincodeServiceable && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'">
      It appears that the delivery address you have provided is incomplete or inaccurate, possibly due to missing
      information. Please ensure that your address includes the following details:
    </p>
    <p
      *ngIf="!orderData?.isPincodeServiceable && ((orderData?.riskScore | lowercase) != 'high' || !orderData?.riskScoreReasons.includes('Address issue')) && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'">
      It appears that the delivery address you have provided is currently not serviceable. Please update the delivery
      address.
    </p>
    <p
      *ngIf="(!orderData?.isPincodeServiceable) && (orderData?.riskScore | lowercase) == 'high' && orderData?.riskScoreReasons.includes('Address issue') && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0'">
      It appears that your delivery address is incorrect/inaccurate, and the pincode provided is not serviceable. Please
      ensure that your address includes the following details:
    </p>
    <div class="row"
      *ngIf="!(!orderData?.isPincodeServiceable && ((orderData?.riskScore | lowercase) != 'high' || !orderData?.riskScoreReasons.includes('Address issue')) && selectedItemDetails?.latestStatus != 'cancelled' && selectedItemDetails?.onHold != '0')">
      <div class="infoAddress">
        <div class="landmark">
        </div>
        A landmark
      </div>
      <div class="infoAddress">
        <div class="street">
        </div>
        Street name
      </div>
      <div class="infoAddress">
        <div class="building">
        </div>
        Building or apartment number
      </div>
      <div class="infoAddress">
        <div class="city">
        </div>
        City, town, or village
      </div>
    </div>
    <hr>
    <div class="button">
      <a (click)="verifyOTP ? sendOTP() : showEditDetails()">
        <div class="edit"></div> Update address
      </a>
    </div>
  </div>


  <div class="row" [class.right-panel-active]="leftPanelActive"
    [ngClass]="selectedItemDetails?.latestStatus == 'out_for_delivery' && orderData.isCOD === 0 ? 'height-600':''">
    <!-- <div [ngClass]="showLoader ? 'panel-left loading' : 'panel-left'"> -->
    <!-- <ng-container *ngIf="orderData && orderData.customerOrderNumber">     -->
    <!-- <div class="left-panel-hide" (click)="hideLeftPanel()">
                    <figure><img src="assets/images/close-left-panel.png" alt=""></figure>
                </div> -->
    <div class="panel-left p_panel-left" [class.right-panel-active]="leftPanelActive">
      <app-order-sidebar 
        (changeleftPanelValue)="displayLeftPanel($event)"
        (selectedItemData)="onSelectShowOrderItemDetail($event)"
        (editdetailsevent)="editdetailsevent($event)"></app-order-sidebar>
      <div class="mob-footer">
        <p>
          Need further assistance?
          <a href="mailto:{{
              userSettings && userSettings.contactMailId
                ? userSettings.contactMailId
                : ''
            }}">
            Contact us
          </a>
        </p>
        <app-mob-header></app-mob-header>
      </div>
    </div>
    <!-- <div [ngClass]="showLoader ? 'panel-right loading' : 'panel-right'"> -->
    <div class="panel-right p_panel-right" [class.left-panel-active]="leftPanelActive">
      <ng-container *ngIf="selectedItemDetails && selectedItemDetails.productName">
        <!-- <div class="left-panel-show">
                    <figure><img src="assets/images/back-arrow.png" alt=""></figure>
                     <span> Order {{orderData ? orderData.customerOrderNumber:''}} </span>
                    <img src="{{ userSettings && userSettings.brandLogoUrl ? userSettings.brandLogoUrl:'' }}" alt="" />

                </div> -->
        <div class="ordered-items ordered-items-cus">
          <!-- {{selectedItemDetails | json }} -->
          <div class="item-list active">
            <div class="item-info" [ngClass]="{'pl-0' :isMobile && verifyOTP}">
              <div class="item-name">
                <button class="close-track" (click)="showLeftPanel()" *ngIf="!isDesktopDevice && !verifyOTP"></button>
                <span class="order-heading"> {{isMobile && verifyOTP ? 'Tracking details' : 'Item details'}}</span>

                <div class="right-block">
                  <ng-container *ngIf="selectedItemDetails?.invoiceUrl && !verifyOTP">
                    <!-- <a href=" {{selectedItemDetails?.invoiceUrl}} " class="download_invoice" target="_blank" download> -->
                    <a [href]="SecureinvoiceUrl" class="download_invoice" target="_blank" download>
                      Invoice
                      <img class="download-icon" src="https://ik.imagekit.io/2gwij97w0o/POE/icon-download-new.svg" />
                    </a>
                  </ng-container>
                  <ng-container *ngIf="
                      (userSettings?.supportPhone &&
                        userSettings?.supportPhone != 'null') ||
                      (userSettings?.supportEmail &&
                        userSettings?.supportEmail != 'null') ||
                      (userSettings?.supportUrl &&
                        userSettings?.supportUrl != 'null')
                    ">
                    <a (click)="openHelpModal()" [ngStyle]="{ color: color ? getColor : '#828282' }">Help ?</a>
                  </ng-container>
                </div>

                <!-- <ng-container *ngIf="(userSettings?.supportPhone && userSettings?.supportPhone!='null') || (userSettings?.supportEmail && userSettings?.supportEmail!='null') || (userSettings?.supportUrl && userSettings?.supportUrl!='null')">
                                    <a (click)="openHelpModal()"
                                    [ngStyle]="{color:color?getColor:'#828282'} ">Help ?</a>
                                </ng-container> -->
              </div>
              <!-- <ng-container *ngIf="selectedItemDetails.additionalProductInfo">
                                <div class="item-color-size">
                                    <ng-container
                                        *ngFor="let productinfo of selectedItemDetails.additionalProductInfo | keyvalue; last as isLast">
                                        {{ productinfo.key | titlecase}}: <span> {{ productinfo.value }}</span><span
                                            *ngIf="!isLast"> |</span>
                                    </ng-container>
                                </div>
                            </ng-container> -->
            </div>
          </div>

          <!-- {{selectedItemDetails.latestStatus}} -->
          <!-- <div class="banner-title" *ngIf="selectedItemDetails.latestRevisedExpectedDeliveryDate"><img src="../../assets/images/delivery-vehicle.svg" alt=""> <span>Expected delivery date on {{selectedItemDetails?.latestRevisedExpectedDeliveryDate | date:'EE, MMM d, y' }}</span></div> -->
          <div class="order-scroll">
            <div *ngIf="verifyOTP && isMobile">
              <p class="order-description-verify" *ngIf="orderData?.items?.length > 0">
                You purchased
                <span>{{ orderData && orderData.items ? orderData.items.length : "" }}
                  {{ orderData && orderData.items.length > 1 ? " items" : " item" }}
                </span>
                in order
                <span> {{
                  orderData?.vendorOrderNumber
                  ? orderData?.vendorOrderNumber
                  : orderData?.customerOrderNumber
                  }} </span>
                <ng-container *ngIf="orderData.orderDate">
                  on
                  <span>
                    {{ orderData ? replaceDate(orderData.orderDate) : "" }}
                  </span></ng-container>
              </p>
            </div>
            <app-edd *ngIf="selectedItemDetails.timeLine == 'forwardTrackingTimeline'"></app-edd>
            <!-- <ng-container *ngIf="selectedItemDetails.timeLine == 'forwardTrackingTimeline'">
                    <div *ngIf="(selectedItemDetails.latestStatus != 'picked_up' && selectedItemDetails.latestStatus != 'return_cancelled' && selectedItemDetails.latestStatus != 'cancelled'
                    && selectedItemDetails.latestStatus != 'rto_intransit'
                    && selectedItemDetails.latestStatus != 'rto'
                    && selectedItemDetails.latestStatus != 'rto_out_for_delivery'
                    && selectedItemDetails.latestStatus != 'rto_requested'
                    && selectedItemDetails.latestStatus != 'rto_created'
                    && selectedItemDetails.latestStatus != 'packed' && selectedItemDetails.latestStatus != 'created' && selectedItemDetails.latestStatus != 'failed_delivery'
                    && selectedItemDetails.latestStatus != 'delivered'
                    && selectedItemDetails.latestStatus != 'ndr_resolution_submitted'
                    && selectedItemDetails.latestStatus != 'approved' && selectedItemDetails.latestStatus != 'pickup_cancelled'
                    && selectedItemDetails.latestStatus != 'pickup_pending' && selectedItemDetails.latestStatus != 'rto_delivered_warehouse'
                    && selectedItemDetails.latestStatus != 'delivered_warehouse' && selectedItemDetails.latestStatus != 'return_cancelled') ||
                    (selectedItemDetails.latestStatus == 'intransit' )">


                    <div class="banner-title" *ngIf="selectedItemDetails?.latestRevisedExpectedDeliveryDate || selectedItemDetails.expectedDeliveryDate "><img src="../../assets/images/delivery-vehicle.svg" alt="">
                            <span>Expected delivery by {{selectedItemDetails?.latestRevisedExpectedDeliveryDate
                                ? (selectedItemDetails.latestRevisedExpectedDeliveryDate | date:'EEEE MMM d, y')
                                : (selectedItemDetails.expectedDeliveryDate | date:'EEEE MMM d, y') }}


                            </span>
                            <div class="icon-tooltip">
                                <ng-container *ngIf="(selectedItemDetails.latestRevisedExpectedDeliveryDate > selectedItemDetails.expectedDeliveryDate); else delayBlock">
                                    <a>
                                        <img src="../../../../assets/images/info-green.svg">
                                        <span class="delivery-deley">
                                            Due to intransit delay, the expected delivery date has been revised from "{{selectedItemDetails.expectedDeliveryDate | date:'EE MMM d, y'}}" to "<b style="font-weight: normal;" >{{selectedItemDetails?.latestRevisedExpectedDeliveryDate | date:'EE MMM d, y' }}"</b>
                                        </span>
                                    </a>
                                </ng-container>
                                <ng-template #delayBlock>
                                    <a *ngIf="EddtodaysDate > selectedItemDetails.expectedDeliveryDate">
                                        <img src="../../../../assets/images/info-green.svg">
                                        <span class="delivery-deley">
                                            Delivery for your order has been delayed. We will keep you updated
                                        </span>
                                    </a>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    </ng-container> -->
            <ng-container *ngIf="selectedItemDetails">
              <!-- Refund Timeline Start Here-->
              <ng-container *ngIf="selectedItemDetails.timeLine == 'refundTimeLine'">
                <!-- <ng-container *ngIf="(selectedItemDetails.latestStatus== 'delivered_warehouse' || selectedItemDetails.latestStatus== 'warehouse_delivered') && selectedItemDetails.qc==1 "> -->
                <div [ngClass]="
                    !selectedItemDetails.refundTimelineStausLog.includes(
                      null
                    ) &&
                    selectedItemDetails.refundTimelineStausLog[0].statusLength >
                      1
                      ? 'order-process step-two refund step-four active-four'
                      : 'order-process step-two step-four'
                  " class="refund-raised">
                  <ng-container *ngFor="let refundStatus of refundStatusArray">
                    <div (click)="handleClick()" *ngIf="refundStatus == 'created'" [ngClass]="
                        selectedItemDetails.latestStatus == 'approved' ||
                        selectedItemDetails.latestStatus == 'created'
                          ? 'refund-raised active'
                          : ''
                      ">
                      <span class="i-status active" *ngIf="
                          selectedItemDetails.latestStatus == 'approved' ||
                          selectedItemDetails.latestStatus == 'created'
                        ">
                        Now
                      </span>
                      <span class="i-status" *ngIf="
                          selectedItemDetails.latestStatus == 'initiated' ||
                          selectedItemDetails.latestStatus == 'refund_initiated'
                        ">
                        DONE
                      </span>
                      <p>Refund Approved</p>
                      <br />
                      <ng-container *ngIf="
                          selectedItemDetails.latestStatus == 'approved' ||
                          selectedItemDetails.latestStatus == 'created'
                        ">
                        <span class="no-tooltip" *ngIf="refundToolAndRemark(refundStatus)">
                          On
                          {{ toolTipMessage }}
                        </span>
                      </ng-container>
                      <!-- <span *ngIf="selectedItemDetails.latestStatus == 'approved'"
                                        class="oder-status-note" [innerHtml]="remarkMessage"></span> -->

                      <span *ngIf="
                          selectedItemDetails.latestStatus == 'approved' ||
                          selectedItemDetails.latestStatus == 'created'
                        " class="oder-status-note1 first-boxxx">
                        <figure class="imageDisplay" *ngIf="!verifyOTP">
                          <ng-container *ngIf="
                              selectedItemDetails &&
                              selectedItemDetails.productImageUrl
                            ">
                            <img class="image" src="{{
                                selectedItemDetails &&
                                selectedItemDetails.productImageUrl
                                  ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                    selectedItemDetails.productImageUrl +
                                    '?tr=w-300'
                                  : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                    selectedItemDetails.productImageUrl +
                                    '?tr=w-300'
                              }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                              onerror="this.style.display='none'" />
                          </ng-container>
                          <span class="item-name-price">
                            <span [ngClass]="
                                selectedItemDetails.productName.length > 38
                                  ? 'overflow-lenght'
                                  : ''
                              ">{{
                              selectedItemDetails
                              ? selectedItemDetails.productName
                              : ""
                              }}</span>
                              <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                                ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                              </ng-container>

                            <span *ngIf="
                                selectedItemDetails.productName.length > 38
                              " class="product-hover">{{
                              selectedItemDetails
                              ? selectedItemDetails.productName
                              : ""
                              }}</span>

                            <p>
                              {{ rupeeSymbol }}
                              {{
                              selectedItemDetails?.invoiceTotal
                              ? transformDecimal(
                              selectedItemDetails?.invoiceTotal
                              )
                              : transformDecimal(selectedItemDetails?.lineItemTotal)
                              }}
                              <span *ngIf="selectedItemDetails.productSize">| Size
                                {{
                                selectedItemDetails
                                ? selectedItemDetails.productSize
                                : ""
                                }}</span>
                            </p>
                          </span>
                        </figure>
                      </span>
                      <span *ngIf="
                          selectedItemDetails.latestStatus == 'approved' ||
                          selectedItemDetails.latestStatus == 'created'
                        " class="oder-status-note1">
                        <figure>
                          <span class="center-alignment">
                            <span class="checkbox" *ngIf="
                                selectedItemDetails.latestStatus ==
                                  'approved' ||
                                selectedItemDetails.latestStatus == 'created'
                              ">
                              <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                            </span>

                            <span *ngIf="
                                selectedItemDetails.latestStatus ==
                                  'approved' ||
                                selectedItemDetails.latestStatus == 'created'
                              "
                              style="padding-left: 6px; color: #5DB075;font-weight: bold; font-size:13px;">Approved</span>
                          </span>
                        </figure>
                        <span *ngIf="
                            selectedItemDetails.latestStatus == 'approved' ||
                            selectedItemDetails.latestStatus == 'created'
                          " class="oder-status-note" [innerHtml]="remarkMessage"></span>
                      </span>
                      <!-- <span class="i-status active"
                                            *ngIf="selectedItemDetails.latestStatus == 'approved'"> Now </span>
                                        <span class="i-status" *ngIf="selectedItemDetails.latestStatus == 'initiated'">
                                            DONE </span>
                                        <span *ngIf="selectedItemDetails.latestStatus == 'approved'"
                                            class="oder-status-note" [innerHtml]="remarkMessage"></span> -->
                    </div>
                    <div (click)="handleClick()" *ngIf="
                        refundStatus == 'initiated' ||
                        refundStatus == 'refund_initiated'
                      " [ngClass]="
                        (!selectedItemDetails.refundTimelineStausLog.includes(
                          null
                        ) &&
                          selectedItemDetails.refundTimelineStausLog[0].compareStatus.includes(
                            refundStatus
                          )) ||
                        selectedItemDetails.latestStatus == 'refund_initiated'
                          ? 'active'
                          : ''
                      " class="refund-initiated">
                      <span class="i-status" *ngIf="
                          selectedItemDetails.latestStatus == 'approved' ||
                          selectedItemDetails.latestStatus == 'created'
                        ">
                        NEXT
                      </span>
                      <span class="i-status active" *ngIf="
                          selectedItemDetails.latestStatus == 'initiated' ||
                          selectedItemDetails.latestStatus == 'refund_initiated'
                        " style="background-color: #6FCF97;">
                        Now
                      </span>
                      <p [ngStyle]="{
                          color:
                            selectedItemDetails.latestStatus == 'initiated' ||
                            selectedItemDetails.latestStatus ==
                              'refund_initiated'
                              ? '#6FCF97'
                              : '#B7B7B7'
                        }">
                        Refund Done
                      </p>
                      <br />
                      <ng-container *ngIf="
                          (!selectedItemDetails.refundTimelineStausLog.includes(
                            null
                          ) &&
                            selectedItemDetails.refundTimelineStausLog[0].compareStatus.includes(
                              refundStatus
                            )) ||
                          selectedItemDetails.latestStatus == 'refund_initiated'
                        ">
                        <span class="no-tooltip" *ngIf="refundToolAndRemark(refundStatus)">On {{ toolTipMessage
                          }}</span>
                      </ng-container>

                      <!-- <span *ngIf="selectedItemDetails.latestStatus == 'initiated'"
                                            class="oder-status-note" [innerHtml]="remarkMessage"></span> -->
                    </div>
                  </ng-container>
                </div>

                <div class="refundAlign">
                  <span *ngIf="
                      selectedItemDetails.latestStatus == 'initiated' ||
                      selectedItemDetails.latestStatus == 'refund_initiated'
                    " class="oder-status-note1 first-boxxx">
                    <figure class="imageDisplay" *ngIf="!verifyOTP">
                      <ng-container *ngIf="
                          selectedItemDetails &&
                          selectedItemDetails.productImageUrl
                        ">
                        <img class="image" src="{{
                            selectedItemDetails &&
                            selectedItemDetails.productImageUrl
                              ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                selectedItemDetails.productImageUrl +
                                '?tr=w-300'
                              : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                selectedItemDetails.productImageUrl +
                                '?tr=w-300'
                          }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                          onerror="this.style.display='none'" />
                      </ng-container>
                      <span class="item-name-price">
                        <span [ngClass]="
                            selectedItemDetails.productName.length > 38
                              ? 'overflow-lenght'
                              : ''
                          ">{{
                          selectedItemDetails
                          ? selectedItemDetails.productName
                          : ""
                          }}</span>
                          <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                            ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                          </ng-container>

                        <span *ngIf="selectedItemDetails.productName.length > 38" class="product-hover">{{
                          selectedItemDetails
                          ? selectedItemDetails.productName
                          : ""
                          }}</span>
                        <p>
                          {{ rupeeSymbol }}
                          {{
                          selectedItemDetails?.invoiceTotal
                          ? transformDecimal(
                          selectedItemDetails?.invoiceTotal
                          )
                          : transformDecimal(selectedItemDetails?.lineItemTotal)
                          }}
                          <span *ngIf="selectedItemDetails.productSize">| Size
                            {{
                            selectedItemDetails
                            ? selectedItemDetails.productSize
                            : ""
                            }}</span>
                        </p>
                      </span>
                    </figure>
                  </span>
                  <span *ngIf="
                      selectedItemDetails.latestStatus == 'initiated' ||
                      selectedItemDetails.latestStatus == 'refund_initiated'
                    " class="oder-status-note1">
                    <figure>
                      <span class="center-alignment">
                        <span class="checkbox" *ngIf="
                            selectedItemDetails.latestStatus == 'initiated' ||
                            selectedItemDetails.latestStatus ==
                              'refund_initiated'
                          ">
                          <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                        </span>
                        <span *ngIf="
                            selectedItemDetails.latestStatus == 'initiated' ||
                            selectedItemDetails.latestStatus ==
                              'refund_initiated'
                          " style="padding-left: 6px; color: #5DB075;font-weight: bold;">Completed
                        </span>
                      </span>

                      <span *ngIf="
                          selectedItemDetails.latestStatus == 'initiated' ||
                          selectedItemDetails.latestStatus == 'refund_initiated'
                        " class="oder-status-note" [innerHtml]="remarkMessage"></span>
                    </figure>
                  </span>
                </div>
              </ng-container>
              <!--End Here-->
              <!--Reverse Tracking Timeline Start Here-->
              <ng-container *ngIf="
                  selectedItemDetails.timeLine == 'reverseTrackingTimeline'
                ">
                <!-- Cancel order -->
                <ng-container *ngIf="
                    returnCancelledTimelineStatus.includes(
                      selectedItemDetails.latestStatus
                    );
                    else reverseTimelineCond
                  ">
                  <div class="order-process step-two cancelled">
                    <ng-container *ngFor="
                        let returnCancel of selectedItemDetails
                          .reverseTrackingTimeline.statusLog;
                        let i = index
                      ">
                      <div *ngIf="returnCancel.status == 'created'" class="return-initiated">
                        <span class="i-status"> DONE </span>
                        <!-- <p>  Return Initiated 1</p> -->
                        <p>Shipping soon</p>
                        <!-- <span *ngIf="returnCancel.status == 'created'"
                                                class="no-tooltip">
                                                {{ returnCancel.updatedAt ? replaceDate(returnCancel.updatedAt) :'' }}.
                                            </span> -->
                        <!-- <span class="i-status"> DONE </span> -->
                      </div>

                      <div class="small-status custom-transit" *ngIf="i == 0">
                        <span class="i-status"> DONE </span>
                        <p class="small">In transit</p>
                      </div>

                      <div *ngIf="
                          returnCancel.status ==
                          selectedItemDetails.latestStatus
                        " class="cancelled-i active">
                        <span class="i-status active" style="background-color: #6FCF97;">
                          Now
                        </span>
                        <ng-container *ngIf="
                            returnCancel.remark.includes('QC fail') &&
                              (selectedItemDetails.latestStatus ==
                                'pickup_cancelled' ||
                                selectedItemDetails.latestStatus ==
                                  'cancelled_order');
                            else delivered
                          ">
                          <p style="color:#6FCF97;">
                            {{
                            selectedItemDetails?.returnType == "refund"
                            ? "Refund"
                            : "Exchange"
                            }}
                          </p>

                          <span *ngIf="
                              returnCancel.status ==
                              selectedItemDetails.latestStatus
                            " class="no-tooltip">
                            <!-- Cancelled on -->
                            <!-- On {{ selectedItemDetails?.expectedDeliveryDate ?
                                            replaceDate(selectedItemDetails.expectedDeliveryDate) :'' }}. -->
                            On
                            {{
                            returnCancel.updatedAt
                            ? replaceDate(returnCancel.updatedAt)
                            : ""
                            }}.
                          </span>
                        </ng-container>

                        <ng-template #delivered>
                          <p style="color:#6FCF97;">Delivered</p>

                          <span class="no-tooltip">
                            <!-- Cancelled on -->
                            <!-- On {{ selectedItemDetails?.expectedDeliveryDate ?
                                                replaceDate(selectedItemDetails.expectedDeliveryDate) :'' }}. -->
                            On {{ deliveredDate ? (deliveredDate | date: 'fullDate' ) : "" }}
                          </span>
                        </ng-template>
                        <!-- <span class="i-status active"> Now </span> -->
                        <!-- <span class="oder-status-note"> Your return has been cancelled successfully.
                                            </span> -->
                      </div>
                    </ng-container>
                  </div>
                  <div class="refundAlign">
                    <span class="oder-status-note1 first-boxxx">
                      <figure class="imageDisplay" *ngIf="!verifyOTP">
                        <ng-container *ngIf="
                            selectedItemDetails &&
                            selectedItemDetails.productImageUrl
                          ">
                          <img class="image" src="{{
                              selectedItemDetails &&
                              selectedItemDetails.productImageUrl
                                ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                                : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                            }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                            onerror="this.style.display='none'" />
                        </ng-container>
                        <span class="item-name-price">
                          <span [ngClass]="
                              selectedItemDetails.productName.length > 38
                                ? 'overflow-lenght'
                                : ''
                            ">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                            <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                              ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                            </ng-container>

                          <span *ngIf="selectedItemDetails.productName.length > 38" class="product-hover">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                          <p>
                            {{ rupeeSymbol }}
                            {{
                            selectedItemDetails?.invoiceTotal
                            ? transformDecimal(
                            selectedItemDetails?.invoiceTotal
                            )
                            : transformDecimal(selectedItemDetails?.lineItemTotal)
                            }}
                            <span *ngIf="selectedItemDetails.productSize">| Size
                              {{
                              selectedItemDetails
                              ? selectedItemDetails.productSize
                              : ""
                              }}</span>
                          </p>
                        </span>
                      </figure>
                    </span>
                    <ng-container *ngFor="
                        let returnCancel of selectedItemDetails
                          .reverseTrackingTimeline.statusLog
                      ">
                      <span *ngIf="
                          returnCancel.status ==
                          selectedItemDetails.latestStatus
                        " class="oder-status-note1">
                        <figure>
                          <span class="center-alignment">
                            <span class="checkbox" *ngIf="
                                returnCancel.status ==
                                selectedItemDetails.latestStatus
                              ">
                              <img src="assets/images/cancel-icon.svg" />
                            </span>

                            <ng-container *ngIf="
                                returnCancel.remark.includes('QC fail') &&
                                (selectedItemDetails.latestStatus ==
                                  'pickup_cancelled' ||
                                  selectedItemDetails.latestStatus ==
                                    'cancelled_order') &&
                                (selectedItemDetails.returnType == 'exchange' ||
                                  selectedItemDetails.returnType == 'replace')
                              ">
                              <span *ngIf="
                                  returnCancel.status ==
                                  selectedItemDetails.latestStatus
                                " style="padding-left: 6px; color: red;font-weight: bold; font-size:13px;">Exchange
                                Failed</span>
                            </ng-container>
                            <ng-container *ngIf="
                                returnCancel.remark.includes('QC fail') &&
                                (selectedItemDetails.latestStatus ==
                                  'pickup_cancelled' ||
                                  selectedItemDetails.latestStatus ==
                                    'cancelled_order') &&
                                selectedItemDetails.returnType == 'refund'
                              ">
                              <span *ngIf="
                                  returnCancel.status ==
                                  selectedItemDetails.latestStatus
                                " style="padding-left: 6px; color: red;font-weight: bold; font-size:13px;">Refund
                                Failed</span>
                            </ng-container>

                            <ng-container *ngIf="
                                !(
                                  returnCancel.remark.includes('QC fail') &&
                                  (selectedItemDetails.latestStatus ==
                                    'pickup_cancelled' ||
                                    selectedItemDetails.latestStatus ==
                                      'cancelled_order')
                                )
                              ">
                              <span *ngIf="
                                  returnCancel.status ==
                                  selectedItemDetails.latestStatus
                                " style="padding-left: 6px; color: red;font-weight: bold;">Return Cancelled</span>
                            </ng-container>
                          </span>

                          <ng-container>
                            <span *ngIf="
                                selectedItemDetails.latestStatus ==
                                  'return_cancelled' ||
                                (returnCancelledTimelineStatus.includes(
                                  'return_cancelled'
                                ) &&
                                  returnCancel.status == 'return_cancelled')
                              " class="oder-status-note">As per your request, the return for this item has
                              been cancelled. Please do not accept, if pickup is
                              attempted.</span>

                            <span *ngIf="
                                (selectedItemDetails.latestStatus ==
                                  'pickup_cancelled' ||
                                  selectedItemDetails.latestStatus ==
                                    'cancelled_order' ||
                                  (returnCancel.status == 'cancelled_order' ||
                                    returnCancel.status ==
                                      'pickup_cancelled')) &&
                                !returnCancel.remark.includes('QC fail')
                              " class="oder-status-note">We regret to inform you that your pickup has been
                              cancelled due to multiple failed attempts.</span>
                            <span *ngIf="
                                ((selectedItemDetails.latestStatus ==
                                  'cancelled_order' ||
                                  selectedItemDetails.latestStatus ==
                                    'pickup_cancelled') &&
                                  returnCancel.remark.includes('QC fail')) ||
                                (selectedItemDetails.latestStatus ==
                                  'warehouse_delivered' ||
                                  selectedItemDetails.latestStatus ==
                                    'delivered_warehouse')
                              " class="oder-status-note">Your returned item has failed our quality check,
                              therefore we are unable to process the
                              {{
                              selectedItemDetails?.returnType == "refund"
                              ? "refund"
                              : "exchange"
                              }}. Please contact
                              <a class="support-email" href="mailto:{{
                                  userSettings && userSettings?.supportEmail
                                    ? userSettings?.supportEmail
                                    : ''
                                }}">{{ userSettings?.supportEmail }}</a>
                              for further details</span>
                          </ng-container>

                          <!-- <span class="oder-status-note">
                                                    <p
                                                        *ngIf="selectedItemDetails?.reverseTrackingTimeline?.courierName">
                                                        Courier Name -
                                                        <b>{{selectedItemDetails?.reverseTrackingTimeline?.courierName}}</b>
                                                    </p>
                                                    <p *ngIf="selectedItemDetails?.reverseTrackingTimeline?.trackingID">
                                                        Tracking number
                                                        <b>#{{selectedItemDetails?.reverseTrackingTimeline?.trackingID}}</b>
                                                    </p>
                                                </span> -->
                          <span class="oder-status-note">
                            <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                              (click)="openUpdateModal('cancel')">See all updates
                            </a>
                          </span>
                        </figure>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="
                        selectedItemDetails?.returnPolicy?.isReturnable ===
                          true &&
                        userSettings?.allowReturns &&
                        returnCancelledTimelineStatus.includes(
                          selectedItemDetails.latestStatus
                        ) &&
                        getWindowReturnDate()
                      ">
                      <span class="oder-status-note1 return-cancel">
                        <figure>
                          <span class="cancelbutton">
                            <a [ngStyle]="getStyleWithoutOpacity()" class="layout" (click)="
                                moveToReturnProcess(
                                  selectedItemDetails.orderItemID
                                )
                              ">Return Item
                              <span [ngStyle]="getStyleWithOpacity()"></span>
                            </a>
                          </span>
                        </figure>
                      </span>
                    </ng-container>

                    <span class="oder-status-note1" *ngIf="
                        returnCancelledTimelineStatus.includes(
                          selectedItemDetails.latestStatus
                        ) && getWindowReturnDate()
                      ">
                      <p class="note-status note-status-exclusive">
                        <img src="https://ik.imagekit.io/2gwij97w0o/POE/info.svg" />
                        <span>
                          Exchange/Return window will be closed on
                          {{ windowDate ? windowDate : "" }}
                        </span>
                      </p>
                    </span>

                    <span class="oder-status-note1" *ngIf="
                        returnCancelledTimelineStatus.includes(
                          selectedItemDetails.latestStatus
                        ) && !getWindowReturnDate()
                      ">
                      <p class="note-status note-status-exclusive">
                        <img src="https://ik.imagekit.io/2gwij97w0o/POE/info.svg" /><span>
                          Exchange/Return window closed on
                          {{ windowDate ? windowDate : "" }}
                        </span>
                      </p>
                    </span>
                  </div>
                </ng-container>
                <ng-template #reverseTimelineCond>
                  <ng-container *ngIf="
                      approved.includes(selectedItemDetails?.latestStatus) ||
                      pickup_pending.includes(selectedItemDetails?.latestStatus)
                    ">
                    <div class="banner-title">
                      <img src="../../assets/images/delivery-vehicle.svg" alt="" />
                      <span>
                        Expected pickup by
                        {{
                        selectedItemDetails?.reverseTrackingTimeline
                        .expectedPickupDate
                        ? replaceDate(
                        selectedItemDetails?.reverseTrackingTimeline
                        .expectedPickupDate
                        )
                        : ""
                        }}
                      </span>
                      <div class="icon-tooltip" *ngIf="
                          selectedItemDetails?.reverseTrackingTimeline
                            .isPickupDelay &&
                          !fail_delivery.includes(
                            selectedItemDetails?.latestStatus
                          ) &&
                          userSettings?.notifyDelayedDeliveryPickup
                        ">
                        <a>
                          <img src="../../../../assets/images/info-green.svg" />
                          <span class="delivery-deley">{{
                            userSettings?.delayedPickupMessage
                            }}</span>
                        </a>
                      </div>
                    </div>
                  </ng-container>
                  <div [ngClass]="
                      selectedItemDetails.reverseTimelineStatusLog[0]
                        .statusLength > 1
                        ? 'order-process step-two step-four ' +
                          statusActiveClass[
                            selectedItemDetails.reverseTimelineStatusLog[0]
                              .statusLength
                          ]
                        : 'order-process refund step-two step-four'
                    ">
                    <ng-container *ngFor="let status of reverseStatusArray">
                      <div (click)="handleClick()" *ngIf="status == 'created'
                         ||  status == 'carrier_out_for_pick_up_from_customer' " [ngClass]="
                          reverse_all_status.includes(
                            selectedItemDetails?.latestStatus
                          )
                            ? 'active'
                            : 'not-active'
                        " class="return-initiated">
                        <span class="i-status active" *ngIf="
                            reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          Now
                        </span>
                        <span class="i-status" *ngIf="
                            !reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          DONE
                        </span>
                        <!-- {{ reverseStatusRename[status] | titlecase}} -->
                        <p>{{ " Pickup Soon " }}</p>
                        <ng-container *ngIf="
                            fail_delivery.includes(
                              selectedItemDetails?.latestStatus
                            ) ||
                            pickup.includes(selectedItemDetails?.latestStatus)
                          ">
                          <span class="no-tooltip" *ngIf="
                              reverseToolAndMessage(
                                selectedItemDetails.latestStatus
                              )
                            ">On {{ toolTipMessage }}</span>
                        </ng-container>
                        <ng-container *ngIf="
                            requested.includes(
                              selectedItemDetails?.latestStatus
                            ) ||
                              approved.includes(
                                selectedItemDetails?.latestStatus
                              ) ||
                              pickup_pending.includes(
                                selectedItemDetails?.latestStatus
                              );
                            else outForPickup
                          ">
                          <span class="no-tooltip" *ngIf="
                              reverseToolAndMessage(
                                selectedItemDetails.latestStatus
                              )
                            ">
                            <!-- Pickup
                                                    by {{
                                                    selectedItemDetails?.reverseTrackingTimeline.revisedExpectedPickupDate
                                                    ?
                                                    replaceDate(selectedItemDetails?.reverseTrackingTimeline.revisedExpectedPickupDate)
                                                    :'' }} -->
                          </span>
                        </ng-container>
                        <!-- <ng-container *ngIf="pickup.includes(selectedItemDetails?.latestStatus);">
                                                <span class="no-tooltip"
                                                    *ngIf="reverseToolAndMessage(selectedItemDetails.latestStatus)  ">Pickup by
                                                    {{ toolTipMessage }}</span>
                                            </ng-container> -->
                        <!-- <span *ngIf="selectedItemDetails.latestStatus == 'created'"
                                                class="oder-status-note" [innerHtml]="remarkMessage"></span> -->

                        <span *ngIf="
                            reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note1 first-boxxx">
                          <figure class="imageDisplay" *ngIf="!verifyOTP">
                            <ng-container *ngIf="
                                selectedItemDetails &&
                                selectedItemDetails.productImageUrl
                              ">
                              <img class="image" src="{{
                                  selectedItemDetails &&
                                  selectedItemDetails.productImageUrl
                                    ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                    : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                                onerror="this.style.display='none'" />
                            </ng-container>
                            <span class="item-name-price">
                              <span [ngClass]="
                                  selectedItemDetails.productName.length > 38
                                    ? 'overflow-lenght'
                                    : ''
                                ">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                                <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                                  ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                                </ng-container>

                              <span *ngIf="
                                  selectedItemDetails.productName.length > 38
                                " class="product-hover">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                              <p>
                                {{ rupeeSymbol }}
                                {{
                                selectedItemDetails?.invoiceTotal
                                ? transformDecimal(
                                selectedItemDetails?.invoiceTotal
                                )
                                : transformDecimal(selectedItemDetails?.lineItemTotal)
                                }}
                                <span *ngIf="selectedItemDetails.productSize">| Size
                                  {{
                                  selectedItemDetails
                                  ? selectedItemDetails.productSize
                                  : ""
                                  }}</span>
                              </p>
                            </span>
                          </figure>
                        </span>
                        <span *ngIf="
                            reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note1">
                          <figure>
                            <span class="center-alignment">
                              <span class="checkbox" *ngIf="
                                  reverse_all_status.includes(
                                    selectedItemDetails?.latestStatus
                                  ) &&
                                  !fail_delivery.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                ">
                                <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                              </span>
                              <span class="checkbox" *ngIf="
                                  fail_delivery.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                ">
                                <img src="assets/images/cancel-icon.svg" />
                              </span>
                              <span *ngIf="
                                  requested.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold; font-size:13px;">Return
                                Requested</span>
                              <span *ngIf="
                                  approved.includes(
                                    selectedItemDetails?.latestStatus
                                  ) ||
                                  pickup_pending.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold;font-size:13px;">Request
                                Approved</span>

                              <span *ngIf="
                                  pickup.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold;font-size:13px;">Out For
                                Pickup</span>
                              <span *ngIf="
                                  fail_delivery.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: red;font-weight: bold;font-size:13px;">Pickup Failed
                              </span>
                              <!-- <span *ngIf="pickup_pending.includes(selectedItemDetails?.latestStatus)"
                                                            style="padding-left: 10px; color: #5DB075;font-weight: bold; font-size:13px;">Out for pickup
                                                        </span> -->
                            </span>

                            <span *ngIf="
                                requested.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">{{ remarkMessage }}</span>
                            <span *ngIf="
                                approved.includes(
                                  selectedItemDetails?.latestStatus
                                ) ||
                                pickup_pending.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">{{ remarkMessage }}</span>
                            <span *ngIf="
                                pickup.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">{{ remarkMessage }}</span>
                            <span *ngIf="
                                fail_delivery.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">{{ remarkMessage }}</span>

                            <!-- <span class="oder-status-note">
                                                        <p
                                                            *ngIf="selectedItemDetails?.reverseTrackingTimeline?.courierName">
                                                            Courier Name -
                                                            <b>{{selectedItemDetails?.reverseTrackingTimeline?.courierName}}</b>
                                                        </p>
                                                        <p
                                                            *ngIf="selectedItemDetails?.reverseTrackingTimeline?.trackingID">
                                                            Tracking number
                                                            <b>#{{selectedItemDetails?.reverseTrackingTimeline?.trackingID}}</b>
                                                        </p>
                                                    </span> -->
                            <span class="oder-status-note">
                              <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                                (click)="openUpdateModal('reverse')">See all updates
                              </a>
                            </span>
                            <!-- <span class="oder-status-note"
                                                        *ngIf="selectedItemDetails?.reverseTrackingTimeline.isPickupDelay && !fail_delivery.includes(selectedItemDetails?.latestStatus) && userSettings?.notifyDelayedDeliveryPickup">
                                                        <p class="note-status">
                                                            <span>Note : {{userSettings?.delayedPickupMessage}} </span>
                                                        </p>

                                                    </span> -->
                          </figure>
                        </span>
                        <span *ngIf="
                            reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note1 return-cancel">
                          <figure *ngIf="
                              selectedItemDetails.timeLine ===
                                'reverseTrackingTimeline' &&
                              reverse_all_status.includes(
                                selectedItemDetails.latestStatus
                              )
                            ">
                            <span class="cancelbutton">
                              <a [ngStyle]="getStyleWithoutOpacity()" class="layout" (click)="
                                  moveToReturnCancellation(
                                    selectedItemDetails.orderItemID,
                                    selectedItemDetails.latestStatus
                                  )
                                ">Cancel
                                <span [ngStyle]="getStyleWithOpacity()"></span>
                              </a>
                            </span>
                          </figure>
                        </span>
                      </div>

                      <!--
                                        <div (click)="handleClick()" *ngIf="status == 'approved' "
                                            [ngClass]="selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(status) ? 'small active':'small'"
                                            class="return-approved">
                                            <span class="small-status">
                                                <span class="i-status"
                                                *ngIf="selectedItemDetails.latestStatus == 'created'"> Now </span>
                                            <span class="i-status active"
                                                *ngIf="selectedItemDetails.latestStatus == 'approved'"> Now </span>
                                                <p>{{'In transit'}}</p>
                                            </span>
                                            <ng-container
                                                *ngIf="selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(status)">
                                                <span class="no-tooltip" *ngIf="reverseToolAndMessage(status)">{{
                                                    toolTipMessage }}</span>
                                            </ng-container>

                                        </div> -->

                      <div *ngIf="
                          status == 'picked_up' ||
                          status == 'intransit' ||
                          status == 'out_for_delivery' ||
                          status == 'shipment_delayed ' ||
                          status == 'shipment_held' ||
                          status == 'PICKED_FROM_CUSTOMER'||
                          status == 'IN_TRANSIT'
                        " [ngClass]="
                          selectedItemDetails.latestStatus == 'picked_up' ||
                          selectedItemDetails.latestStatus == 'intransit' ||
                          selectedItemDetails.latestStatus == 'out_for_delivery' ||
                          selectedItemDetails.latestStatus == 'shipment_delayed' ||
                          selectedItemDetails.latestStatus == 'shipment_held' ||
                          selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                          selectedItemDetails.latestStatus == 'IN_TRANSIT'

                            ? 'small active'
                            : 'small'
                        " class="picked-up">
                        <span class="small-status">
                          <span class="i-status" *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails.latestStatus
                              )
                            ">
                            DONE
                          </span>
                          <span class="i-status" *ngIf="
                              reverse_all_status.includes(
                                selectedItemDetails?.latestStatus
                              )
                            ">
                            NEXT
                          </span>

                          <span class="i-status active" *ngIf="
                              selectedItemDetails.latestStatus == 'picked_up' ||
                              selectedItemDetails.latestStatus == 'intransit' ||
                              selectedItemDetails.latestStatus ==
                                'out_for_delivery' ||
                                selectedItemDetails.latestStatus == 'shipment_delayed'||
                                selectedItemDetails.latestStatus == 'shipment_held'||
                                selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                                selectedItemDetails.latestStatus == 'IN_TRANSIT'
                            " style="background-color: #ECB73F;">
                            Now
                          </span>
                          <!-- {{ reverseStatusRename[status] | titlecase}} -->
                          <p [ngClass]="
                              selectedItemDetails.latestStatus == 'picked_up' ||
                              selectedItemDetails.latestStatus == 'intransit' ||
                              selectedItemDetails.latestStatus ==
                                'out_for_delivery' ||
                                selectedItemDetails.latestStatus == 'shipment_delayed'||
                                selectedItemDetails.latestStatus == 'shipment_held'||
                                selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                                selectedItemDetails.latestStatus == 'IN_TRANSIT'
                                ? 'small active'
                                : 'small'
                            ">
                            {{ "In transit" }}
                          </p>
                        </span>
                        <ng-container *ngIf="
                            selectedItemDetails.latestStatus == 'picked_up' ||
                            selectedItemDetails.latestStatus == 'intransit' ||
                            selectedItemDetails.latestStatus ==
                              'out_for_delivery' ||
                              selectedItemDetails.latestStatus == 'shipment_delayed'||
                              selectedItemDetails.latestStatus == 'shipment_held'||
                              selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                              selectedItemDetails.latestStatus == 'IN_TRANSIT'
                          ">
                          <span class="no-tooltip" *ngIf="
                              reverseToolAndMessage(
                                selectedItemDetails.latestStatus
                              ) && intStatus !== 'pickup_pending'
                            ">
                            On {{ toolTipMessage }}</span>
                        </ng-container>
                        <!-- <span class="i-status"
                                                *ngIf="selectedItemDetails.latestStatus == 'created' || selectedItemDetails.latestStatus == 'approved'">
                                                NEXT </span>
                                            <span class="i-status active"
                                                *ngIf="selectedItemDetails.latestStatus == 'picked_up'"> Now </span> -->
                        <!-- <span class="i-status"
                                                *ngIf="selectedItemDetails.latestStatus == 'delivered_warehouse' || selectedItemDetails.latestStatus == 'warehouse_delivered'">
                                                DONE </span>
                                            <span *ngIf="selectedItemDetails.latestStatus == 'picked_up'"
                                                class="oder-status-note" [innerHtml]="remarkMessage"></span> -->
                        <span *ngIf="
                            selectedItemDetails.latestStatus == 'picked_up' ||
                            selectedItemDetails.latestStatus == 'intransit' ||
                            selectedItemDetails.latestStatus ==
                              'out_for_delivery' ||
                              selectedItemDetails.latestStatus == 'shipment_delayed'||
                              selectedItemDetails.latestStatus == 'shipment_held'||
                              selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                              selectedItemDetails.latestStatus == 'IN_TRANSIT'
                          " class="oder-status-note1 first-boxxx">
                          <figure class="imageDisplay" *ngIf="!verifyOTP">
                            <ng-container *ngIf="
                                selectedItemDetails &&
                                selectedItemDetails.productImageUrl
                              ">
                              <img class="image" src="{{
                                  selectedItemDetails &&
                                  selectedItemDetails.productImageUrl
                                    ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                    : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                                onerror="this.style.display='none'" />
                            </ng-container>
                            <span class="item-name-price">
                              <span [ngClass]="
                                  selectedItemDetails.productName.length > 38
                                    ? 'overflow-lenght'
                                    : ''
                                ">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                                <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                                  ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                                </ng-container>

                              <span *ngIf="
                                  selectedItemDetails.productName.length > 38
                                " class="product-hover">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                              <p>
                                {{ rupeeSymbol }}
                                {{
                                selectedItemDetails?.invoiceTotal
                                ? transformDecimal(
                                selectedItemDetails?.invoiceTotal
                                )
                                : transformDecimal(selectedItemDetails?.lineItemTotal)
                                }}
                                <span *ngIf="selectedItemDetails.productSize">| Size
                                  {{
                                  selectedItemDetails
                                  ? selectedItemDetails.productSize
                                  : ""
                                  }}</span>
                              </p>
                            </span>
                          </figure>
                        </span>
                        <span *ngIf="
                            selectedItemDetails.latestStatus == 'picked_up' ||
                            selectedItemDetails.latestStatus == 'intransit' ||
                            selectedItemDetails.latestStatus ==
                              'out_for_delivery' ||
                              selectedItemDetails.latestStatus == 'shipment_delayed'||
                              selectedItemDetails.latestStatus == 'shipment_held'||
                              selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                              selectedItemDetails.latestStatus == 'IN_TRANSIT'
                          " class="oder-status-note1">
                          <figure>
                            <span class="center-alignment">
                              <span class="checkbox" *ngIf="
                                  selectedItemDetails.latestStatus ==
                                    'picked_up' ||
                                  selectedItemDetails.latestStatus ==
                                    'intransit' ||
                                  selectedItemDetails.latestStatus ==
                                    'out_for_delivery'||
                                    selectedItemDetails.latestStatus ==
                                    'shipment_delayed'||
                                    selectedItemDetails.latestStatus == 'shipment_held'||
                                    selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                                    selectedItemDetails.latestStatus == 'IN_TRANSIT'
                                ">
                                <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                              </span>
                              <span *ngIf="
                                  selectedItemDetails.latestStatus ==
                                    'picked_up' ||
                                  selectedItemDetails.latestStatus ==
                                    'intransit' ||
                                  selectedItemDetails.latestStatus ==
                                    'out_for_delivery' ||
                                    selectedItemDetails.latestStatus ==
                                    'shipment_delayed'||
                                    selectedItemDetails.latestStatus == 'shipment_held'||
                                    selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                                    selectedItemDetails.latestStatus == 'IN_TRANSIT'
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold; font-size:13px;">Picked
                                up</span>
                            </span>
                            <span *ngIf="
                                selectedItemDetails.latestStatus ==
                                  'picked_up' ||
                                selectedItemDetails.latestStatus ==
                                  'intransit' ||
                                selectedItemDetails.latestStatus ==
                                  'out_for_delivery' ||
                                  selectedItemDetails.latestStatus ==
                                  'shipment_delayed'||
                                  selectedItemDetails.latestStatus == 'shipment_held'||
                                  selectedItemDetails.latestStatus == 'PICKED_FROM_CUSTOMER' ||
                                  selectedItemDetails.latestStatus == 'IN_TRANSIT'
                              " class="oder-status-note">Your item has been successfully picked up by our
                              courier partner.</span>
                            <!-- <span class="oder-status-note">
                                                        <p
                                                            *ngIf="selectedItemDetails?.reverseTrackingTimeline?.courierName">
                                                            Courier Name -
                                                            <b>{{selectedItemDetails?.reverseTrackingTimeline?.courierName}}</b>
                                                        </p>
                                                        <p
                                                            *ngIf="selectedItemDetails?.reverseTrackingTimeline?.trackingID">
                                                            Tracking number
                                                            <b>#{{selectedItemDetails?.reverseTrackingTimeline?.trackingID}}</b>
                                                        </p>
                                                    </span> -->
                            <span class="oder-status-note">
                              <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                                (click)="openUpdateModal('reverse')">See all updates
                              </a>
                            </span>
                          </figure>
                        </span>
                      </div>

                      <div *ngIf="warehouse_delivered.includes(status)" [ngClass]="
                          ((status == 'delivered_warehouse' ||
                            status == 'warehouse_delivered' ||
                            status == 'delivered') &&
                          selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(
                            status
                          ) &&
                          !selectedItemDetails.qc
                            ? 'refund-bad'
                            : 'refund-succ') +
                          ' ' +
                          (selectedItemDetails.latestStatus ==
                            'delivered_warehouse' ||
                          selectedItemDetails.latestStatus ==
                            'warehouse_delivered' ||
                          selectedItemDetails.latestStatus == 'delivered'
                            ? 'active'
                            : '')
                        ">
                        <span class="i-status" *ngIf="
                            !(
                              selectedItemDetails.latestStatus ==
                                'delivered_warehouse' ||
                              selectedItemDetails.latestStatus ==
                                'warehouse_delivered' ||
                              selectedItemDetails.latestStatus == 'delivered'
                            )
                          ">
                          NEXT
                        </span>
                        <span class="i-status active" style="background-color:  #6FCF97;" *ngIf="
                            selectedItemDetails.latestStatus ==
                              'delivered_warehouse' ||
                            selectedItemDetails.latestStatus ==
                              'warehouse_delivered' ||
                            selectedItemDetails.latestStatus == 'delivered'
                          ">
                          Now
                        </span>
                        <p [ngClass]="
                            ((status == 'delivered_warehouse' ||
                              status == 'warehouse_delivered' ||
                              status == 'delivered') &&
                            selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(
                              status
                            ) &&
                            !selectedItemDetails.qc
                              ? 'refund-bad'
                              : 'refund-succ') +
                            ' ' +
                            (selectedItemDetails.latestStatus ==
                              'delivered_warehouse' ||
                            selectedItemDetails.latestStatus ==
                              'warehouse_delivered' ||
                            selectedItemDetails.latestStatus == 'delivered'
                              ? 'active'
                              : '')
                          ">
                          {{
                          selectedItemDetails.returnType == returnTypeExchange
                          ? "Exchange"
                          : selectedItemDetails.returnType ==
                          returnTypeReplace
                          ? "Exchange"
                          : reverseStatusRename[status]
                          }}
                        </p>
                        <ng-container *ngIf="
                            warehouse_delivered.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          <span class="no-tooltip" *ngIf="
                              reverseToolAndMessage(
                                selectedItemDetails.latestStatus
                              )
                            ">
                            On {{ toolTipMessage }}</span>
                        </ng-container>
                        <br />
                      </div>
                    </ng-container>
                  </div>

                  <!---------------------------------------------------Refund Desktop ---------------------->

                  <div class="refundAlign" *ngIf="
                      warehouse_delivered.includes(
                        selectedItemDetails.latestStatus
                      )
                    ">
                    <span class="oder-status-note1 first-boxxx">
                      <figure class="imageDisplay" *ngIf="!verifyOTP">
                        <ng-container *ngIf="
                            selectedItemDetails &&
                            selectedItemDetails.productImageUrl
                          ">
                          <img class="image" src="{{
                              selectedItemDetails &&
                              selectedItemDetails.productImageUrl
                                ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                                : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                            }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                            onerror="this.style.display='none'" />
                        </ng-container>
                        <span class="item-name-price">
                          <span [ngClass]="
                              selectedItemDetails.productName.length > 38
                                ? 'overflow-lenght'
                                : ''
                            ">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                            <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                              ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                            </ng-container>

                          <span *ngIf="selectedItemDetails.productName.length > 38" class="product-hover">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                          <p>
                            {{ rupeeSymbol }}
                            {{
                            selectedItemDetails?.invoiceTotal
                            ? transformDecimal(
                            selectedItemDetails?.invoiceTotal
                            )
                            : transformDecimal(selectedItemDetails?.lineItemTotal)
                            }}
                            <span *ngIf="selectedItemDetails.productSize">| Size
                              {{
                              selectedItemDetails
                              ? selectedItemDetails.productSize
                              : ""
                              }}</span>
                          </p>
                        </span>
                      </figure>
                    </span>
                    <span class="oder-status-note1  ">
                      <figure>
                        <span class="center-alignment">
                          <span class="checkbox" *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              ) &&
                              (selectedItemDetails.returnType == 'refund' &&
                                selectedItemDetails.qc == 0)
                            ">
                            <img src="assets/images/cancel-icon.svg" />
                          </span>
                          <span class="checkbox" *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              ) &&
                              (selectedItemDetails.returnType == 'replace' ||
                                selectedItemDetails.returnType == 'exchange') &&
                              selectedItemDetails.qc == 0
                            ">
                            <img src="assets/images/cancel-icon.svg" />
                          </span>

                          <span class="checkbox" *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              ) &&
                              (selectedItemDetails.returnType == 'replace' ||
                                selectedItemDetails.returnType == 'exchange') &&
                              selectedItemDetails.qc == 1
                            ">
                            <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                          </span>

                          <span *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              ) &&
                              (selectedItemDetails.returnType == 'refund' &&
                                selectedItemDetails.qc == 0)
                            " style="padding-left: 6px; color: red;font-weight: bold; font-size:13px;">Refund Failed
                          </span>

                          <span *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              ) &&
                              (selectedItemDetails.returnType == 'replace' ||
                                selectedItemDetails.returnType == 'exchange') &&
                              selectedItemDetails.qc == 1
                            " style="padding-left: 6px; color: #5DB075;font-weight: bold; font-size:13px;">Approved
                          </span>

                          <span *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              ) &&
                              (selectedItemDetails.returnType == 'replace' ||
                                selectedItemDetails.returnType == 'exchange') &&
                              selectedItemDetails.qc == 0
                            " style="padding-left: 6px; color: red;font-weight: bold; font-size:13px;">Exchange Failed
                          </span>
                        </span>

                        <span *ngIf="
                            warehouse_delivered.includes(
                              selectedItemDetails?.latestStatus
                            ) && selectedItemDetails.qc == 0
                          " class="oder-status-note">Your returned item has failed our quality check,
                          therefore we are unable to process the
                          {{
                          selectedItemDetails.returnType == "refund"
                          ? "refund"
                          : "exchange"
                          }}. Please contact
                          <a class="support-email" href="mailto:{{
                              userSettings && userSettings?.supportEmail
                                ? userSettings?.supportEmail
                                : ''
                            }}">{{ userSettings?.supportEmail }}</a>
                          for further details</span>

                        <span *ngIf="
                            selectedItemDetails.qc &&
                            (selectedItemDetails.returnType == 'replace' ||
                              selectedItemDetails.returnType == 'exchange') &&
                            warehouse_delivered.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note">
                          We have successfully received the returned item and
                          your
                          {{
                          selectedItemDetails.returnType == "refund"
                          ? "refund"
                          : "exchange"
                          }}
                          request has been approved.

                          <a href="/track/{{
                              orderData.customerOrderNumber
                            }} / {{ selectedItemDetails.newOrderItemID }} ">Click here</a>
                          to track your new item
                        </span>
                        <!-- <span class="oder-status-note">
                                                <p *ngIf="selectedItemDetails?.reverseTrackingTimeline?.courierName">
                                                    Courier Name -
                                                    <b>{{selectedItemDetails?.reverseTrackingTimeline?.courierName}}</b>
                                                </p>
                                                <p *ngIf="selectedItemDetails?.reverseTrackingTimeline?.trackingID">
                                                    Tracking number
                                                    <b>#{{selectedItemDetails?.reverseTrackingTimeline?.trackingID}}</b>
                                                </p>
                                            </span> -->
                        <span class="oder-status-note">
                          <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                            (click)="openUpdateModal('reverse')">See all updates
                          </a>
                        </span>
                      </figure>
                    </span>
                  </div>
                </ng-template>
              </ng-container>
              <!--End Here-->

              <!--Return Tracking Timeline Start Here-->
              <ng-container *ngIf="selectedItemDetails.timeLine == 'returnTrackingTimeline'">
                <ng-container *ngIf="
                    returnCancelledTimelineStatus.includes(
                      selectedItemDetails.latestStatus
                    );
                    else reverseTimelineCond
                  ">
                  <div class="order-process step-two cancelled">
                    <ng-container *ngFor="
                        let returnCancel of selectedItemDetails
                          .reverseTrackingTimeline.statusLog;
                        let i = index
                      ">
                      <div *ngIf="returnCancel.status == 'created'" [ngClass]="
                          !['cancelled_order', 'return_cancelled'].includes(
                            selectedItemDetails?.latestStatus
                          )
                            ? 'active'
                            : 'not-active'
                        " class="return-initiated">
                        <span class="i-status"> DONE </span>
                        <!-- <p>  Return Initiated 1</p> -->
                        <p>Shipping soon</p>
                        <!-- <span *ngIf="returnCancel.status == 'created'"
                            class="no-tooltip">
                            {{ returnCancel.updatedAt ? replaceDate(returnCancel.updatedAt) :'' }}.
                        </span> -->
                        <!-- <span class="i-status"> DONE </span> -->
                      </div>

                      <div class="small-status custom-transit" *ngIf="i == 0">
                        <span class="i-status"> DONE </span>
                        <p class="small">In transit</p>
                      </div>

                      <div *ngIf="
                          returnCancel.status ==
                          selectedItemDetails.latestStatus
                        " class="cancelled-i active">
                        <span class="i-status active" style="background-color: #6FCF97;">
                          Now
                        </span>
                        <ng-container *ngIf="
                            returnCancel.remark.includes('QC fail') &&
                              (selectedItemDetails.latestStatus ==
                                'pickup_cancelled' ||
                                selectedItemDetails.latestStatus ==
                                  'cancelled_order');
                            else delivered
                          ">
                          <p style="color:#6FCF97;">
                            {{
                            selectedItemDetails?.returnType == "return"
                            ? "Return"
                            : "Return"
                            }}
                          </p>

                          <span *ngIf="
                              returnCancel.status ==
                              selectedItemDetails.latestStatus
                            " class="no-tooltip">
                            <!-- Cancelled on -->
                            <!-- On {{ selectedItemDetails?.expectedDeliveryDate ?
                        replaceDate(selectedItemDetails.expectedDeliveryDate) :'' }}. -->
                            On
                            {{
                            returnCancel.updatedAt
                            ? replaceDate(returnCancel.updatedAt)
                            : ""
                            }}.
                          </span>
                        </ng-container>

                        <ng-template #delivered>
                          <p style="color:#6FCF97;">Delivered</p>

                          <span class="no-tooltip">
                            <!-- Cancelled on -->
                            <!-- On {{ selectedItemDetails?.expectedDeliveryDate ?
                            replaceDate(selectedItemDetails.expectedDeliveryDate) :'' }}. -->
                            On {{ deliveredDate ? (deliveredDate | date: 'fullDate' ) : "" }}
                          </span>
                        </ng-template>
                        <!-- <span class="i-status active"> Now </span> -->
                        <!-- <span class="oder-status-note"> Your return has been cancelled successfully.
                        </span> -->
                      </div>
                    </ng-container>
                  </div>
                  <div class="refundAlign">
                    <span class="oder-status-note1 first-boxxx">
                      <figure class="imageDisplay" *ngIf="!verifyOTP">
                        <ng-container *ngIf="
                            selectedItemDetails &&
                            selectedItemDetails.productImageUrl
                          ">
                          <img class="image" src="{{
                              selectedItemDetails &&
                              selectedItemDetails.productImageUrl
                                ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                                : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                            }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                            onerror="this.style.display='none'" />
                        </ng-container>
                        <span class="item-name-price">
                          <span [ngClass]="
                              selectedItemDetails.productName.length > 38
                                ? 'overflow-lenght'
                                : ''
                            ">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                            <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                              ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                            </ng-container>

                          <span *ngIf="selectedItemDetails.productName.length > 38" class="product-hover">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                          <p>
                            {{ rupeeSymbol }}
                            {{
                            selectedItemDetails?.invoiceTotal
                            ? transformDecimal(
                            selectedItemDetails?.invoiceTotal
                            )
                            : transformDecimal(selectedItemDetails?.lineItemTotal)
                            }}
                            <span *ngIf="selectedItemDetails.productSize">| Size
                              {{
                              selectedItemDetails
                              ? selectedItemDetails.productSize
                              : ""
                              }}</span>
                          </p>
                        </span>
                      </figure>
                    </span>
                    <ng-container *ngFor="
                        let returnCancel of selectedItemDetails
                          .reverseTrackingTimeline.statusLog
                      ">
                      <span *ngIf="
                          returnCancel.status ==
                          selectedItemDetails.latestStatus
                        " class="oder-status-note1">
                        <figure>
                          <span class="center-alignment">
                            <span class="checkbox" *ngIf="
                                returnCancel.status ==
                                selectedItemDetails.latestStatus
                              ">
                              <img src="assets/images/cancel-icon.svg" />
                            </span>

                            <ng-container *ngIf="
                                returnCancel.remark.includes('QC fail') &&
                                (selectedItemDetails.latestStatus ==
                                  'pickup_cancelled' ||
                                  selectedItemDetails.latestStatus ==
                                    'cancelled_order') &&
                                (selectedItemDetails.returnType == 'exchange' ||
                                  selectedItemDetails.returnType == 'replace')
                              ">
                              <span *ngIf="
                                  returnCancel.status ==
                                  selectedItemDetails.latestStatus
                                " style="padding-left: 6px; color: red;font-weight: bold; font-size:13px;">Exchange
                                Failed</span>
                            </ng-container>
                            <ng-container *ngIf="
                                returnCancel.remark.includes('QC fail') &&
                                (selectedItemDetails.latestStatus ==
                                  'pickup_cancelled' ||
                                  selectedItemDetails.latestStatus ==
                                    'cancelled_order') &&
                                selectedItemDetails.returnType == 'refund'
                              ">
                              <span *ngIf="
                                  returnCancel.status ==
                                  selectedItemDetails.latestStatus
                                " style="padding-left: 6px; color: red;font-weight: bold; font-size:13px;">Refund
                                Failed</span>
                            </ng-container>

                            <ng-container *ngIf="
                                !(
                                  returnCancel.remark.includes('QC fail') &&
                                  (selectedItemDetails.latestStatus ==
                                    'pickup_cancelled' ||
                                    selectedItemDetails.latestStatus ==
                                      'cancelled_order')
                                )
                              ">
                              <span *ngIf="
                                  returnCancel.status ==
                                  selectedItemDetails.latestStatus
                                " style="padding-left: 6px; color: red;font-weight: bold;">Return Cancelled</span>
                            </ng-container>
                          </span>

                          <ng-container>
                            <span *ngIf="
                                selectedItemDetails.latestStatus ==
                                  'return_cancelled' ||
                                (returnCancelledTimelineStatus.includes(
                                  'return_cancelled'
                                ) &&
                                  returnCancel.status == 'return_cancelled')
                              " class="oder-status-note">As per your request, the return for this item has
                              been cancelled. Please do not accept, if pickup is
                              attempted.</span>

                            <span *ngIf="
                                (selectedItemDetails.latestStatus ==
                                  'pickup_cancelled' ||
                                  selectedItemDetails.latestStatus ==
                                    'cancelled_order' ||
                                  (returnCancel.status == 'cancelled_order' ||
                                    returnCancel.status ==
                                      'pickup_cancelled')) &&
                                !returnCancel.remark.includes('QC fail')
                              " class="oder-status-note">We regret to inform you that your pickup has been
                              cancelled due to multiple failed attempts.</span>
                            <span *ngIf="
                                ((selectedItemDetails.latestStatus ==
                                  'cancelled_order' ||
                                  selectedItemDetails.latestStatus ==
                                    'pickup_cancelled') &&
                                  returnCancel.remark.includes('QC fail')) ||
                                (selectedItemDetails.latestStatus ==
                                  'warehouse_delivered' ||
                                  selectedItemDetails.latestStatus ==
                                    'delivered_warehouse')
                              " class="oder-status-note">Your returned item has failed our quality check,
                              therefore we are unable to process the
                              {{
                              selectedItemDetails?.returnType == "refund"
                              ? "refund"
                              : "exchange"
                              }}. Please contact
                              <a class="support-email" href="mailto:{{
                                  userSettings && userSettings?.supportEmail
                                    ? userSettings?.supportEmail
                                    : ''
                                }}">{{ userSettings?.supportEmail }}</a>
                              for further details</span>
                          </ng-container>

                          <!-- <span class="oder-status-note">
                                                    <p
                                                        *ngIf="selectedItemDetails?.reverseTrackingTimeline?.courierName">
                                                        Courier Name -
                                                        <b>{{selectedItemDetails?.reverseTrackingTimeline?.courierName}}</b>
                                                    </p>
                                                    <p *ngIf="selectedItemDetails?.reverseTrackingTimeline?.trackingID">
                                                        Tracking number
                                                        <b>#{{selectedItemDetails?.reverseTrackingTimeline?.trackingID}}</b>
                                                    </p>
                                                </span> -->
                          <span class="oder-status-note">
                            <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                              (click)="openUpdateModal('cancel')">See all updates
                            </a>
                          </span>
                        </figure>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="
                        selectedItemDetails?.returnPolicy?.isReturnable ===
                          true && userSettings?.allowReturns
                      ">
                      <span class="oder-status-note1 return-cancel">
                        <figure class="delivered-btn">
                          <span class="cancelbutton">
                            <a [ngStyle]="getStyleWithoutOpacity()" class="layout" (click)="
                                moveToReturnProcess(
                                  selectedItemDetails.orderItemID
                                )
                              ">Return Item
                              <span [ngStyle]="getStyleWithOpacity()"></span>
                            </a>
                          </span>
                          <div class="icon-tooltip">
                            <a *ngIf="
                                returnCancelledTimelineStatus.includes(
                                  selectedItemDetails.latestStatus
                                ) &&
                                getWindowReturnDate() &&
                                (selectedItemDetails?.returnPolicy
                                  ?.isReturnable === true &&
                                  userSettings?.allowReturns)
                              ">
                              <img src="https://ik.imagekit.io/2gwij97w0o/POE/info.svg" />
                              <span>
                                Exchange/Return window will be closed on
                                {{ windowDate ? windowDate : "" }}
                              </span>
                            </a>
                          </div>
                        </figure>
                      </span>
                    </ng-container>

                    <!-- <span class="oder-status-note1"
                                    *ngIf="returnCancelledTimelineStatus.includes(selectedItemDetails.latestStatus) &&  getWindowReturnDate() && (selectedItemDetails?.returnPolicy?.isReturnable === true && userSettings?.allowReturns)">
                                    <p class="note-status note-status-exclusive">
                                        <img src="https://ik.imagekit.io/2gwij97w0o/POE/info.svg"> <span> 33 Exchange/Return window
                                            will be closed on {{windowDate?windowDate:''}} </span>
                                    </p>
                                </span> -->

                    <span class="oder-status-note1" *ngIf="
                        returnCancelledTimelineStatus.includes(
                          selectedItemDetails.latestStatus
                        ) &&
                        !getWindowReturnDate() &&
                        (selectedItemDetails?.returnPolicy?.isReturnable ===
                          true &&
                          userSettings?.allowReturns)
                      ">
                      <p class="note-status note-status-exclusive">
                        <img src="https://ik.imagekit.io/2gwij97w0o/POE/info.svg" /><span>
                          Exchange/Return window closed on
                          {{ windowDate ? windowDate : "" }}
                        </span>
                      </p>
                    </span>
                  </div>
                </ng-container>
                <ng-template #reverseTimelineCond>
                  <ng-container *ngIf="
                      approved.includes(selectedItemDetails?.latestStatus) ||
                      pickup_pending.includes(selectedItemDetails?.latestStatus)
                    ">
                    <div class="banner-title">
                      <img src="../../assets/images/delivery-vehicle.svg" alt="" />
                      <span>
                        Expected pickup by
                        {{
                        selectedItemDetails?.reverseTrackingTimeline
                        .expectedPickupDate
                        ? replaceDate(
                        selectedItemDetails?.reverseTrackingTimeline
                        .expectedPickupDate
                        )
                        : ""
                        }}
                      </span>
                      <div class="icon-tooltip" *ngIf="
                          selectedItemDetails?.reverseTrackingTimeline
                            .isPickupDelay &&
                          !fail_delivery.includes(
                            selectedItemDetails?.latestStatus
                          ) &&
                          userSettings?.notifyDelayedDeliveryPickup
                        ">
                        <a>
                          <img src="../../../../assets/images/info-green.svg" />
                          <span class="delivery-deley">{{
                            userSettings?.delayedPickupMessage
                            }}</span>
                        </a>
                      </div>
                    </div>
                  </ng-container>

                  <div [ngClass]="
                      selectedItemDetails.reverseTimelineStatusLog[0]
                        .statusLength > 1
                        ? 'order-process step-two step-four ' +
                          statusActiveClass[
                            selectedItemDetails.reverseTimelineStatusLog[0]
                              .statusLength
                          ]
                        : 'order-process refund step-two step-four'
                    ">
                    <ng-container *ngFor="let status of reverseStatusArray">
                      <div (click)="handleClick()" *ngIf="status == 'created'" [ngClass]="
                          reverse_all_status.includes(
                            selectedItemDetails?.latestStatus
                          )
                            ? 'active'
                            : 'not-active'
                        " class="return-initiated">
                        <span class="i-status active" *ngIf="
                            reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          Now
                        </span>
                        <span class="i-status" *ngIf="
                            !reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          DONE
                        </span>
                        <!-- {{ reverseStatusRename[status] | titlecase}} -->
                        <p>{{ " Pickup Soon " }}</p>
                        <ng-container *ngIf="
                            fail_delivery.includes(
                              selectedItemDetails?.latestStatus
                            ) ||
                            pickup.includes(
                              selectedItemDetails?.latestStatus
                            ) ||
                            requested.includes(
                              selectedItemDetails?.latestStatus
                            ) ||
                            approved.includes(selectedItemDetails?.latestStatus)
                          ">
                          <span class="no-tooltip" *ngIf="
                              reverseToolAndMessage(
                                selectedItemDetails.latestStatus
                              )
                            ">On {{ toolTipMessage }}</span>
                        </ng-container>
                        <ng-container *ngIf="
                            requested.includes(
                              selectedItemDetails?.latestStatus
                            ) ||
                              approved.includes(
                                selectedItemDetails?.latestStatus
                              ) ||
                              pickup_pending.includes(
                                selectedItemDetails?.latestStatus
                              );
                            else outForPickup
                          ">
                          <span class="no-tooltip" *ngIf="
                              reverseToolAndMessage(
                                selectedItemDetails.latestStatus
                              )
                            ">
                            <!-- Pickup
                                                    by {{
                                                    selectedItemDetails?.reverseTrackingTimeline.revisedExpectedPickupDate
                                                    ?
                                                    replaceDate(selectedItemDetails?.reverseTrackingTimeline.revisedExpectedPickupDate)
                                                    :'' }} -->
                          </span>
                        </ng-container>
                        <!-- <ng-container *ngIf="pickup.includes(selectedItemDetails?.latestStatus);">

                            <span class="no-tooltip"
                                *ngIf="reverseToolAndMessage(selectedItemDetails.latestStatus)  ">Pickup by
                                {{ toolTipMessage }}</span>

                        </ng-container> -->
                        <!-- <span *ngIf="selectedItemDetails.latestStatus == 'created'"
                            class="oder-status-note" [innerHtml]="remarkMessage"></span> -->

                        <span *ngIf="
                            reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note1 first-boxxx">
                          <figure class="imageDisplay" *ngIf="!verifyOTP">
                            <ng-container *ngIf="
                                selectedItemDetails &&
                                selectedItemDetails.productImageUrl
                              ">
                              <img class="image" src="{{
                                  selectedItemDetails &&
                                  selectedItemDetails.productImageUrl
                                    ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                    : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                                onerror="this.style.display='none'" />
                            </ng-container>
                            <span class="item-name-price">
                              <span [ngClass]="
                                  selectedItemDetails.productName.length > 38
                                    ? 'overflow-lenght'
                                    : ''
                                ">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                                <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                                  ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                                </ng-container>

                              <span *ngIf="
                                  selectedItemDetails.productName.length > 38
                                " class="product-hover">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                              <p>
                                {{ rupeeSymbol }}
                                {{
                                selectedItemDetails?.invoiceTotal
                                ? transformDecimal(
                                selectedItemDetails?.invoiceTotal
                                )
                                : transformDecimal(selectedItemDetails?.lineItemTotal)
                                }}
                                <span *ngIf="selectedItemDetails.productSize">| Size
                                  {{
                                  selectedItemDetails
                                  ? selectedItemDetails.productSize
                                  : ""
                                  }}</span>
                              </p>
                            </span>
                          </figure>
                        </span>
                        <span *ngIf="
                            reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note1">
                          <figure>
                            <span class="center-alignment">
                              <span class="checkbox" *ngIf="
                                  reverse_all_status.includes(
                                    selectedItemDetails?.latestStatus
                                  ) &&
                                  !fail_delivery.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                ">
                                <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                              </span>
                              <span class="checkbox" *ngIf="
                                  fail_delivery.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                ">
                                <img src="assets/images/cancel-icon.svg" />
                              </span>
                              <span *ngIf="
                                  requested.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold; font-size:13px;">Return
                                Requested</span>
                              <span *ngIf="
                                  approved.includes(
                                    selectedItemDetails?.latestStatus
                                  ) ||
                                  pickup_pending.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold;font-size:13px;">Request
                                Approved</span>

                              <span *ngIf="
                                  pickup.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold;font-size:13px;">Out For
                                Pickup</span>
                              <span *ngIf="
                                  fail_delivery.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: red;font-weight: bold;font-size:13px;">Pickup Failed
                              </span>
                              <!-- <span *ngIf="pickup_pending.includes(selectedItemDetails?.latestStatus)"
                                        style="padding-left: 10px; color: #5DB075;font-weight: bold; font-size:13px;">Out for pickup
                                    </span> -->
                            </span>

                            <span *ngIf="
                                requested.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">{{
                              "Your request to return this item has been raised successfully. You will be notified as
                              soon as it is approved."
                              }}</span>
                            <span *ngIf="
                                order_placed.includes(
                                  selectedItemDetails?.latestStatus
                                ) ||
                                approved.includes(
                                  selectedItemDetails?.latestStatus
                                ) ||
                                pickup_pending.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">{{
                              "Your request to return this item has been approved. You will be notified as the courier
                              partner is out for pickup"
                              }}</span>
                            <span *ngIf="
                                pickup.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">
                              <!-- {{'Your item is out for pickup by our courier partner.'}} -->
                              {{
                              "Our courier partner is arriving today for the return pickup."
                              }}
                              <!-- {{'Your item has been successfully picked up by our courier partner.'}} -->
                            </span>
                            <span *ngIf="
                                fail_delivery.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">{{
                              "We were unable to pickup your item due to failed attempt. You will be notified as soon as
                              our courier partner will re-attempt the pickup."
                              }}</span>

                            <!-- <span class="oder-status-note">
                                                        <p
                                                            *ngIf="selectedItemDetails?.reverseTrackingTimeline?.courierName">
                                                            Courier Name -
                                                            <b>{{selectedItemDetails?.reverseTrackingTimeline?.courierName}}</b>
                                                        </p>
                                                        <p
                                                            *ngIf="selectedItemDetails?.reverseTrackingTimeline?.trackingID">
                                                            Tracking number
                                                            <b>#{{selectedItemDetails?.reverseTrackingTimeline?.trackingID}}</b>
                                                        </p>
                                                    </span> -->
                            <span class="oder-status-note">
                              <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                                (click)="openUpdateModal('reverse')">See all updates
                              </a>
                            </span>
                            <!-- <span class="oder-status-note"
                                                        *ngIf="selectedItemDetails?.reverseTrackingTimeline.isPickupDelay && !fail_delivery.includes(selectedItemDetails?.latestStatus) && userSettings?.notifyDelayedDeliveryPickup">
                                                        <p class="note-status">
                                                            <span>Note : {{userSettings?.delayedPickupMessage}} </span>
                                                        </p>

                                                    </span> -->
                          </figure>
                        </span>
                        <span *ngIf="
                            reverse_all_status.includes(
                              selectedItemDetails?.latestStatus
                            ) &&
                            selectedItemDetails.timeLine ===
                              'returnTrackingTimeline'
                          " class="oder-status-note1 return-cancel">
                          <figure>
                            <span class="cancelbutton">
                              <a [ngStyle]="getStyleWithoutOpacity()" class="layout" (click)="
                                  moveToReturnCancellation(
                                    selectedItemDetails.orderItemID,
                                    selectedItemDetails.latestStatus
                                  )
                                ">Cancel
                                <span [ngStyle]="getStyleWithOpacity()"></span></a>
                            </span>
                          </figure>
                        </span>
                      </div>

                      <!--
                    <div (click)="handleClick()" *ngIf="status == 'approved' "
                        [ngClass]="selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(status) ? 'small active':'small'"
                        class="return-approved">
                        <span class="small-status">
                            <span class="i-status"
                            *ngIf="selectedItemDetails.latestStatus == 'created'"> Now </span>
                        <span class="i-status active"
                            *ngIf="selectedItemDetails.latestStatus == 'approved'"> Now </span>
                            <p>{{'In transit'}}</p>
                        </span>
                        <ng-container
                            *ngIf="selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(status)">
                            <span class="no-tooltip" *ngIf="reverseToolAndMessage(status)">{{
                                toolTipMessage }}</span>
                        </ng-container>

                    </div> -->

                      <div *ngIf="
                          status == 'picked_up' ||
                          status == 'intransit' ||
                          status == 'out_for_delivery'
                        " [ngClass]="
                          selectedItemDetails.latestStatus == 'picked_up' ||
                          selectedItemDetails.latestStatus == 'intransit' ||
                          selectedItemDetails.latestStatus == 'out_for_delivery'
                            ? 'small active'
                            : 'small'
                        " class="picked-up">
                        <span class="small-status">
                          <span class="i-status" *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              )
                            ">
                            DONE
                          </span>
                          <span class="i-status" *ngIf="
                              reverse_all_status.includes(
                                selectedItemDetails?.latestStatus
                              )
                            ">
                            NEXT
                          </span>

                          <span class="i-status active" *ngIf="
                              selectedItemDetails.latestStatus == 'picked_up' ||
                              selectedItemDetails.latestStatus == 'intransit' ||
                              selectedItemDetails.latestStatus ==
                                'out_for_delivery'
                            " style="background-color: #ECB73F;">
                            Now
                          </span>
                          <!-- {{ reverseStatusRename[status] | titlecase}} -->
                          <p [ngClass]="
                              selectedItemDetails.latestStatus == 'picked_up' ||
                              selectedItemDetails.latestStatus ==
                                'out_for_delivery' ||
                              selectedItemDetails.latestStatus == 'intransit'
                                ? 'small active'
                                : 'small'
                            ">
                            {{ "In transit" }}
                          </p>
                        </span>
                        <ng-container *ngIf="
                            selectedItemDetails.latestStatus == 'picked_up' ||
                            selectedItemDetails.latestStatus == 'intransit' ||
                            selectedItemDetails.latestStatus ==
                              'out_for_delivery'
                          ">
                          <span class="no-tooltip" *ngIf="
                              reverseToolAndMessage(
                                selectedItemDetails.latestStatus
                              ) && intStatus !== 'pickup_pending'
                            ">
                            On {{ toolTipMessage }}</span>
                        </ng-container>
                        <!-- <span class="i-status"
                            *ngIf="selectedItemDetails.latestStatus == 'created' || selectedItemDetails.latestStatus == 'approved'">
                            NEXT </span>
                        <span class="i-status active"
                            *ngIf="selectedItemDetails.latestStatus == 'picked_up'"> Now </span> -->
                        <!-- <span class="i-status"
                            *ngIf="selectedItemDetails.latestStatus == 'delivered_warehouse' || selectedItemDetails.latestStatus == 'warehouse_delivered'">
                            DONE </span>
                        <span *ngIf="selectedItemDetails.latestStatus == 'picked_up'"
                            class="oder-status-note" [innerHtml]="remarkMessage"></span> -->
                        <span *ngIf="
                            selectedItemDetails.latestStatus == 'picked_up' ||
                            selectedItemDetails.latestStatus == 'intransit' ||
                            selectedItemDetails.latestStatus ==
                              'out_for_delivery'
                          " class="oder-status-note1 first-boxxx">
                          <figure class="imageDisplay" *ngIf="!verifyOTP">
                            <ng-container *ngIf="
                                selectedItemDetails &&
                                selectedItemDetails.productImageUrl
                              ">
                              <img class="image" src="{{
                                  selectedItemDetails &&
                                  selectedItemDetails.productImageUrl
                                    ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                    : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                                onerror="this.style.display='none'" />
                            </ng-container>
                            <span class="item-name-price">
                              <span [ngClass]="
                                  selectedItemDetails.productName.length > 38
                                    ? 'overflow-lenght'
                                    : ''
                                ">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                                <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                                  ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                                </ng-container>

                              <span *ngIf="
                                  selectedItemDetails.productName.length > 38
                                " class="product-hover">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                              <p>
                                {{ rupeeSymbol }}
                                {{
                                selectedItemDetails?.invoiceTotal
                                ? transformDecimal(
                                selectedItemDetails?.invoiceTotal
                                )
                                : transformDecimal(selectedItemDetails?.lineItemTotal)
                                }}
                                <span *ngIf="selectedItemDetails.productSize">| Size
                                  {{
                                  selectedItemDetails
                                  ? selectedItemDetails.productSize
                                  : ""
                                  }}</span>
                              </p>
                            </span>
                          </figure>
                        </span>
                        <span *ngIf="
                            selectedItemDetails.latestStatus == 'picked_up' ||
                            selectedItemDetails.latestStatus == 'intransit' ||
                            selectedItemDetails.latestStatus ==
                              'out_for_delivery'
                          " class="oder-status-note1">
                          <figure>
                            <span class="center-alignment">
                              <span class="checkbox" *ngIf="
                                  selectedItemDetails.latestStatus ==
                                    'picked_up' ||
                                  selectedItemDetails.latestStatus ==
                                    'intransit' ||
                                  selectedItemDetails.latestStatus ==
                                    'out_for_delivery'
                                ">
                                <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                              </span>
                              <span *ngIf="
                                  selectedItemDetails.latestStatus ==
                                    'picked_up' ||
                                  selectedItemDetails.latestStatus ==
                                    'intransit' ||
                                  selectedItemDetails.latestStatus ==
                                    'out_for_delivery'
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold; font-size:13px;">Picked
                                up</span>
                            </span>
                            <span *ngIf="
                                selectedItemDetails.latestStatus ==
                                  'picked_up' ||
                                selectedItemDetails.latestStatus ==
                                  'intransit' ||
                                selectedItemDetails.latestStatus ==
                                  'out_for_delivery'
                              " class="oder-status-note">Your item has been successfully picked up by our
                              courier partner.</span>
                            <!-- <span class="oder-status-note">
                                                        <p
                                                            *ngIf="selectedItemDetails?.reverseTrackingTimeline?.courierName">
                                                            Courier Name -
                                                            <b>{{selectedItemDetails?.reverseTrackingTimeline?.courierName}}</b>
                                                        </p>
                                                        <p
                                                            *ngIf="selectedItemDetails?.reverseTrackingTimeline?.trackingID">
                                                            Tracking number
                                                            <b>#{{selectedItemDetails?.reverseTrackingTimeline?.trackingID}}</b>
                                                        </p>
                                                    </span> -->
                            <span class="oder-status-note">
                              <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                                (click)="openUpdateModal('reverse')">See all updates
                              </a>
                            </span>
                          </figure>
                        </span>
                      </div>

                      <div *ngIf="
                          status == 'delivered_warehouse' ||
                          status == 'warehouse_delivered' ||
                          status == 'delivered'
                        " [ngClass]="
                          ((status == 'delivered_warehouse' ||
                            status == 'warehouse_delivered' ||
                            status == 'delivered') &&
                          selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(
                            status
                          ) &&
                          !selectedItemDetails.qc
                            ? 'refund-bad'
                            : 'refund-succ') +
                          ' ' +
                          (selectedItemDetails.latestStatus ==
                            'delivered_warehouse' ||
                          selectedItemDetails.latestStatus ==
                            'warehouse_delivered' ||
                          selectedItemDetails.latestStatus == 'delivered'
                            ? 'active'
                            : '')
                        ">
                        <span class="i-status" *ngIf="
                            !warehouse_delivered.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          NEXT
                        </span>
                        <span class="i-status active" style="background-color:  #6FCF97;" *ngIf="
                            warehouse_delivered.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          Now
                        </span>
                        <p [ngClass]="
                            ((status == 'delivered_warehouse' ||
                              status == 'warehouse_delivered' ||
                              status == 'delivered') &&
                            selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(
                              status
                            ) &&
                            !selectedItemDetails.qc
                              ? 'refund-bad'
                              : 'refund-succ') +
                            ' ' +
                            (selectedItemDetails.latestStatus ==
                              'delivered_warehouse' ||
                            selectedItemDetails.latestStatus ==
                              'warehouse_delivered' ||
                            selectedItemDetails.latestStatus == 'delivered'
                              ? 'active'
                              : '')
                          ">
                          <!-- {{ (selectedItemDetails.returnType == returnTypeExchange) ?
                            'Exchange':(selectedItemDetails.returnType == returnTypeReplace ?
                            'Exchange': reverseStatusRename[status]) }} -->
                          {{ "Returned" }}
                        </p>
                        <!-- <ng-container *ngFor="let reverseStatus of remarkStatusArray; let i = index;"> -->
                        <ng-container *ngIf="
                            remarkDummyStatusArray.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          <ng-container *ngIf="
                              selectedItemDetails.reverseTimelineStatusLog[0].compareStatus.includes(
                                selectedItemDetails?.latestStatus
                              )
                            ">
                            <span class="no-tooltip" *ngIf="
                                reverseToolAndMessage(
                                  selectedItemDetails?.latestStatus
                                )
                              ">
                              On
                              {{ toolTipMessage }}</span>
                          </ng-container>
                        </ng-container>
                        <br />
                      </div>
                    </ng-container>
                  </div>

                  <!---------------------------------------------------Refund Desktop ---------------------->

                  <div class="refundAlign" *ngIf="
                      selectedItemDetails.latestStatus ==
                        'delivered_warehouse' ||
                      selectedItemDetails.latestStatus ==
                        'warehouse_delivered' ||
                      selectedItemDetails.latestStatus == 'delivered'
                    ">
                    <span class="oder-status-note1 first-boxxx">
                      <figure class="imageDisplay" *ngIf="!verifyOTP">
                        <ng-container *ngIf="
                            selectedItemDetails &&
                            selectedItemDetails.productImageUrl
                          ">
                          <img class="image" src="{{
                              selectedItemDetails &&
                              selectedItemDetails.productImageUrl
                                ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                                : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                            }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                            onerror="this.style.display='none'" />
                        </ng-container>
                        <span class="item-name-price">
                          <span [ngClass]="
                              selectedItemDetails.productName.length > 38
                                ? 'overflow-lenght'
                                : ''
                            ">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                            <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                              ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                            </ng-container>

                          <span *ngIf="selectedItemDetails.productName.length > 38" class="product-hover">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                          <p>
                            {{ rupeeSymbol }}
                            {{
                            selectedItemDetails?.invoiceTotal
                            ? transformDecimal(
                            selectedItemDetails?.invoiceTotal
                            )
                            : transformDecimal(selectedItemDetails?.lineItemTotal)
                            }}
                            <span *ngIf="selectedItemDetails.productSize">| Size
                              {{
                              selectedItemDetails
                              ? selectedItemDetails.productSize
                              : ""
                              }}</span>
                          </p>
                        </span>
                      </figure>
                    </span>
                    <span class="oder-status-note1  ">
                      <figure>
                        <span class="center-alignment">
                          <!-- <span class="checkbox"
                                                    *ngIf="warehouse_delivered.includes(selectedItemDetails?.latestStatus) && (selectedItemDetails.qc==0);">
                                                    <img src="assets/images/cancel-icon.svg">

                                                </span> -->
                          <!-- <span class="checkbox"
                                                    *ngIf="warehouse_delivered.includes(selectedItemDetails?.latestStatus) && (selectedItemDetails.returnType=='return' || selectedItemDetails.returnType=='exchange') && selectedItemDetails.qc==0;">
                                                    <img src="assets/images/cancel-icon.svg">

                                                </span> -->

                          <span class="checkbox" *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              )
                            ">
                            <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                          </span>

                          <span *ngIf="
                              warehouse_delivered.includes(
                                selectedItemDetails?.latestStatus
                              )
                            " style="padding-left: 6px; color: #5DB075;font-weight: bold; font-size:13px;">Return
                            Received
                          </span>

                          <!-- <span
                                                    *ngIf="warehouse_delivered.includes(selectedItemDetails?.latestStatus) && selectedItemDetails.qc==0;"
                                                    style="padding-left: 10px; color: red;font-weight: bold; font-size:13px;">Return Failed
                                                    Failed
                                                </span> -->
                        </span>

                        <!-- <span
                                                *ngIf="warehouse_delivered.includes(selectedItemDetails?.latestStatus) && selectedItemDetails.qc==0 "
                                                class="oder-status-note">

                                                Your   {{selectedItemDetails.returnType=='return'?'return':'return'}} item has failed our quality check, Please contact <a class="support-email"
                                                href="mailto:{{ (userSettings && userSettings?.supportEmail) ? userSettings?.supportEmail:'' }}">{{userSettings?.supportEmail}}</a> for further details

                                            </span> -->

                        <span *ngIf="
                            warehouse_delivered.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note">
                          Your
                          {{
                          selectedItemDetails.returnType == "return"
                          ? "return"
                          : "return"
                          }}
                          item has been successfully received.
                        </span>

                        <!-- <span class="oder-status-note">
                                                <p *ngIf="selectedItemDetails?.reverseTrackingTimeline?.courierName">
                                                    Courier Name -
                                                    <b>{{selectedItemDetails?.reverseTrackingTimeline?.courierName}}</b>
                                                </p>
                                                <p *ngIf="selectedItemDetails?.reverseTrackingTimeline?.trackingID">
                                                    Tracking number
                                                    <b>#{{selectedItemDetails?.reverseTrackingTimeline?.trackingID}}</b>
                                                </p>
                                            </span> -->
                        <span class="oder-status-note">
                          <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                            (click)="openUpdateModal('reverse')">See all updates
                          </a>
                        </span>
                      </figure>
                    </span>
                  </div>
                </ng-template>
              </ng-container>
              <!--End Here-->

              <!-- Forward Timeline Start Here -->
              <ng-container *ngIf="
                  selectedItemDetails.timeLine == 'forwardTrackingTimeline'
                ">
                <ng-container *ngIf="
                    cancellationStatusArray.includes(
                      selectedItemDetails.latestStatus
                    );
                    else forwardTimelineCond
                  ">
                  <div class="order-process step-two cancelled">
                    <ng-container *ngFor="let cancel of forwardTrackingTimelinestatusLog">
                      <div *ngIf="cancel.status == 'created'" class="ordered-i" [ngClass]="
                          cancel.status == selectedItemDetails.latestStatus
                            ? 'refund-raised active'
                            : ''
                        ">
                        <span class="i-status"> Done </span>

                        <p>Shipping Soon</p>
                        <span *ngIf="
                            cancel.status == selectedItemDetails.latestStatus &&
                            selectedItemDetails.onHold == 0
                          " class="no-tooltip ">
                          <!--  Order Placed on -->
                          <!-- {{ cancel.updatedAt ? replaceDate(cancel.updatedAt) :'' }}. -->
                          Arriving by
                          {{
                          selectedItemDetails?.expectedDeliveryDate
                          ? replaceDate(
                          selectedItemDetails?.expectedDeliveryDate
                          )
                          : ""
                          }}
                        </span>
                        <span class="no-tooltip" *ngIf="
                            cancel.status == selectedItemDetails.latestStatus &&
                            selectedItemDetails.onHold == 1
                          ">On Hold</span>
                      </div>
                      <div (click)="handleClick()" *ngIf="
                          cancel.status == selectedItemDetails.latestStatus
                        " class="order-cancelled-i active">
                        <span class="i-status active" style="background-color: #EB5757;">
                          Now
                        </span>
                        <p [ngStyle]="{
                            color: cancellationStatusArray.includes(
                              cancel.status
                            )
                              ? '#4F4F4F'
                              : '#B7B7B7'
                          }">
                          Cancelled
                        </p>

                        <!-- <p>Cancelled</p> -->
                        <span *ngIf="
                            cancellationStatusArray.includes(cancel.status) ||
                            selectedItemDetails.latestStatus == 'cancelled'
                          " class="no-tooltip">
                          <!-- Cancelled on -->
                          On
                          {{
                          cancel.updatedAt
                          ? replaceDate(cancel.updatedAt)
                          : ""
                          }}.
                          <!-- On  {{ selectedItemDetails?.expectedDeliveryDate ?
                                                replaceDate(selectedItemDetails.expectedDeliveryDate) :'' }} -->
                        </span>
                        <!-- <span class="no-tooltip" *ngIf="cancellationStatusArray.includes(cancel.status) && selectedItemDetails.onHold == 1">
                                                On  {{ selectedItemDetails?.expectedDeliveryDate ?
                                                    replaceDate(selectedItemDetails.expectedDeliveryDate) :'' }}</span> -->

                        <!-- <span class="i-status active"> Now </span> -->
                        <!-- <span class="oder-status-note"> Your order has been cancelled succesfully. -->
                      </div>
                    </ng-container>
                  </div>
                  <div class="refundAlign">
                    <span class="oder-status-note1 first-boxxx">
                      <figure class="imageDisplay" *ngIf="!verifyOTP">
                        <ng-container *ngIf="
                            selectedItemDetails &&
                            selectedItemDetails.productImageUrl
                          ">
                          <img class="image" src="{{
                              selectedItemDetails &&
                              selectedItemDetails.productImageUrl
                                ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                                : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                            }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                            onerror="this.style.display='none'" />
                        </ng-container>
                        <span class="item-name-price">
                          <span [ngClass]="
                              selectedItemDetails.productName.length > 38
                                ? 'overflow-lenght'
                                : ''
                            ">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                            <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                              ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                            </ng-container>

                          <span *ngIf="selectedItemDetails.productName.length > 38" class="product-hover">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                          <p>
                            {{ rupeeSymbol }}
                            {{
                            selectedItemDetails?.invoiceTotal
                            ? transformDecimal(
                            selectedItemDetails?.invoiceTotal
                            )
                            : transformDecimal(selectedItemDetails?.lineItemTotal)
                            }}
                            <span *ngIf="selectedItemDetails.productSize">| Size
                              {{
                              selectedItemDetails
                              ? selectedItemDetails.productSize
                              : ""
                              }}</span>
                          </p>
                        </span>
                      </figure>
                    </span>
                    <span class="oder-status-note1">
                      <figure>
                        <span class="center-alignment">
                          <span>
                            <img src="assets/images/cancel-icon.svg" />
                          </span>
                          <span *ngIf="!(selectedItemDetails.latestStatus == 'cancelled' && previousStatus == 'intransit')" style="padding-left: 6px; color: red;font-weight: bold;">Delivery Cancelled</span>
                          <span *ngIf="selectedItemDetails.latestStatus == 'cancelled' && previousStatus == 'intransit'" style="padding-left: 6px; color: red;font-weight: bold;">Cancellation Requested</span>
                        </span>
                        <ng-container *ngFor="
                            let cancel of forwardTrackingTimelinestatusLog
                          ">
                          <span *ngIf="
                              selectedItemDetails.latestStatus == 'cancelled' &&
                              (cancel.status == 'cancelled' &&
                                cancel.remark == 'cancelled') &&
                              orderData.isCOD === 1
                            " class="oder-status-note">{{
                            "As per your request, this item has been cancelled. Please do not accept, if delivery is
                            attempted."
                            }}</span>

                          <span *ngIf="
                              selectedItemDetails.latestStatus == 'cancelled' &&
                              (cancel.status == 'cancelled' &&
                                cancel.remark == 'cancelled') &&
                              orderData.isCOD === 0
                            " class="oder-status-note">{{
                            "As per your request, this item has been cancelled. Please do not accept, if delivery is
                            attempted. If you have already paid, a full refund will be inititated. "
                            }}</span>

                          <span *ngIf="
                              selectedItemDetails.latestStatus == 'cancelled' &&
                              (cancel.status == 'cancelled' &&
                                (cancel.remark == '' ||
                                  cancel.remark == 'fulfillment_cancel'))
                            " class="oder-status-note">{{
                            "We regret to inform you that we were unable to process your order due to fulfilment issue."
                            }}
                          </span>
                        </ng-container>
                        <span *ngIf="
                            cancellationStatusArrayNew.includes(
                              selectedItemDetails.latestStatus
                            )
                          " class="oder-status-note">{{
                          "We regret to inform you that your order has been cancelled due to multiple failed attempts."
                          }}
                        </span>
                        <span *ngIf="
                        selectedItemDetails.latestStatus == 'cancelled' && previousStatus == 'intransit'
                          " class="oder-status-note">{{
                          "Your cancellation request has been submitted. If order delivery is still attempted, please refuse to accept it."
                          }}
                        </span>
                        <span class="oder-status-note">
                          <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                            (click)="openUpdateModal()">See all updates
                          </a>
                        </span>
                        <!-- <span   class="oder-status-note" >
                                           <a style="font-size: 13px;font: weight 600;text-decoration: underline;">See all updates
                                        </a>

                                        </span> -->
                      </figure>
                    </span>
                  </div>
                </ng-container>
                <ng-template #forwardTimelineCond>
                  <div [ngClass]="
                      selectedItemDetails.modifyStatusLog[0].statusLength > 1
                        ? 'order-process step-two step-four ' +
                          statusActiveClass[
                            selectedItemDetails.modifyStatusLog[0].statusLength
                          ]
                        : 'order-process step-two step-four'
                    ">
                    <ng-container *ngFor="let status of forwardStatusArray">
                      <div *ngIf="forwardIntransitStatus.includes(status)" [ngClass]="
                          intransit_status.includes(
                            selectedItemDetails?.latestStatus
                          )
                            ? 'small active'
                            : 'small'
                        ">
                        <!-- [ngClass]="selectedItemDetails.modifyStatusLog[0].compareStatus.includes(status) ? 'small active':'small'"> -->
                        <!-- [ngClass]="(selectedItemDetails.latestStatus == 'failed_delivery' || selectedItemDetails.latestStatus == 'ndr_resolution_submitted') ? 'small active red':(selectedItemDetails.modifyStatusLog[0].compareStatus.includes(status) ? 'small active':'small')" -->
                        <span class="i-status" *ngIf="
                            placed.includes(
                              selectedItemDetails?.latestStatus
                            ) ||
                            packed.includes(selectedItemDetails?.latestStatus)
                          ">
                          NEXT
                        </span>
                        <span class="i-status active" style="background-color: #ECB73F;" *ngIf="
                            intransit_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          Now
                        </span>
                        <span class="i-status" *ngIf="
                            delivered.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          DONE
                        </span>
                        <span [ngClass]="
                            forwardIntransitStatus.includes(status)
                              ? 'small-status'
                              : ''
                          ">In transit</span>

                        <!-- <ng-container *ngFor="let middlestatus of forwardIntransitStatus">
                                                                <ng-container *ngIf="selectedItemDetails.modifyStatusLog[0].compareStatus.includes(middlestatus)"> {{middlestatus}}
                                                                <span class="tool-tip arrow-bottom" *ngIf="forwardToolAndRemark(middlestatus)">{{ toolTipMessage }}</span>
                                                                </ng-container>
                                                            </ng-container> -->
                        <ng-container>
                          <ng-container *ngIf="
                              Delivery_failed.includes(
                                selectedItemDetails?.latestStatus
                              ) ||
                              out_for_delivery.includes(
                                selectedItemDetails?.latestStatus
                              )
                            ">
                            <span class="no-tooltip" *ngIf="
                                forwardToolAndRemark(
                                  selectedItemDetails?.latestStatus
                                )
                              ">On {{ toolTipMessage }}</span>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="
                            intransit_status.includes(
                              selectedItemDetails?.latestStatus
                            ) &&
                            !Delivery_failed.includes(
                              selectedItemDetails?.latestStatus
                            ) &&
                            !out_for_delivery.includes(
                              selectedItemDetails?.latestStatus
                            )
                          ">
                          <!-- <span
                                                    class="no-tooltip" >Arriving by {{
                                                    selectedItemDetails?.expectedDeliveryDate ?
                                                    replaceDate(selectedItemDetails.expectedDeliveryDate) :''
                                                    }}</span> -->
                          <ng-container *ngIf="seeUpdatesData">
                            <span class="no-tooltip" *ngIf="getStatusDate(seeUpdatesData, 'intransit')">
                              <a class="updates">
                                <!-- On {{ status.value[0].updatedAt | date:'EE MMM d, y' }} -->
                                On
                                {{
                                replaceDate(
                                getStatusDate(seeUpdatesData, "intransit")
                                )
                                }}
                              </a>
                            </span>
                          </ng-container>
                          <!-- <span class="no-tooltip" *ngFor="let item of selectedItemDetails?.forwardTrackingTimeline?.statusLog" >
                                                        <span *ngIf="item.status == 'intransit'">On {{item.updatedAt  | date:'EE MMM d, y' }}</span>
                                                     </span> -->

                          <!-- <span
                                                    class="no-tooltip" *ngIf="selectedItemDetails?.latestRevisedExpectedDeliveryDate || selectedItemDetails.expectedDeliveryDate ">
                                                    Arriving by
                                                    {{selectedItemDetails?.latestRevisedExpectedDeliveryDate
                                                    ? replaceDate(selectedItemDetails.latestRevisedExpectedDeliveryDate)
                                                    : replaceDate(selectedItemDetails.expectedDeliveryDate) }}
                                                </span> -->
                        </ng-container>

                        <!-- <span class="i-status"
                                                *ngIf="selectedItemDetails.latestStatus == 'created' || selectedItemDetails.latestStatus == 'packed'">
                                                NEXT </span>
                                            <span class="i-status active"
                                                *ngIf="forwardIntransitStatus.includes(selectedItemDetails.latestStatus)">
                                                Now </span>
                                            <span class="i-status"
                                                *ngIf="selectedItemDetails.latestStatus == 'delivered'"> DONE </span>
                                            <span
                                                *ngIf="forwardIntransitStatus.includes(selectedItemDetails.latestStatus)"
                                                class="oder-status-note" [innerHtml]="remarkMessage"></span>
 -->
                        <span *ngIf="
                            intransit_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note1 first-boxxx">
                          <figure class="imageDisplay" *ngIf="!verifyOTP">
                            <ng-container *ngIf="
                                selectedItemDetails &&
                                selectedItemDetails.productImageUrl
                              ">
                              <img class="image" src="{{
                                  selectedItemDetails &&
                                  selectedItemDetails.productImageUrl
                                    ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                    : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                      selectedItemDetails.productImageUrl +
                                      '?tr=w-300'
                                }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                                onerror="this.style.display='none'" />
                            </ng-container>
                            <span class="item-name-price">
                              <span [ngClass]="
                                  selectedItemDetails.productName.length > 38
                                    ? 'overflow-lenght'
                                    : ''
                                ">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                                <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                                  ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                                </ng-container>

                              <span *ngIf="
                                  selectedItemDetails.productName.length > 38
                                " class="product-hover">{{
                                selectedItemDetails
                                ? selectedItemDetails.productName
                                : ""
                                }}</span>
                              <p>
                                {{ rupeeSymbol }}
                                {{
                                selectedItemDetails?.invoiceTotal
                                ? transformDecimal(
                                selectedItemDetails?.invoiceTotal
                                )
                                : transformDecimal(selectedItemDetails?.lineItemTotal)
                                }}
                                <span *ngIf="selectedItemDetails.productSize">| Size
                                  {{
                                  selectedItemDetails
                                  ? selectedItemDetails.productSize
                                  : ""
                                  }}</span>
                              </p>
                            </span>
                          </figure>
                        </span>
                        <span *ngIf="
                            intransit_status.includes(
                              selectedItemDetails?.latestStatus
                            )
                          " class="oder-status-note1">
                          <figure>
                            <span class="center-alignment">
                              <span *ngIf="
                                  shipped.includes(
                                    selectedItemDetails?.latestStatus
                                  ) ||
                                  shipment_delay.includes(
                                    selectedItemDetails?.latestStatus
                                  ) ||
                                  out_for_delivery.includes(
                                    selectedItemDetails?.latestStatus
                                  ) ||
                                  selectedItemDetails?.latestStatus ==
                                    'shipment_held'
                                " class="checkbox">
                                <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                              </span>
                              <span *ngIf="
                                  Delivery_failed.includes(
                                    selectedItemDetails?.latestStatus
                                  ) ||
                                  (selectedItemDetails?.latestStatus ==
                                    'ndr_resolution_submitted' &&
                                    selectedItemDetails.forwardTrackingTimeline
                                      .odaAddress != '')
                                ">
                                <img src="assets/images/cancel-icon.svg" />
                              </span>
                              <span *ngIf="
                                  shipped.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold;">Shipped</span>
                              <span *ngIf="
                                  out_for_delivery.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #5DB075;font-weight: bold;">Out for delivery</span>
                              <span *ngIf="
                                  shipment_delay.includes(
                                    selectedItemDetails?.latestStatus
                                  )
                                " style="padding-left: 6px; color: #9dada2;font-weight: bold;">Shipped
                              </span>

                              <span *ngIf="
                                  selectedItemDetails?.latestStatus ==
                                  'shipment_held'
                                " style="padding-left: 6px; color: #9dada2;font-weight: bold;">Shipped
                              </span>

                              <span *ngIf="
                                  Delivery_failed.includes(
                                    selectedItemDetails?.latestStatus
                                  ) ||
                                  (selectedItemDetails?.latestStatus ==
                                    'ndr_resolution_submitted' &&
                                    selectedItemDetails.forwardTrackingTimeline
                                      .odaAddress != '')
                                " style="padding-left: 6px; color: red;">Delivery Failed</span>

                              <!-- <span
                                                            *ngIf="selectedItemDetails?.latestStatus=='ndr_resolution_submitted' "
                                                            style="padding-left: 6px; color: #5DB075;font-weight: bold;">Delivery request submitted</span>  -->

                              <!-- <span
                                                            *ngIf="shipment_delay.includes(selectedItemDetails?.latestStatus) "
                                                            style="padding-left: 6px; color: #5DB075;font-weight: bold;">{{shipment_delay_status}}</span> -->

                              <!-- <span
                                                            *ngIf="shipment_delay.includes(selectedItemDetails?.latestStatus)"
                                                            style="padding-left: 6px; color: #5DB075;font-weight: bold;">
                                                            <ng-container
                                                                *ngFor="let status of selectedItemDetails.forwardTrackingTimeline?.statusLog;let i=index"
                                                                style="padding-left: 6px; color: #5DB075;font-weight: bold;">
                                                                <span *ngIf="shipment_delay.includes(status.status)">
                                                                    {{
                                                                    getShipmentStatus(selectedItemDetails.forwardTrackingTimeline?.statusLog[i-1]?.status)}}
                                                                </span>
                                                            </ng-container> -->
                              <!-- <span *ngIf="getShipment(selectedItemDetails.forwardTrackingTimeline?.statusLog[i-1]?.status)">
                                                                {{shipped_delay_message}}
                                                               </span> -->
                              <!-- </span> -->
                            </span>
                            <span *ngIf="
                                shipped.includes(
                                  selectedItemDetails?.latestStatus
                                ) ||
                                selectedItemDetails?.latestStatus ==
                                  'shipment_held'
                              " class="oder-status-note">{{
                              "Your item has been picked up by our courier partner. You will be notified as soon as it
                              is out for delivery."
                              }}</span>

                            <span *ngIf="
                                out_for_delivery.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">{{
                              "Your item is out for delivery by our courier partner."
                              }}</span>

                            <!-- <span
                                                    *ngIf="shipment_delay.includes(selectedItemDetails?.latestStatus)"
                                                    class="oder-status-note">Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery. </span>  -->

                            <span *ngIf="
                                Delivery_failed.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">
                              <!-- {{'We are unable to deliver your item due to failed attempt. You will be notified as soon as our courier partner will re-attempt the delivery.'}} -->
                              {{
                              "We are unable to deliver your item due to failed attempt."
                              }}
                              <ng-container *ngIf="selectedItemDetails?.latestStatu">
                                We will notify you of the address and contact
                                details of the nearest hub for pick-up.
                              </ng-container>
                            </span>

                            <span *ngIf="
                                selectedItemDetails.latestStatus ==
                                  'ndr_resolution_submitted' &&
                                selectedItemDetails.forwardTrackingTimeline
                                  .odaAddress
                              " class="oder-status-note">
                              We are unable to deliver your item due to failed
                              attempt. We request you to pick up the items
                              from:<br />

                              <p class="odaaddress" *ngIf="
                                  selectedItemDetails.forwardTrackingTimeline
                                    .odaAddress
                                ">
                                {{
                                selectedItemDetails.forwardTrackingTimeline
                                .odaAddress
                                }}
                                <a class="odacopy" (click)="
                                    copyInputOda(
                                      selectedItemDetails
                                        .forwardTrackingTimeline.odaAddress
                                    )
                                  "><img src="https://ik.imagekit.io/2gwij97w0o/POE/icon-copy.svg" /></a>
                              </p>
                            </span>

                            <span *ngIf="
                                selectedItemDetails.latestStatus ==
                                  'failed_delivery' &&
                                selectedItemDetails.forwardTrackingTimeline
                                  .odaAddress == ''
                              " class="oder-status-note">
                              <p class="odaaddress">
                                We will notify you of the address and contact
                                details of the nearest hub for pick-up.
                              </p>
                            </span>

                            <ng-container>
                              <span *ngIf="
                                  selectedItemDetails?.latestStatus ==
                                  'ndr_resolution_submitted'
                                " class="oder-status-note">
                                <ng-container *ngIf="
                                    selectedItemDetails?.ndrResolutionDetails
                                      ?.resolutionCode == 'ACTF001'
                                  ">
                                  Your request to re-attempt delivery has been
                                  submitted. We will let you know when the
                                  courier partner is out for delivery.
                                </ng-container>

                                <ng-container *ngIf="
                                    selectedItemDetails?.ndrResolutionDetails
                                      ?.resolutionCode == 'ACTF002'
                                  ">
                                  Your request to change the delivery date has
                                  been submitted. We will let you know when the
                                  courier partner is out for delivery.
                                </ng-container>

                                <ng-container *ngIf="
                                    selectedItemDetails?.ndrResolutionDetails
                                      ?.resolutionCode == 'ACTF003'
                                  ">
                                  Your request to cancel the delivery for this
                                  item has been submitted.
                                </ng-container>

                                <ng-container *ngIf="
                                    selectedItemDetails?.ndrResolutionDetails
                                      ?.resolutionCode == 'ACTF004'
                                  ">
                                  Your request to report the courier mentioned
                                  reason as false has been submitted. We will
                                  let you know when the courier partner is out
                                  for delivery.
                                </ng-container>
                              </span>
                            </ng-container>

                            <!-- <span
                                                        *ngIf="shipment_delay.includes(selectedItemDetails?.latestStatus) "
                                                        class="oder-status-note">
                                                        <ng-container
                                                            *ngFor="let status of selectedItemDetails.forwardTrackingTimeline?.statusLog;let i=index">
                                                            <span *ngIf="shipment_delay.includes(status.status) ">
                                                                <span
                                                                    >
                                                                    {{getShipment(selectedItemDetails.forwardTrackingTimeline?.statusLog[i-1]?.status)}}
                                                                </span>
                                                            </span>

                                                            <span>

                                                            </span>
                                                        </ng-container>
                                                    </span> -->
                            <span *ngIf="
                                shipment_delay.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note">
                              <ng-container>
                                <span *ngIf="getShipment(selectedItemDetails)">
                                  {{ shipped_delay_message }}
                                </span>
                              </ng-container>
                            </span>

                            <!-- <span class="oder-status-note">
                                                        <p
                                                            *ngIf="selectedItemDetails?.forwardTrackingTimeline?.courierName">
                                                            Courier Name -
                                                            <b>{{selectedItemDetails?.forwardTrackingTimeline?.courierName}}</b>
                                                        </p>
                                                        <p
                                                            *ngIf="selectedItemDetails?.forwardTrackingTimeline?.trackingID">
                                                            Tracking number
                                                            <b>#{{selectedItemDetails?.forwardTrackingTimeline?.trackingID}}</b>
                                                        </p>
                                                    </span> -->

                            <span class="oder-status-note">
                              <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                                (click)="openUpdateModal()">See all updates
                              </a>
                            </span>

                            <!-- <ng-container
                                                        *ngFor="let status of selectedItemDetails.forwardTrackingTimeline?.statusLog">
                                                        <span class="oder-status-note"
                                                            *ngIf="((shipped.includes(selectedItemDetails?.latestStatus) || shipment_delay.includes(selectedItemDetails?.latestStatus)) && status.status=='shipment_delayed' && userSettings?.notifyDelayedDeliveryPickup) ">
                                                            <p class="note-status">
                                                                <span> Note : {{userSettings?.delayedDeliveryMessage}}
                                                                </span>

                                                            </p>

                                                        </span>

                                                    </ng-container> -->
                            <!-- <ng-container
                                                   >

                                                    <span class="oder-status-note"
                                                        *ngIf="(selectedItemDetails.latestRevisedExpectedDeliveryDate   > selectedItemDetails. expectedDeliveryDate || todaysDate > selectedItemDetails. expectedDeliveryDate)">
                                                        <p class="note-status">
                                                            <span> Note : {{userSettings?.delayedDeliveryMessage}}
                                                            </span>

                                                        </p>

                                                    </span>

                                                </ng-container> -->

                            <ng-container> </ng-container>
                          </figure>
                        </span>
                        <span class="oder-status-note1 return-cancel" *ngIf="
                                selectedItemDetails?.latestStatus == 'intransit'
                                &&
                                userSettings?.allowCancellation &&
                                selectedItemDetails.cancellationPolicy &&
                                selectedItemDetails.cancellationPolicy
                                  .isCancellable === true
                              ">
                                <figure>
                                  <span class="cancelbutton" *ngIf="
                                    selectedItemDetails.timeLine ===
                                      'forwardTrackingTimeline' &&
                                      selectedItemDetails.cancellationPolicy
                                        .isCancellable === true
                                  ">
                                    <a [ngStyle]="getStyleWithoutOpacity()" class="layout" (click)="
                                      moveToCancellation(
                                        selectedItemDetails.orderItemID,
                                        selectedItemDetails.latestStatus
                                      )
                                    ">Request Cancellation
                                      <span [ngStyle]="getStyleWithOpacity()"></span>
                                    </a>
                                  </span>
                                </figure>
                              </span>
                        <ng-container>
                          <span class="oder-status-note1 return-cancel" *ngIf="
                              Delivery_failed.includes(
                                selectedItemDetails?.latestStatus
                              ) ||
                              (selectedItemDetails.latestStatus ==
                                'failed_delivery' &&
                                selectedItemDetails.forwardTrackingTimeline
                                  .odaAddress == '') &&
                              (selectedItemDetails.latestStatus ==
                                'ndr_resolution_submitted' &&
                                selectedItemDetails.forwardTrackingTimeline
                                  .odaAddress != '')
                            ">
                            <figure>
                              <span class="cancelbutton">
                                <a [ngStyle]="getStyletakeaction()" class="takeaction" (click)="
                                    moveToFailedDelivery(
                                      selectedItemDetails.orderItemID
                                    )
                                  ">Take Action
                                  <span [ngStyle]="getStyleWithOpacity()"></span></a>
                              </span>
                            </figure>
                          </span>
                        </ng-container>
                        <!-- Take Next Action end -->
                        <span class="mark-as-delivery-content"
                          *ngIf="selectedItemDetails?.latestStatus == 'out_for_delivery' && orderData.isCOD === 0">
                          <span>Hey, If you have already received this item but it's still showing "out for delivery",
                            kindly confirm the delivery by marking the item as delivered.</span>
                        </span>
                        <!-- Mark As Delivered start -->
                        <span class="oder-status-note1 return-cancel marked-button" *ngIf="
                            out_for_delivery.includes(
                              selectedItemDetails?.latestStatus
                            )  && orderData.isCOD === 0
                          ">
                          <figure>
                            <span class="cancelbutton ">
                              <a [ngStyle]="{ color: userSettings.actionColor }" (click)="
                                  moveToMarkAsDeliveredPopup(
                                    selectedItemDetails.orderItemID
                                  )
                                ">Mark as Delivered</a>
                            </span>
                          </figure>
                        </span>
                        <!-- Mark As Delivered end -->

                        <!-- <span *ngIf="Delivery_failed.includes(selectedItemDetails?.latestStatus) "
                                                class="oder-status-note1 return-cancel">
                                                <figure>

                                                    <span class="cancelbutton"
                                                        *ngIf="selectedItemDetails.timeLine === 'forwardTrackingTimeline' && selectedItemDetails.cancellationPolicy && selectedItemDetails.cancellationPolicy.isCancellable === true && !cancellationStatusArray.includes(selectedItemDetails.latestStatus) && selectedItemDetails.latestStatus != 'delivered'">

                                                        <a [ngStyle]="{color:userSettings.actionColor}"
                                                            (click)="moveToCancellation(selectedItemDetails.orderItemID, selectedItemDetails.latestStatus)">Cancel
                                                            Item</a>
                                                    </span>

                                                </figure>
                                            </span> -->
                      </div>

                      <!----------------------------------------------------------Status=====Created----------------------------------------------------->
                      <div (click)="handleClick()" *ngIf="status == 'created'" [ngClass]="
                          placed.includes(selectedItemDetails.latestStatus) ||
                          packed.includes(selectedItemDetails?.latestStatus)
                            ? 'active'
                            : 'not-active'
                        " class="return-initiated">
                        <span *ngIf="
                            placed.includes(selectedItemDetails.latestStatus) ||
                            packed.includes(selectedItemDetails?.latestStatus)
                          " class="i-status active">
                          Now
                        </span>
                        <span *ngIf="
                            !(
                              placed.includes(
                                selectedItemDetails.latestStatus
                              ) ||
                              packed.includes(selectedItemDetails?.latestStatus)
                            )
                          " class="i-status">
                          DONE
                        </span>

                        <ng-container
                          *ngIf="((!orderData?.isPincodeServiceable && selectedItemDetails.latestStatus != 'cancelled') ||
                        ((orderData?.riskScore | lowercase) == 'high'
                         && orderData?.riskScoreReasons.includes('Address issue') && selectedItemDetails.latestStatus != 'cancelled')) && selectedItemDetails.onHold != '0'; else elseTemplate">
                          <p>On hold</p>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <p>Shipping soon</p>
                        </ng-template>

                        <ng-container>
                          <!-- <span class="no-tooltip"
                                                    *ngIf="packed.includes(selectedItemDetails?.latestStatus) && selectedItemDetails?.onHold == 0">Arriving
                                                    by {{ selectedItemDetails?.expectedDeliveryDate ?
                                                    replaceDate(selectedItemDetails?.expectedDeliveryDate) :''
                                                    }}</span> -->
                          <span class="no-tooltip" *ngIf="
                              selectedItemDetails?.onHold == 1 &&
                              this.orderData.isPoeOldFlow &&
                              (packed.includes(
                                selectedItemDetails?.latestStatus
                              ) ||
                                placed.includes(
                                  selectedItemDetails?.latestStatus
                                ))
                            ">On Hold</span>
                        </ng-container>
                        <!--
                                            <span *ngIf="selectedItemDetails.latestStatus !== 'created'"
                                                class="i-status"> DONE </span> -->
                        <ng-container *ngIf="
                            selectedItemDetails &&
                            selectedItemDetails.latestStatus
                          ">
                          <!--Forward Timeline Cancellation-->
                          <ng-container *ngIf="
                              cancellationStatusArray.includes(
                                selectedItemDetails.latestStatus
                              );
                              else secondtype
                            ">
                            <div *ngIf="
                                selectedItemDetails.timeLine ==
                                  'refundTimeLine';
                                else otherRemark
                              " class="status-note">
                              {{ remarkMessage }}
                            </div>
                            <ng-template #otherRemark>
                              <div *ngIf="
                                  selectedItemDetails.latestStatus ==
                                    'cancelled' && orderData.isCOD === 1
                                " class="status-note">
                                As per your request, this item has been
                                cancelled. Please do not accept, if delivery is
                                attempted.
                              </div>
                              <div *ngIf="
                                  selectedItemDetails.latestStatus ==
                                    'cancelled' && orderData.isCOD === 0
                                " class="status-note">
                                As per your request, this item has been
                                cancelled. Please do not accept, if delivery is
                                attempted.Your refund will be initiated within
                                1-3 Days.
                              </div>
                              <div *ngIf="
                                  selectedItemDetails.latestStatus !=
                                  'cancelled'
                                " class="status-note">
                                We regret to inform you that we were unable to
                                process your order request as reason for
                                unfulfillemt.
                              </div>
                            </ng-template>
                          </ng-container>
                          <!--End Here-->
                          <!--Return Timeline Cancellation-->
                          <ng-template #secondtype>
                            <ng-container *ngIf="
                                selectedItemDetails.timeLine ==
                                  'reverseTrackingTimeline' &&
                                  returnCancelledTimelineStatus.includes(
                                    selectedItemDetails.latestStatus
                                  );
                                else thirdtype
                              ">
                              <div class="status-note">
                                As per your request this return has been
                                cancelled.Please do not accept if return is
                                attempted by the courier partner.
                              </div>
                            </ng-container>
                          </ng-template>
                          <!--End Here-->
                          <ng-template #thirdtype>
                            <!-- <span *ngIf="selectedItemDetails.latestStatus == 'created'"
                                                        class="oder-status-note" [innerHtml]="remarkMessage"></span> -->
                            <!-- {{ remarkMessage }} -->
                            <span *ngIf="
                                placed.includes(
                                  selectedItemDetails.latestStatus
                                ) ||
                                packed.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note1 first-boxxx">
                              <figure class="imageDisplay" *ngIf="!verifyOTP">
                                <ng-container *ngIf="
                                    selectedItemDetails &&
                                    selectedItemDetails.productImageUrl
                                  ">
                                  <img class="image" src="{{
                                      selectedItemDetails &&
                                      selectedItemDetails.productImageUrl
                                        ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                          selectedItemDetails.productImageUrl +
                                          '?tr=w-300'
                                        : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                          selectedItemDetails.productImageUrl +
                                          '?tr=w-300'
                                    }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                                    onerror="this.style.display='none'" />
                                </ng-container>
                                <span class="item-name-price">
                                  <span [ngClass]="
                                      selectedItemDetails.productName.length >
                                      38
                                        ? 'overflow-lenght'
                                        : ''
                                    ">{{
                                    selectedItemDetails
                                    ? selectedItemDetails.productName
                                    : ""
                                    }}</span>
                                    <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                                      ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                                    </ng-container>

                                  <span *ngIf="
                                      selectedItemDetails.productName.length >
                                      38
                                    " class="product-hover">{{
                                    selectedItemDetails
                                    ? selectedItemDetails.productName
                                    : ""
                                    }}</span>
                                  <p>
                                    {{ rupeeSymbol }}
                                    {{
                                    selectedItemDetails?.invoiceTotal
                                    ? transformDecimal(
                                    selectedItemDetails?.invoiceTotal
                                    )
                                    : transformDecimal(selectedItemDetails?.lineItemTotal)
                                    }}
                                    <span *ngIf="selectedItemDetails.productSize">| Size
                                      {{
                                      selectedItemDetails
                                      ? selectedItemDetails.productSize
                                      : ""
                                      }}</span>
                                  </p>
                                </span>
                              </figure>
                            </span>
                            <span *ngIf="
                                placed.includes(
                                  selectedItemDetails.latestStatus
                                ) ||
                                packed.includes(
                                  selectedItemDetails?.latestStatus
                                )
                              " class="oder-status-note1">
                              <figure>
                                <span class="center-alignment" *ngIf="
                                    placed.includes(
                                      selectedItemDetails.latestStatus
                                    ) ||
                                    packed.includes(
                                      selectedItemDetails?.latestStatus
                                    )
                                  ">
                                  <span class="checkbox">
                                    <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />
                                  </span>
                                  <span *ngIf="
                                      placed.includes(
                                        selectedItemDetails?.latestStatus
                                      )
                                    " style="padding-left: 6px; color: #5DB075;font-weight: bold;">Placed
                                  </span>
                                  <span *ngIf="
                                      packed.includes(
                                        selectedItemDetails?.latestStatus
                                      )
                                    " style="padding-left: 6px; color: #5DB075;font-weight: bold;">Packed</span>
                                </span>

                                <span *ngIf="
                                    placed.includes(
                                      selectedItemDetails?.latestStatus
                                    )
                                  " class="oder-status-note">
                                  <span
                                    *ngIf="(orderData?.riskScore | lowercase) == 'high' && orderData?.riskScoreReasons.includes('Address issue') && orderData?.isPincodeServiceable && selectedItemDetails.latestStatus != 'cancelled' && selectedItemDetails.onHold != '0'">
                                    Your item is put on hold due to incomplete or inaccurate address.
                                  </span>
                                  <span
                                    *ngIf="!orderData?.isPincodeServiceable && ((orderData?.riskScore | lowercase) != 'high' || !orderData?.riskScoreReasons.includes('Address issue')) && selectedItemDetails.latestStatus != 'cancelled' && selectedItemDetails.onHold != '0'">
                                    Your item is put on hold due to delivery address is not serviceable.
                                  </span>
                                  <span
                                    *ngIf="(!orderData?.isPincodeServiceable) && (orderData?.riskScore | lowercase) == 'high' && orderData?.riskScoreReasons.includes('Address issue') && selectedItemDetails.latestStatus != 'cancelled' && selectedItemDetails.onHold != '0'">
                                    Your item is put on hold due to delivery address is incorrect/inaccurate, and the
                                    pincode provided is not serviceable.
                                  </span>
                                  <span
                                    *ngIf="orderData?.isPincodeServiceable && ((orderData?.riskScore | lowercase) != 'high' || !orderData?.riskScoreReasons.includes('Address issue')) || selectedItemDetails.onHold == '0'">
                                    {{
                                    "Your order has been placed successfully. You will be notified as soon as it is
                                    ready to be shipped."
                                    }}
                                  </span>
                                </span>
                                <span *ngIf="
                                    packed.includes(
                                      selectedItemDetails?.latestStatus
                                    )
                                  " class="oder-status-note">{{
                                  "Your item is ready to be shipped by our courier partner. You will be notified as soon
                                  as it is dispatched."
                                  }}</span>

                                <!-- <span class="oder-status-note"
                                                                *ngIf="packed.includes(selectedItemDetails?.latestStatus)">
                                                                <p
                                                                    *ngIf="selectedItemDetails?.forwardTrackingTimeline?.courierName">
                                                                    Courier Name -
                                                                    <b>{{selectedItemDetails?.forwardTrackingTimeline?.courierName}}</b>
                                                                </p>
                                                                <p
                                                                    *ngIf="selectedItemDetails?.forwardTrackingTimeline?.trackingID">
                                                                    Tracking number
                                                                    <b>#{{selectedItemDetails?.forwardTrackingTimeline?.trackingID}}</b>
                                                                </p>
                                                            </span> -->
                                <span class="oder-status-note" *ngIf="
                                    packed.includes(
                                      selectedItemDetails?.latestStatus
                                    )
                                  ">
                                  <a class="update-btn"
                                    style="font-size: 13px;font: weight 600;text-decoration: underline;"
                                    (click)="openUpdateModal()">See all updates
                                  </a>
                                </span>
                                <span class="oder-status-note" *ngIf="selectedItemDetails?.onHold == 1">
                                  <p class="note-status">
                                    <span>Note :
                                      {{
                                      "This item will be processed once we receive the exchange product in a good
                                      condition"
                                      }}
                                    </span>
                                  </p>
                                </span>
                              </figure>
                            </span>
                            <span>

                              <span *ngIf="((!orderData?.isPincodeServiceable && selectedItemDetails.latestStatus != 'cancelled') ||
                                          ((orderData?.riskScore | lowercase) == 'high'
                                           && orderData?.riskScoreReasons.includes('Address issue') && selectedItemDetails.latestStatus != 'cancelled')) && selectedItemDetails.onHold != '0'
                                          " class="update-address">
                                <a class="layout" (click)="showEditDetails()">Update Address

                                </a>
                              </span>

                              <span class="oder-status-note1 return-cancel" *ngIf="
                                placed.includes(
                                  selectedItemDetails?.latestStatus
                                ) ||
                                selectedItemDetails?.latestStatus == 'packed' &&
                                userSettings?.allowCancellation &&
                                selectedItemDetails.cancellationPolicy &&
                                selectedItemDetails.cancellationPolicy
                                  .isCancellable === true
                              ">
                                <figure>
                                  <span class="cancelbutton" *ngIf="
                                    selectedItemDetails.timeLine ===
                                      'forwardTrackingTimeline' &&
                                    selectedItemDetails.cancellationPolicy
                                      .isCancellable === true
                                  ">
                                    <a [ngStyle]="getStyleWithoutOpacity()" class="layout" (click)="
                                      moveToCancellation(
                                        selectedItemDetails.orderItemID,
                                        selectedItemDetails.latestStatus
                                      )
                                    ">Cancel Item
                                      <span [ngStyle]="getStyleWithOpacity()"></span>
                                    </a>
                                  </span>
                                </figure>
                              </span>
                            </span>
                          </ng-template>
                        </ng-container>
                      </div>
                      <div (click)="handleClick()" *ngIf="status == 'delivered'" [ngClass]="
                          selectedItemDetails.modifyStatusLog[0].compareStatus.includes(
                            status
                          )
                            ? 'active'
                            : ''
                        ">
                        <span class="i-status" *ngIf="
                            !delivered.includes(
                              selectedItemDetails.latestStatus
                            )
                          ">
                          next </span><br />
                        <span class="i-status active" *ngIf="
                            delivered.includes(selectedItemDetails.latestStatus)
                          " style="background-color: #6FCF97;">
                          Now
                        </span>
                        <p [ngStyle]="{
                            color: delivered.includes(
                              selectedItemDetails.latestStatus
                            )
                              ? '#6FCF97'
                              : '#B7B7B7'
                          }">
                          Delivered
                        </p>

                        <!-- <p
                                                [ngClass]="delivered.includes(selectedItemDetails.latestStatus) ? 'color-green' :''">
                                                Delivered1</p> -->
                        <ng-container *ngIf="
                            selectedItemDetails.modifyStatusLog[0].compareStatus.includes(
                              status
                            )
                          ">
                          <span class="no-tooltip" *ngIf="
                              forwardToolAndRemark(
                                selectedItemDetails.latestStatus
                              )
                            ">
                            On
                            {{ toolTipMessage }}
                            <!-- {{
                                                        selectedItemDetails?.expectedDeliveryDate ?
                                                        replaceDate(selectedItemDetails.expectedDeliveryDate) :''  }} -->
                          </span>
                        </ng-container>
                        <br />
                        <!-- <span class="i-status"
                                                *ngIf="selectedItemDetails.latestStatus !== 'delivered'"> NEXT </span>
                                            <span class="i-status active"
                                                *ngIf="selectedItemDetails.latestStatus == 'delivered'"> Now </span> -->
                        <!-- <span *ngIf="selectedItemDetails.latestStatus == 'delivered'"
                                                class="oder-status-note" [innerHtml]="remarkMessage"></span> -->
                      </div>
                    </ng-container>
                  </div>

                  <div class="refundAlign">
                    <span *ngIf="
                        delivered.includes(selectedItemDetails.latestStatus)
                      " class="oder-status-note1 first-boxxx">
                      <figure class="imageDisplay" *ngIf="!verifyOTP">
                        <ng-container *ngIf="
                            selectedItemDetails &&
                            selectedItemDetails.productImageUrl
                          ">
                          <img class="image" src="{{
                              selectedItemDetails &&
                              selectedItemDetails.productImageUrl
                                ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                                : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                                  selectedItemDetails.productImageUrl +
                                  '?tr=w-300'
                            }}" alt="" width="25%" height="25%" style="border-radius: 4px;"
                            onerror="this.style.display='none'" />
                        </ng-container>
                        <span class="item-name-price">
                          <span [ngClass]="
                              selectedItemDetails.productName.length > 38
                                ? 'overflow-lenght'
                                : ''
                            ">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                            <ng-container *ngIf="selectedItemDetails?.additionalProductInfo?.productDetails?.sku">
                              ({{selectedItemDetails?.additionalProductInfo?.productDetails?.sku}})
                            </ng-container>

                          <span *ngIf="selectedItemDetails.productName.length > 38" class="product-hover">{{
                            selectedItemDetails
                            ? selectedItemDetails.productName
                            : ""
                            }}</span>
                          <p>
                            {{ rupeeSymbol }}
                            {{
                            selectedItemDetails?.invoiceTotal
                            ? transformDecimal(
                            selectedItemDetails?.invoiceTotal
                            )
                            : transformDecimal(selectedItemDetails?.lineItemTotal)
                            }}
                            <span *ngIf="selectedItemDetails.productSize">| Size
                              {{
                              selectedItemDetails
                              ? selectedItemDetails.productSize
                              : ""
                              }}</span>
                          </p>
                        </span>
                      </figure>
                    </span>
                    <span *ngIf="
                        delivered.includes(selectedItemDetails.latestStatus)
                      " class="oder-status-note1 ">
                      <figure>
                        <span class="center-alignment">
                          <img src="https://ik.imagekit.io/2gwij97w0o/POE/check-success.svg" />

                          <span *ngIf="
                              delivered.includes(
                                selectedItemDetails.latestStatus
                              )
                            " style="padding-left: 6px; color: #5DB075;font-weight: bold;">Delivered</span>
                        </span>

                        <span *ngIf="
                            delivered.includes(selectedItemDetails.latestStatus)
                          " class="oder-status-note">{{ remarkMessage }}</span>

                        <!-- <span class="oder-status-note">
                                                <p *ngIf="selectedItemDetails?.forwardTrackingTimeline?.courierName">
                                                    Courier Name -
                                                    <b>{{selectedItemDetails?.forwardTrackingTimeline?.courierName}}</b>
                                                </p>
                                                <p *ngIf="selectedItemDetails?.forwardTrackingTimeline?.trackingID">
                                                    Tracking number
                                                    <b>#{{selectedItemDetails?.forwardTrackingTimeline?.trackingID}}</b>
                                                </p>
                                            </span> -->
                        <span class="oder-status-note">
                          <a class="update-btn" style="font-size: 13px;font: weight 600;text-decoration: underline;"
                            (click)="openUpdateModal()">See all updates
                          </a>
                          <a class="window-close" *ngIf="
                              delivered.includes(
                                selectedItemDetails.latestStatus
                              ) &&
                              selectedItemDetails?.returnPolicy
                                ?.isReturnable === true &&
                              userSettings?.allowReturns &&
                              !getWindowReturnDate()
                            ">
                            <img src="https://ik.imagekit.io/2gwij97w0o/POE/info.svg" />
                            <span>Exchange/Return window closed on
                              {{ windowDate ? windowDate : "" }}
                            </span>
                          </a>
                        </span>
                      </figure>
                    </span>
                    <ng-container *ngIf="delivered.includes(selectedItemDetails.latestStatus) &&
                        selectedItemDetails?.returnPolicy?.isReturnable ===
                          true &&
                        userSettings?.allowReturns &&
                        getWindowReturnDate()">
                      <span class="oder-status-note1 return-cancel">
                        <figure class="delivered-btn">
                          <span class="cancelbutton" *ngIf="selectedItemDetails &&
                              selectedItemDetails.returnPolicy &&
                              selectedItemDetails?.returnPolicy
                                ?.isReturnable === true
                            ">
                            <a [ngStyle]="getStyleWithoutOpacity()" class="layout" (click)="
                                moveToReturnProcess(
                                  selectedItemDetails.orderItemID
                                )
                              ">Return Item
                              <span [ngStyle]="getStyleWithOpacity()"></span>
                            </a>
                          </span>
                          <div class="icon-tooltip">
                            <a *ngIf="
                                delivered.includes(
                                  selectedItemDetails.latestStatus
                                ) && getWindowReturnDate()
                              ">
                              <img src="https://ik.imagekit.io/2gwij97w0o/POE/info.svg" />
                              <span>
                                Exchange/Return window will be closed on
                                {{ windowDate ? windowDate : "" }}
                              </span>
                            </a>
                            <!-- <a *ngIf="delivered.includes(selectedItemDetails.latestStatus) && ! getWindowReturnDate()">
                                                    <img src="../../../../assets/images/info.svg">
                                                    <span>Exchange/Return window
                                                        closed on {{windowDate?windowDate:''}} </span>
                                                </a> -->
                          </div>
                        </figure>
                      </span>
                    </ng-container>
                  </div>
                </ng-template>
              </ng-container>
              <!--End Here-->

              <!-- Verify OTP -->
              <div class="verify-otp-block" *ngIf="verifyOTP && isMobile">
                <div class="text">
                  Verify OTP to view order details.
                </div>
                <div class="button">
                  <button type="button" class="otp-btn" (click)="sendOTP()">SEND OTP</button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mob-footer">
    <div class="footer-underline" [ngClass]="footerKey && footerKey.length == 1 ? 'one-footer-link' : ''">
      <p>
        <ng-container *ngFor="let keys of footerKey; let i = index">
          <a target="_blank" (click)="open_ace_com(keys[1])">
            {{ keys[0] }}
          </a>
        </ng-container>
      </p>
    </div>
    <div *ngIf="userSettings?.websiteUrl">
      <p class="second-para">
        <a (click)="open_ace_com(userSettings?.websiteUrl)">
          <span class="mobile-store-link"> Go to store </span>
        </a>
      </p>
    </div>
  </div>
  <div class="loader-layover" *ngIf="showLoader">
    <figure>
      <img [src]="
          isTablet || isMobile
            ? 'assets/images/mobile-loader.gif'
            : 'assets/images/track-page-loader.gif'
        " alt="Loader" />
    </figure>
  </div>

  <div class="poweredby-mobile">
    <a href="https://www.eshopbox.com/" target="_blank">
      <p style="word-wrap: no-wrap;">
        <a href="https://www.eshopbox.com/" target="_blank">
          <img src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg" alt="" /></a>Powered by Eshopbox
      </p>
    </a>
  </div>

  <div class="see-update-box" (click)="closeUpdateModal()" [class.SeeUpdatesShow]="isSeeUpdatesOpendelay"></div>
  <div class="main-box main-update-box" [ngStyle]="{ display: isSeeUpdatesOpendelay ? 'block' : '' }"
    [class.SeeUpdatesShow]="isSeeUpdatesOpen">
    <div class="header-box">
      <!------------------------------ forwardTrackingTimeline-------------------------- -->

      <div class="header-text" *ngIf="
          selectedItemDetails?.forwardTrackingTimeline &&
          selectedItemDetails?.timeLine == 'forwardTrackingTimeline'
        ">
        <ng-container *ngIf="
            selectedItemDetails.forwardTrackingTimeline.courierName &&
              selectedItemDetails.forwardTrackingTimeline.trackingID;
            else other
          ">
          <h2>
            Order Updates
            <!-- Shipping partner:
            {{ selectedItemDetails.forwardTrackingTimeline.courierName }} -->
          </h2>
          <p>
            #{{
            selectedItemDetails.forwardTrackingTimeline.trackingID
            ? selectedItemDetails.forwardTrackingTimeline.trackingID
            : "NA"
            }}
          </p>
        </ng-container>

        <ng-template #other>
          <h2>Order updates</h2>
          <p>Tracking ID is not assigned</p>
        </ng-template>
      </div>

      <!------------------------------ reverseTrackingTimeline-------------------------- -->

      <div class="header-text" *ngIf="
          selectedItemDetails?.reverseTrackingTimeline &&
          selectedItemDetails?.timeLine == 'reverseTrackingTimeline'
        ">
        <ng-container *ngIf="
            selectedItemDetails.reverseTrackingTimeline.courierName &&
              selectedItemDetails.reverseTrackingTimeline.trackingID;
            else other
          ">
          <h2>
            Order updates
            <!-- Shipping partner:
            {{ selectedItemDetails.reverseTrackingTimeline.courierName }} -->
          </h2>
          <p>
            #{{
            selectedItemDetails.reverseTrackingTimeline.trackingID
            ? selectedItemDetails.reverseTrackingTimeline.trackingID
            : "NA"
            }}
          </p>
        </ng-container>
        <ng-template #other>
          <h2>Order updates</h2>
          <p>Tracking ID is not assigned</p>
        </ng-template>
      </div>

      <!------------------------------ returnTrackingTimeline-------------------------- -->

      <div class="header-text" *ngIf="
          selectedItemDetails?.reverseTrackingTimeline &&
          selectedItemDetails?.timeLine == 'returnTrackingTimeline'
        ">
        <ng-container *ngIf="
            selectedItemDetails.reverseTrackingTimeline.courierName &&
              selectedItemDetails.reverseTrackingTimeline.trackingID;
            else other
          ">
          <h2>
            Order updates
            <!-- Shipping partner:
            {{ selectedItemDetails.reverseTrackingTimeline.courierName }} -->
          </h2>
          <p>
            #{{
            selectedItemDetails.reverseTrackingTimeline.trackingID
            ? selectedItemDetails.reverseTrackingTimeline.trackingID
            : "NA"
            }}
          </p>
        </ng-container>
        <ng-template #other>
          <h2>Order updates</h2>
          <p>Tracking ID is not assigned</p>
        </ng-template>
      </div>

      <!------------------------------ refundTimeline-------------------------- -->

      <div class="header-text" *ngIf="
          selectedItemDetails?.refundTimeline &&
          selectedItemDetails?.timeLine == 'refundTimeline'
        ">
        <ng-container *ngIf="
            selectedItemDetails.refundTimeline.courierName &&
              selectedItemDetails.refundTimeline.trackingID;
            else other
          ">
          <h2>
            Order updates
            <!-- Shipping partner:
            {{ selectedItemDetails.refundTimeline.courierName }} -->
          </h2>
          <p>
            #{{
            selectedItemDetails.refundTimeline.trackingID
            ? selectedItemDetails.refundTimeline.trackingID
            : "NA"
            }}
          </p>
        </ng-container>
        <ng-template #other>
          <h2>Order updates</h2>
          <p>Tracking ID is not assigned</p>
        </ng-template>
      </div>
      <button (click)="closeUpdateModal()"></button>
    </div>
    <!-- </div>  -->
    <div class="bottom-box" id="specificDiv" *ngIf="isSeeUpdatesOpen">
      <ng-container *ngIf="
          selectedItemDetails?.refundTimeLine &&
          selectedItemDetails?.timeLine == 'refundTimeline' &&
          !show_forward_reverse_timeline
        ">
        <div class="timeline-box" *ngFor="
            let status of (selectedItemDetails?.refundTimeLine.statusLog
              | reverse);
            let i = index
          ">
          <h2 class="date">
            {{ status?.updatedAt ? replaceDate(status.updatedAt) : "" }}
          </h2>
          <div class="time-action">
            <span class="time-box">{{
              status.updatedAt.substring(11, 19) | convert24hrsTo12hrs
              }}</span>{{ status_content }}
            <div class="right-timeline">
              <h2 class="status-box">
                {{
                status.status == "created"
                ? "Requested"
                : (status.status | replaceString | titlecase)
                }}
              </h2>
              <!-- <h2 class="status-box"  >{{status.status | replaceString | titlecase  }}</h2> -->

              <p class="location-box">
                {{ status.remark ? status.remark : " " }}
              </p>

              <p class="location-box">
                {{ status.location ? status.location : " " }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <!---------------------------------------------------reverseTrackingTimeline----------------------------------------------------->
      <ng-container *ngIf="
          selectedItemDetails?.reverseTrackingTimeline &&
          selectedItemDetails?.timeLine == 'reverseTrackingTimeline' &&
          !show_forward_reverse_timeline
        ">
        <div class="new-update-design">
          <app-see-update-data [isSeeUpdatesOpen]="isSeeUpdatesOpen" [selectedItemDetails]="selectedItemDetails"
            [seeUpdatesData]="seeUpdatesData" [timelineStatus]="'reverse'">
          </app-see-update-data>
        </div>
      </ng-container>

      <!---------------------------------------------------returnTrackingTimeline----------------------------------------------------->
      <ng-container *ngIf="
          selectedItemDetails?.reverseTrackingTimeline &&
          selectedItemDetails?.timeLine == 'returnTrackingTimeline' &&
          !show_forward_reverse_timeline
        ">
        <div class="new-update-design">
          <app-see-update-data [isSeeUpdatesOpen]="isSeeUpdatesOpen" [selectedItemDetails]="selectedItemDetails"
            [seeUpdatesData]="seeUpdatesData" [timelineStatus]="'return'">
          </app-see-update-data>
        </div>
      </ng-container>

      <!---------------------------------------------------forwardTrackingTimeline----------------------------------------------------->
      <ng-container *ngIf="
          selectedItemDetails?.forwardTrackingTimeline &&
          selectedItemDetails?.timeLine == 'forwardTrackingTimeline' &&
          !show_forward_reverse_timeline
        ">
        <div class="new-update-design">
          <app-see-update-data [isSeeUpdatesOpen]="isSeeUpdatesOpen" [selectedItemDetails]="selectedItemDetails"
            [seeUpdatesData]="seeUpdatesData" [timelineStatus]="'forward'">
          </app-see-update-data>
        </div>
      </ng-container>

      <!---------------------------------------------------CancelTimeline----------------------------------------------------->
      <ng-container *ngIf="
          selectedItemDetails?.forwardTrackingTimeline &&
          selectedItemDetails?.reverseTrackingTimeline &&
          show_forward_reverse_timeline
        ">
        <div class="new-update-design">
          <app-see-update-data [isSeeUpdatesOpen]="isSeeUpdatesOpen" [selectedItemDetails]="selectedItemDetails"
            [seeUpdatesData]="seeUpdatesData" [timelineStatus]="'forward'">
          </app-see-update-data>
        </div>
        <div class="new-update-design">
          <app-see-update-data [isSeeUpdatesOpen]="isSeeUpdatesOpen" [selectedItemDetails]="selectedItemDetails"
            [seeUpdatesData]="seeUpdatesDataCancel" [timelineStatus]="'reverse'">
          </app-see-update-data>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="help-box" (click)="closeHelpModal()" [class.helpShow]="isHelpOpendelay"></div>
  <div class="main-helpbox" [ngStyle]="{ display: isHelpOpendelay ? 'block' : '' }" [class.helpShow]="isHelpOpen">
    <app-help [userSettings]="userSettings" (closeHelpModal)="closeHelpModal()">
    </app-help>
  </div>
</div>
