"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var rxjs_1 = require("rxjs");
var constants_1 = require("../shared/constants/constants");
// import { GetOrder } from "./actions/order-placed.actions";
// import { getCurrentOrder, getSelectedItem } from "./reducers/selectors";
var common_1 = require("@angular/common");
var material_1 = require("@angular/material");
var moment = require("moment");
var ngx_device_detector_1 = require("ngx-device-detector");
var information_popover_component_1 = require("../shared/components/information-popover/information-popover.component");
var services_1 = require("../shared/services");
var index_1 = require("../shared/services/index");
var scrolling_service_1 = require("../shared/services/scrolling.service");
var shared_service_1 = require("../shared/services/shared.service");
var order_placed_actions_1 = require("./actions/order-placed.actions");
var selectors_1 = require("./reducers/selectors");
var OrderPlacedComponent = /** @class */ (function () {
    // dialog: MatDialog;
    function OrderPlacedComponent(preventScroll, router, store, alertService, route, datePipe, decimalPipe, titleService, deviceService, sharedService, sanitizer, orderService, orderReturnService, dialog, auth) {
        this.preventScroll = preventScroll;
        this.router = router;
        this.store = store;
        this.alertService = alertService;
        this.route = route;
        this.datePipe = datePipe;
        this.decimalPipe = decimalPipe;
        this.titleService = titleService;
        this.deviceService = deviceService;
        this.sharedService = sharedService;
        this.sanitizer = sanitizer;
        this.orderService = orderService;
        this.orderReturnService = orderReturnService;
        this.dialog = dialog;
        this.auth = auth;
        this.dateFormat = constants_1.DATE_FORMAT;
        this.leftPanelActive = false;
        // orderUrlIDs;
        this.arr_seeAllUpdates = [];
        // orderId: any;
        this.rupeeSymbol = constants_1.RUPEE_SYMBOL;
        this.showLoader = true;
        this.subscriptions$ = new rxjs_1.Subscription();
        this.userSettingssubscriptions$ = new rxjs_1.Subscription();
        this.modifyStatusLog = [];
        this.reverseTimelineStatusLog = [];
        this.forwardStatusArray = constants_1.FORWARD_STATUS.FORWARD_ORDER_STATUS_TIMELINE;
        this.forwardIntransitStatus = constants_1.FORWARD_STATUS.INTRANSIT_ALL_STATUS;
        this.cancellationStatusArray = constants_1.FORWARD_STATUS.CANCEL_STATUS;
        this.reverseStatusArray = constants_1.REVERSE_STATUS.REVERSE_ORDER_STATUS_TIMELINE;
        this.reverseStatusRename = constants_1.REVERSE_STATUS.REVERSE_ORDER_STATUS_RENAME;
        this.remarkStatusArray = constants_1.REVERSE_STATUS.REMARK_REVERSE_STATUS;
        this.remarkDummyStatusArray = constants_1.REVERSE_STATUS.REMARK_REVERSE_STATUS_DUMMY;
        this.reverseIntransitStatus = constants_1.REVERSE_STATUS.INTRANSIT_ALL_STATUS;
        this.statusActiveClass = constants_1.STATUS_ACTIVE_CLASS;
        this.refundStatusArray = constants_1.REFUND_STATUS.REFUND_STATUS_TIMELINE;
        this.reverseTimelineCancelButtonStatus = constants_1.REVERSE_STATUS.REVERSE_STATUS_FOR_CANCEL_RETURN_BUTTON;
        this.showMiddleTimlineActive = false;
        this.returnTypeExchange = constants_1.RETURN_TYPE_EXCHANGE;
        this.returnTypeReplace = constants_1.RETURN_TYPE_REPLACE;
        this.returnCancelledTimelineStatus = constants_1.REVERSE_STATUS.RETURN_CANCELLED_STATUS;
        this.shippedStatusCount = 0;
        this.isHelpOpen = false;
        this.isHelpOpendelay = false;
        this.isSeeUpdatesOpen = false;
        this.isSeeUpdatesOpendelay = false;
        /*---------------------------------Start-------------------------*/
        this.placed = constants_1.FORWARD_STATUS.FORWARD_PLACED_STATUS;
        this.packed = constants_1.FORWARD_STATUS.FORWARD_PACKED_STATUS;
        this.shipped = constants_1.FORWARD_STATUS.FORWARD_SHIPPED_STATUS;
        this.Delivery_failed = constants_1.FORWARD_STATUS.FORWARD_SHIPPED_DELIVERYFAILD_STATUS;
        this.out_for_delivery = constants_1.FORWARD_STATUS.FORWARD_OUTFORDILEVRY_STATUS;
        this.intransit_status = constants_1.FORWARD_STATUS.FORWARD_ALL_INTRANSIT_STATUS;
        this.shipment_delay = constants_1.FORWARD_STATUS.FORWARD_SHIPMENT_DELAY_STATUS;
        this.delivered = constants_1.FORWARD_STATUS.FORWARD_DELIVERED_STATUS;
        /*---------------------------------Start REVERSE_STATUS-------------------------*/
        this.requested = constants_1.REVERSE_STATUS.REVERSE_RETURN_REQUESTED_STATUS;
        this.approved = constants_1.REVERSE_STATUS.REVERSE_RETURN_APPROVED_STATUS;
        this.pickup = constants_1.REVERSE_STATUS.REVERSE_RETURN_OUTFOR_PICKUP_STATUS;
        this.fail_delivery = constants_1.REVERSE_STATUS.REVERSE_RETURN_PICK_UP_FAILED_STATUS;
        this.pickup_pending = constants_1.REVERSE_STATUS.REVERSE_RETURN_PICK_UP_PENDING_STATUS;
        this.reverse_all_status = constants_1.REVERSE_STATUS.REVERSE_STATUS_TIMELINE;
        /*---------------------------------Start WAREHOUSE_STATUS-------------------------*/
        this.warehouse_delivered = constants_1.REVERSE_STATUS.WAREHOUSE_STATUS;
        this.order_placed = constants_1.REVERSE_STATUS.ORDER_PLACED;
        this.cancellationStatusArrayNew = constants_1.FORWARD_STATUS.CANCEL_STATUS_NEW;
        this.allowCancel = constants_1.FORWARD_STATUS.CANCEL;
        this.isReadMore = true;
        this.remark = "THis is long text replaced with read more/less link example in Angular application paragraph text shown here continue continue THis is long text replaced w";
        this.CancelItemToster = false;
        this.userdetailsinfo = false;
        this.verifyOTP = false;
        // Variable to maintain order of Map while looping using ngFor
        this.originalOrder = function (a, b) {
            return 0;
        };
        this.expandedIndex = -1;
        this.verifyOTP = this.auth.getTokenType() == 'partial' && !this.auth.isFromShopify ? true : false;
        localStorage.removeItem('partialVisit');
    }
    OrderPlacedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isDesktopDevice = this.deviceService.isDesktop();
        var isSafari = navigator.vendor &&
            navigator.vendor.indexOf("Apple") > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf("CriOS") == -1 &&
            navigator.userAgent.indexOf("FxiOS") == -1;
        var el = document.createElement("p");
        this.browserSafari = isSafari;
        // console.log("Is it Safari:", this.browserSafari);
        if (index_1.LocalStorageService.get("cancelItemRequestTrue")) {
            this.CancelItemToster = true;
        }
        if (index_1.LocalStorageService.get("returnRequest")) {
            this.returnItemToster = true;
        }
        if (index_1.LocalStorageService.get("failedDeliverTostermsg")) {
            this.failedDeliveryRequest = true;
        }
        if (index_1.LocalStorageService.get("editdetailsTostermsg")) {
            this.editdetailsRequest = true;
        }
        if (index_1.LocalStorageService.get("failedDelivernegativeMsg")) {
            this.failedDeliverynegative = true;
        }
        this.isJumpToFirst = localStorage.getItem('isJumpToFirst');
        var date = new Date();
        this.todaysDate = this.datePipe.transform(date, this.dateFormat);
        // this.datePipe.transform(date, this.dateFormat);
        this.EddtodaysDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        // this.todaysDate = date;
        // if (!localStorage.getItem('foo')) {
        //   localStorage.setItem('foo', 'no reload')
        //   window.location.reload();
        // } else {
        //   localStorage.removeItem('foo')
        // }
        this.statusData = this.sharedService.getData();
        if (this.statusData) {
            this.leftPanelActive = this.statusData;
        }
        if (this.isJumpToFirst) {
            this.showLeftPanel();
        }
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.flushSomeStorageData();
        this.orderId = this.route.snapshot.paramMap.get("orderId")
            ? this.route.snapshot.paramMap.get("orderId")
            : "";
        this.orderItemId = this.route.snapshot.paramMap.get("orderItemId")
            ? this.route.snapshot.paramMap.get("orderItemId")
            : "";
        /*Get order data from state*/
        this.store.dispatch(new order_placed_actions_1.GetOrder(this.orderId));
        this.subscriptions$.add(this.store.pipe(store_1.select(selectors_1.getCurrentOrder)).subscribe(function (result1) {
            if (result1) {
                _this.showLoader = false;
                _this.orderData = result1;
            }
            else {
                _this.orderData = [];
                _this.selectedItemDetails = [];
            }
        }));
        setTimeout(function () {
            index_1.LocalStorageService.flush("failedDelivernegativeMsg");
            _this.failedDeliverynegative = false;
        }, 10000);
        this.subscriptions$ = this.store
            .select(function (state) { return state.orderPlaced.orderPlaced; })
            .subscribe(function (result) {
            if (result) {
                _this.orderData = result;
                // this.orderData['riskScore'] = 'high';
                // this.orderData['riskScoreReason'] = ['Address issue'];
                // this.orderData['pincodeServiceable'] = false;
                // console.log("this.orderData ",this.orderData );
                index_1.LocalStorageService.set("orderTotal", _this.orderData.orderTotal);
            }
        });
        this.resolutionCode = index_1.LocalStorageService.get("resolutionCode");
        this.resolutionCodeContent = index_1.LocalStorageService.get("resolutionCodeContent");
        if (index_1.LocalStorageService.get("failedDeliverTostermsg")) {
            this.store.dispatch(new order_placed_actions_1.GetOrder(this.orderId));
            this.subscriptions$.add(this.store.pipe(store_1.select(selectors_1.getCurrentOrder)).subscribe(function (result1) {
                if (result1) {
                    _this.orderData = result1;
                    _this.showLoader = false;
                    // console.log("this.orderData",this.orderData);
                    // LocalStorageService.set('channelSlug',this.orderData.channelSlug);
                    // this.defaultSelectedItem(this.orderData);
                }
                else {
                    _this.orderData = [];
                    _this.selectedItemDetails = [];
                }
            }));
            setTimeout(function () {
                index_1.LocalStorageService.flush("failedDeliverTostermsg");
                _this.failedDeliveryRequest = false;
            }, 10000);
        }
        /*Get user settings data from state*/
        // this.userSettingssubscriptions$ = this.store.select(state => state.orderPlaced.userSettings).subscribe(result => {
        //   if (result) {
        //     this.userSettings = result;
        //     this.getColor = this.userSettings.actionColor;
        //     LocalStorageService.set('getColor', this.getColor)
        //     console.log('result',result);
        //     this.footerKey = Object.entries(this.userSettings.footerLinks);
        //   }
        // });
        this.store.dispatch(new order_placed_actions_1.GetUserSettings());
        this.subscriptions$.add(this.store.pipe(store_1.select(selectors_1.getUserSettings)).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.getColor = _this.userSettings.actionColor;
                if (_this.userSettings.footerLinks) {
                    _this.footerKey = Object.entries(_this.userSettings.footerLinks);
                }
                _this.bgcolor = _this.userSettings.noticeBackgroundColor;
                _this.titleService.setTitle("Track Order");
            }
            else {
                _this.userSettings = [];
            }
        }));
        setTimeout(function () {
            _this.closetosterclick();
            _this.returnItemToster = false;
            _this.CancelItemToster = false;
            index_1.LocalStorageService.flush("returnRequest");
            index_1.LocalStorageService.flush("cancelItemRequestTrue");
            index_1.LocalStorageService.flush("editdetailsTostermsg");
            _this.editdetailsRequest = false;
        }, 10000);
    };
    OrderPlacedComponent.prototype.editdetailsevent = function (event) {
        var _this = this;
        this.editdetailsRequest = true;
        setTimeout(function () {
            _this.closetosterclick();
            _this.returnItemToster = false;
            _this.CancelItemToster = false;
            index_1.LocalStorageService.flush("returnRequest");
            index_1.LocalStorageService.flush("cancelItemRequestTrue");
            index_1.LocalStorageService.flush("editdetailsTostermsg");
            _this.editdetailsRequest = false;
        }, 10000);
    };
    OrderPlacedComponent.prototype.getStyleWithoutOpacity = function () {
        return {
            border: "1px solid" + this.userSettings.actionColor,
            color: this.userSettings.actionColor
            //  opacity:0.6,
        };
    };
    OrderPlacedComponent.prototype.getStyletakeaction = function () {
        return {
            // background: this.userSettings.actionColor,
            border: "1px solid" + this.userSettings.actionColor,
            color: this.userSettings.actionColor
            //  opacity:0.6,
        };
    };
    OrderPlacedComponent.prototype.getStyleWithOpacity = function () {
        return {
            background: this.userSettings.actionColor,
            opacity: 0.05
        };
    };
    OrderPlacedComponent.prototype.expandRow = function (index) {
        this.expandedIndex = index === this.expandedIndex ? -1 : index;
    };
    /**
     * move to cancellation screen
     * @method moveTocancellation
     */
    OrderPlacedComponent.prototype.moveToCancellation = function (itemId) {
        if (status === "intransit") {
            this.alertService.showError(constants_1.CONSTANTS.ALERT_MESSAGES.CANCEL_ITEM_INTRANSIT_STATUS_MESSAGE);
        }
        else {
            this.router.navigate([constants_1.ROUTES.ORDER_CANCEL, this.orderId, itemId]);
        }
    };
    /**
     * ON Select Item show item details
     */
    OrderPlacedComponent.prototype.onSelectShowOrderItemDetail = function (item) {
        var _this = this;
        if (item &&
            (item.latestStatus === "failed_delivery" ||
                item.latestStatus === "rto_failed")) {
            if (item.timeLine != "forwardTrackingTimeline")
                item.latestStatus =
                    item.reverseTrackingTimeline.statusLog[item.reverseTrackingTimeline.statusLog.length - 2].status;
            else if (item.timeLine == "forwardTrackingTimeline" &&
                item.latestStatus === "rto_failed")
                item.latestStatus =
                    item.forwardTrackingTimeline.statusLog[item.forwardTrackingTimeline.statusLog.length - 2].status;
        }
        if (item && !item.latestStatus && item.orderItemStatus) {
            item.latestStatus = item.orderItemStatus.toLowerCase();
        }
        // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, item.orderItemID]);
        this.modifyStatusLog = null;
        this.reverseTimelineStatusLog = null;
        this.refundTimelineStausLog = null;
        //forward tracking timeline
        if (item.forwardTrackingTimeline &&
            item.timeLine === "forwardTrackingTimeline") {
            var oldData = Object.assign(item.forwardTrackingTimeline.statusLog);
            this.seeUpdateModifyArray(oldData);
            this.forwardTrackingTimelinestatusLog = this.removeDuplicateUsingFilter(item.forwardTrackingTimeline.statusLog, false);
            this.modifyStatusLog = this.modifyStatusArray(item.forwardTrackingTimeline.statusLog, this.forwardStatusArray);
            this.shippedStatusCount = 0;
            this.modifyStatusLog.compareStatus.forEach(function (element) {
                if (_this.forwardIntransitStatus.includes(element)) {
                    _this.shippedStatusCount++;
                }
            });
        }
        //reverse tracking timeline
        else if (item.reverseTrackingTimeline &&
            item.timeLine === "reverseTrackingTimeline") {
            this.seeUpdateModifyArray(item.reverseTrackingTimeline.statusLog);
            // this.forwardTrackingTimelinestatusLog = this.removeDuplicateUsingFilter(item.reverseTrackingTimeline.statusLog, false);
            // item.reverseTrackingTimeline.statusLog = this.removeDuplicateUsingFilter(
            //   item.reverseTrackingTimeline.statusLog,
            //   false
            // );
            this.reverseTimelineStatusLog = this.modifyStatusArray(item.reverseTrackingTimeline.statusLog, this.reverseStatusArray);
        }
        else if (item.reverseTrackingTimeline &&
            item.timeLine === "returnTrackingTimeline") {
            this.seeUpdateModifyArray(item.reverseTrackingTimeline.statusLog);
            // this.forwardTrackingTimelinestatusLog = this.removeDuplicateUsingFilter(item.reverseTrackingTimeline.statusLog, false);
            // item.reverseTrackingTimeline.statusLog = this.removeDuplicateUsingFilter(
            //   item.reverseTrackingTimeline.statusLog,
            //   false
            // );
            this.reverseTimelineStatusLog = this.modifyStatusArray(item.reverseTrackingTimeline.statusLog, this.reverseStatusArray);
        }
        //refund timeline
        else if (item.refundTimeLine && item.timeLine === "refundTimeLine") {
            item.refundTimeLine.statusLog = this.removeDuplicateUsingFilter(item.refundTimeLine.statusLog, true);
            this.refundTimelineStausLog = this.modifyStatusArray(item.refundTimeLine.statusLog, this.refundStatusArray);
        }
        item.modifyStatusLog = [];
        item.reverseTimelineStatusLog = [];
        item.refundTimelineStausLog = [];
        item.modifyStatusLog.push(this.modifyStatusLog);
        item.reverseTimelineStatusLog.push(this.reverseTimelineStatusLog);
        item.refundTimelineStausLog.push(this.refundTimelineStausLog);
        index_1.LocalStorageService.flush("processOrderItemDetails");
        index_1.LocalStorageService.set("processOrderItemDetails", item);
        this.selectedItemDetails = item;
        this.selectedItemDetails.forwardTrackingTimeline.statusLog.map(function (status) {
            if (status.status == 'intransit') {
                _this.previousStatus = 'intransit';
            }
        });
        this.invoiceUrl = this.selectedItemDetails.invoiceUrl;
        this.SecureinvoiceUrl = this.sanitizer.bypassSecurityTrustUrl(this.invoiceUrl);
        // console.log("dfsdfdf",this.selectedItemDetails);
        this.showLoader = false;
        for (var _i = 0, _a = this.selectedItemDetails.forwardTrackingTimeline
            .statusLog; _i < _a.length; _i++) {
            var value = _a[_i];
            if (value.status == "delivered") {
                var deliveredDate = value.updatedAt;
                // this.deliveredDate = this.datePipe.transform(
                //   deliveredDate,
                //   this.dateFormat);
                deliveredDate = deliveredDate.replace("T", " ");
                this.deliveredDate = deliveredDate;
            }
            if (this.Delivery_failed.includes(value.status)) {
                index_1.LocalStorageService.set("failed_delivery_remark", value.remark);
            }
        }
        if (this.selectedItemDetails.refundTimeLine) {
            if (this.selectedItemDetails.refundTimeLine.refundMode ==
                "originalPaymentMode") {
                this.selectedItemDetails.refundTimeLine.refundMode =
                    "original Payment Mode";
            }
            else {
                this.selectedItemDetails.refundTimeLine.refundMode;
            }
        }
    };
    // public seeUpdateModifyArray(trackingData, timeline?: string) {
    //   // console.log("rr",trackingData);
    //   if(!timeline) this.seeUpdatesData.clear();
    //   this.seeUpdatesDataCancel.clear();
    //   for (let data of trackingData) {
    //     let trackingStatus: string = data["status"];
    //     if (this.seeUpdatesData.has(trackingStatus) || this.seeUpdatesDataCancel.has(trackingStatus)) {
    //       let particularStatusData = this.seeUpdatesData.get(trackingStatus);
    //       particularStatusData.push(data);
    //       timeline ? this.seeUpdatesDataCancel.set(trackingStatus, particularStatusData) : this.seeUpdatesData.set(trackingStatus, particularStatusData);
    //     } else {
    //       let particularStatusData: any = [];
    //       particularStatusData.push(data);
    //       timeline ? this.seeUpdatesDataCancel.set(trackingStatus, particularStatusData) : this.seeUpdatesData.set(trackingStatus, particularStatusData);
    //     }
    //   }
    //   console.log("---------this.seeUpdatesData------",this.seeUpdatesData)
    //   console.log("---------this.seeUpdatesDataCancel------",this.seeUpdatesDataCancel)
    // }
    OrderPlacedComponent.prototype.seeUpdateModifyArray = function (trackingData, timeline) {
        var _this = this;
        // console.log("rr",trackingData);
        if (!timeline)
            this.seeUpdatesData = {};
        this.seeUpdatesDataCancel = {};
        var prevTrackingStatus;
        trackingData.forEach(function (data, index) {
            var trackingStatus = data["status"] + index;
            if (index != 0) {
                timeline
                    ? (prevTrackingStatus = Object.keys(_this.seeUpdatesDataCancel)[Object.keys(_this.seeUpdatesDataCancel).length - 1])
                    : (prevTrackingStatus = Object.keys(_this.seeUpdatesData)[Object.keys(_this.seeUpdatesData).length - 1]);
                if (prevTrackingStatus.startsWith(data["status"])) {
                    timeline
                        ? _this.seeUpdatesDataCancel[prevTrackingStatus].push(data)
                        : _this.seeUpdatesData[prevTrackingStatus].push(data);
                }
                else {
                    var particularStatusData = [];
                    particularStatusData.push(data);
                    timeline
                        ? (_this.seeUpdatesDataCancel[trackingStatus] = particularStatusData)
                        : (_this.seeUpdatesData[trackingStatus] = particularStatusData);
                }
            }
            else {
                var particularStatusData = [];
                particularStatusData.push(data);
                timeline
                    ? (_this.seeUpdatesDataCancel[trackingStatus] = particularStatusData)
                    : (_this.seeUpdatesData[trackingStatus] = particularStatusData);
            }
        });
    };
    OrderPlacedComponent.prototype.formatStatus = function (status) {
        return status.replace(/[0-9]/g, "");
    };
    OrderPlacedComponent.prototype.modifyStatusArray = function (statusLogs, statusArray) {
        this.modifyStatusLog = [];
        this.compareForwardStatus = [];
        for (var i = 0; i < statusLogs.length; i++) {
            this.compareForwardStatus.push(statusLogs[i]["status"]);
            if (statusArray.includes(statusLogs[i]["status"])) {
                this.modifyStatusLog.push(statusLogs[i]["status"]);
            }
        }
        var finalArray = {
            statusLength: this.modifyStatusLog.length,
            compareStatus: this.compareForwardStatus
        };
        return finalArray ? finalArray : null;
    };
    OrderPlacedComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions$) {
            this.subscriptions$.unsubscribe();
        }
        if (this.userSettingssubscriptions$) {
            this.userSettingssubscriptions$.unsubscribe();
        }
    };
    OrderPlacedComponent.prototype.removeDuplicateUsingFilter = function (arr, condition) {
        if (condition === true) {
            var unique_array = Array.from(arr.reduce(function (m, t) { return m.set(t.status, t); }, new Map()).values());
            return unique_array;
        }
        else {
            var arrNew = this.sortArray(arr, "asc");
            var unique_array = this.distinctArray(arrNew);
            return unique_array;
        }
        // console.log("sort", arrNew);
        // const unique_array =  Array.from(arrNew.reduce((m, t) => m.set(t.status, t), new Map()).values());
        // console.log("uni", unique_array);
    };
    OrderPlacedComponent.prototype.distinctArray = function (data) {
        return Array.from(new Set(data.map(function (arr) { return arr.status; }))).map(function (status) {
            var updatedAt = data.filter(function (updatedArr) { return updatedArr.status === status; });
            updatedAt = updatedAt.sort(function (a, b) {
                return Date.parse(a.updatedAt) - Date.parse(b.updatedAt);
            });
            return {
                remark: updatedAt[updatedAt.length - 1].remark,
                status: data.find(function (updatedArr) { return updatedArr.status === status; }).status,
                updatedAt: updatedAt[updatedAt.length - 1].updatedAt,
                location: updatedAt[updatedAt.length - 1].location,
                revisedExpectedDeliveryDate: updatedAt[updatedAt.length - 1].revisedExpectedDeliveryDate
            };
        });
        // console.log(unique);
    };
    OrderPlacedComponent.prototype.sortArray = function (arr, type) {
        if (type === "asc") {
            return arr.sort(function (a, b) {
                return new Date(a.updatedAt) - new Date(b.updatedAt);
            });
        }
        else {
            return arr.sort(function (a, b) {
                return new Date(b.updatedAt) - new Date(a.updatedAt);
            });
        }
    };
    /**
     * move to Return screen
     * @method moveToReturnProcess
     */
    OrderPlacedComponent.prototype.moveToReturnProcess = function (itemId) {
        this.router.navigate([constants_1.ROUTES.ORDER_REFUND_EXCHANGE, this.orderId, itemId]);
        // this.router.navigate([ROUTES.ORDER_RETURN, this.orderId, itemId]);
        index_1.LocalStorageService.flush("itemDetails");
        index_1.LocalStorageService.flush("exchangeItemDetails");
        index_1.LocalStorageService.flush("actionValue");
        index_1.LocalStorageService.flush("refundMode");
        index_1.LocalStorageService.flush("refundDetails");
        index_1.LocalStorageService.flush("returnItemRequest");
        index_1.LocalStorageService.flush("actionType");
        index_1.LocalStorageService.flush("defaultSelected");
    };
    /*Left Panel Show Hide*/
    OrderPlacedComponent.prototype.showLeftPanel = function () {
        var body = document.getElementsByTagName("body")[0];
        body.classList.remove("top-header");
        this.leftPanelActive = false;
    };
    OrderPlacedComponent.prototype.displayLeftPanel = function (value) {
        this.leftPanelActive = true;
        // console.log("left", this.leftPanelActive);
    };
    OrderPlacedComponent.prototype.reverseToolAndMessage = function (latestStatus) {
        var reverseTimeline = this.selectedItemDetails.reverseTrackingTimeline
            .statusLog;
        var returnTypeText = this.selectedItemDetails.returnType === this.returnTypeExchange
            ? "exchange"
            : this.selectedItemDetails.returnType === this.returnTypeReplace
                ? "replacement"
                : "refund";
        // for (let i = 0; i < reverseTimeline.length; i++) {
        for (var i = reverseTimeline.length - 1; i >= 0; i--) {
            if (reverseTimeline[i].status === latestStatus) {
                reverseTimeline[i].updatedAt = reverseTimeline[i].updatedAt.replace(" ", "T");
                switch (latestStatus) {
                    case "created": {
                        this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        if (this.selectedItemDetails.returnType === this.returnTypeExchange) {
                            this.remarkMessage =
                                "Your request to exchange this item has been raised successfully";
                        }
                        else if (this.selectedItemDetails.returnType === this.returnTypeReplace) {
                            // this.remarkMessage = 'Your request to replace this item has been raised successfully';
                            this.remarkMessage =
                                "Your request to return this item has been raised successfully. You will be notified as soon as it is approved.";
                        }
                        else {
                            // this.remarkMessage = 'Your request to return this item has been raised successfully';
                            this.remarkMessage =
                                "Your request to return this item has been raised successfully. You will be notified as soon as it is approved.";
                        }
                        break;
                    }
                    case "approved":
                    case "pickup_pending": {
                        //case "pickup_pending": case "ready_for_pickup": case "shipment_delayed":
                        this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        if (this.selectedItemDetails.returnType === this.returnTypeExchange) {
                            this.remarkMessage =
                                "Your request to exchange this item has been approved";
                        }
                        else if (this.selectedItemDetails.returnType === this.returnTypeReplace) {
                            // this.remarkMessage = 'Your request to replace this item has been approved';
                            this.remarkMessage =
                                "Your request to return this item has been approved. You will be notified as the courier partner is out for pickup";
                        }
                        else {
                            // this.remarkMessage = 'Your request to return this item has been approved';
                            this.remarkMessage =
                                "Your request to return this item has been approved. You will be notified as the courier partner is out for pickup.";
                        }
                        break;
                    }
                    case "carrier_out_for_pick_up_from_customer":
                    case "out_for_pickup": {
                        this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        // this.remarkMessage  = 'Our courier partner is out for the pickup.';
                        // this.remarkMessage =
                        //   "Your item is out for pickup by our courier partner.";
                        this.remarkMessage =
                            "Our courier partner is arriving today for the return pickup.";
                        break;
                    }
                    // case "shipment_delayed": case "pickup_pending": {
                    //   this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                    //   this.remarkMessage = 'Your item is ready to be shipped by our courier partner. You will be notified as soon as it is dispatched.'
                    //   break;
                    // }
                    case "picked_up":
                    case "intransit":
                    case "out_for_delivery": {
                        this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        if (this.selectedItemDetails.returnType === this.returnTypeExchange) {
                            this.remarkMessage =
                                "Your item has been successfully picked up by our courier partner.";
                        }
                        else if (this.selectedItemDetails.returnType === this.returnTypeReplace) {
                            this.remarkMessage =
                                "Your item has been successfully picked up by our courier partner.";
                        }
                        else {
                            this.remarkMessage =
                                "Your item has been successfully picked up by our courier partner.";
                        }
                        break;
                    }
                    case "failed_delivery":
                    case "pickup_failed":
                    case "pickup_cancelled": {
                        //case "pickup_cancelled": case "cancelled_order":
                        if (this.selectedItemDetails.returnType == "replace" ||
                            this.selectedItemDetails.returnType == "exchange") {
                            this.remarkMessage =
                                "We were unable to pickup your item due to failed attempt.";
                        }
                        else {
                            this.remarkMessage =
                                "We were unable to pickup your item due to failed attempt. You will be notified as soon as our courier partner will re-attempt the pickup.";
                        }
                        if (this.selectedItemDetails.returnType === this.returnTypeExchange ||
                            this.selectedItemDetails.returnType === this.returnTypeReplace) {
                            this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        }
                        else {
                            this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        }
                        // this.remarkMessage  = 'We are unable to pickup your item.';
                        // this.remarkMessage='We were unable to pickup your item due to failed attempt. You will be notified as soon as our courier partner will re-attempt the pickup.';
                        //as the courier partner stated "' + reverseTimeline[i].remark + '"';
                        break;
                    }
                    case "ndr_resolution_submitted": {
                        var resolutionDetails = JSON.parse(JSON.parse(reverseTimeline[i].remark));
                        if (resolutionDetails.resolutionCode === constants_1.RESOLUTION_CODE.DELIVERY_DATE) {
                            this.toolTipMessage = this.datePipe.transform(resolutionDetails.deferredDate, this.dateFormat);
                        }
                        else {
                            this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        }
                        this.remarkMessage = this.failedPickupResolutionRemarkMessages(resolutionDetails.resolutionCode);
                        break;
                    }
                    case "intransit": {
                        this.toolTipMessage =
                            returnTypeText === "refund"
                                ? "Refund Request on " +
                                    this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat)
                                : returnTypeText +
                                    " approved on " +
                                    this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        if (returnTypeText === "refund") {
                            this.remarkMessage =
                                "We have recieved your refund request for this item.";
                        }
                        else {
                            // this.remarkMessage = '<p>Your item has been successfully recieved and your ' + returnTypeText + ' request has been approved. Please click <a href="/track/' + this.orderData.customerOrderNumber + '/' + this.selectedItemDetails.newOrderItemID + '" >click here</a> to track your ' + returnTypeText + ' item</p>';
                            this.remarkMessage =
                                "<p>Your " +
                                    returnTypeText +
                                    ' request has been approved.<a href="/track/' +
                                    this.orderData.customerOrderNumber +
                                    "/" +
                                    this.selectedItemDetails.newOrderItemID +
                                    '" >Click here</a> to track your new item</p>';
                        }
                        break;
                    }
                    case "warehouse_delivered":
                    case "delivered_warehouse":
                    case "delivered": {
                        this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        if (this.selectedItemDetails.qc) {
                            //TODO change the return type message and give click on button
                            // this.remarkMessage = 'Your ' + this.selectedItemDetails.returnType + ' request has been approved.'
                        }
                        else {
                            this.remarkMessage =
                                "Your returned item has failed our quality check, therefore we are unable to process the " +
                                    this.selectedItemDetails.returnType +
                                    " Please contact " +
                                    this.userSettings.supportEmail +
                                    " for further details.";
                        }
                        break;
                    }
                    case "IN_TRANSIT":
                    case "PICKED_FROM_CUSTOMER":
                    case "shipment_held":
                    case "shipment_delayed": {
                        this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
                        break;
                    }
                    default: {
                        //statements;
                        break;
                    }
                }
                return [this.toolTipMessage, this.remarkMessage];
            }
        }
    };
    OrderPlacedComponent.prototype.forwardToolAndRemark = function (latestStatus) {
        var forwardTimeLine = this.selectedItemDetails.forwardTrackingTimeline
            .statusLog;
        for (var i = 0; i < forwardTimeLine.length; i++) {
            if (forwardTimeLine[i].status === latestStatus) {
                // console.log(forwardTimeLine[i].status);
                forwardTimeLine[i].updatedAt = forwardTimeLine[i].updatedAt.replace(" ", "T");
                switch (latestStatus) {
                    case "created": {
                        this.toolTipMessage = this.datePipe.transform(forwardTimeLine[i].updatedAt, this.dateFormat);
                        // this.remarkMessage = 'Your order has been placed successfully';
                        this.remarkMessage =
                            "Your order has been placed successfully. You will be notified as soon as it is ready to be shipped.";
                        break;
                    }
                    case "packed":
                    case "manifested":
                    case "pickup_pending": {
                        this.toolTipMessage = this.datePipe.transform(forwardTimeLine[i].updatedAt, this.dateFormat);
                        this.remarkMessage =
                            "Your item is ready to be shipped by our courier partner. You will be notified as soon as it is dispatched.";
                        break;
                    }
                    case "dispatched":
                    case "shipment_delayed": {
                        this.toolTipMessage = this.datePipe.transform(forwardTimeLine[i].updatedAt, this.dateFormat);
                        this.remarkMessage =
                            "Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery.";
                        break;
                    }
                    case "intransit": {
                        this.toolTipMessage = this.datePipe.transform(forwardTimeLine[i].updatedAt, this.dateFormat);
                        this.remarkMessage = "Your item has been shipped";
                        break;
                    }
                    case "out_for_delivery": {
                        this.toolTipMessage = this.datePipe.transform(forwardTimeLine[i].updatedAt, this.dateFormat);
                        this.remarkMessage =
                            "Your item is out for delivery by our courier partner."; //Want to return the product?  Confirm Delivery to raise a return request
                        break;
                    }
                    case "failed_delivery":
                    case "delivery_failed": {
                        this.toolTipMessage = this.datePipe.transform(forwardTimeLine[i].updatedAt, this.dateFormat);
                        this.remarkMessage =
                            "We were unable to deliver your item due to failed attempt. You will be notified as soon as our courier partner will re-attempt the delivery.."; //Our courier partner stated "' + forwardTimeLine[i].remark + '"';
                        break;
                    }
                    case "ndr_resolution_submitted": {
                        var resolutionDetails = JSON.parse(JSON.parse(forwardTimeLine[i].remark));
                        if (resolutionDetails.resolutionCode === constants_1.RESOLUTION_CODE.DELIVERY_DATE) {
                            this.toolTipMessage = this.datePipe.transform(resolutionDetails.deferredDate, this.dateFormat);
                        }
                        else {
                            this.toolTipMessage = this.datePipe.transform(forwardTimeLine[i].updatedAt, this.dateFormat);
                        }
                        this.remarkMessage = this.failedDeliveryResolutionRemarkMessages(resolutionDetails.resolutionCode);
                        break;
                    }
                    case "delivered": {
                        this.toolTipMessage = this.datePipe.transform(forwardTimeLine[i].updatedAt, this.dateFormat);
                        this.deliveredDate = forwardTimeLine[i].updatedAt;
                        this.remarkMessage = "Your item has been successfully delivered";
                        break;
                    }
                    default: {
                        //statements;
                        break;
                    }
                }
                return [this.toolTipMessage, this.remarkMessage];
            }
        }
    };
    OrderPlacedComponent.prototype.refundToolAndRemark = function (latestStatus) {
        var refundTimeLine = this.selectedItemDetails.refundTimeLine.statusLog;
        for (var i = 0; i < refundTimeLine.length; i++) {
            if (refundTimeLine[i].status === latestStatus) {
                refundTimeLine[i].dateTime = refundTimeLine[i].dateTime.replace(" ", "T");
                switch (latestStatus) {
                    // case "created":  {
                    //   this.toolTipMessage = this.datePipe.transform(refundTimeLine[i].dateTime, this.dateFormat);
                    //   this.remarkMessage = 'Your refund request has been raised successfully.';
                    //   break;
                    // }
                    case "created":
                    case "approved": {
                        this.toolTipMessage = this.datePipe.transform(refundTimeLine[i].dateTime, this.dateFormat);
                        this.remarkMessage =
                            "Your refund request has been approved successfully. You will be notified as soon as the refund is initiated.";
                        break;
                    }
                    case "refund_initiated": {
                        this.toolTipMessage = this.datePipe.transform(refundTimeLine[i].dateTime, this.dateFormat);
                        // this.remarkMessage = 'Refund of ' + this.rupeeSymbol + ' ' + this.selectedItemDetails.customerPrice + ' will be transferred to your ' + this.selectedItemDetails.refundTimeLine.refundMode + ' in 2-7 working days.';
                        this.remarkMessage =
                            "Refund of " +
                                this.rupeeSymbol +
                                " " +
                                this.selectedItemDetails.customerPrice +
                                "  has been initiated and will be transferred to your " +
                                this.selectedItemDetails.refundTimeLine.refundMode +
                                " within 2-7 working days.  For any further queries, kindly reach out to your financial institution ";
                        break;
                    }
                    case "initiated": {
                        this.toolTipMessage = this.datePipe.transform(refundTimeLine[i].dateTime, this.dateFormat);
                        // this.remarkMessage = 'Refund of ' + this.rupeeSymbol + ' ' + this.selectedItemDetails.customerPrice + ' will be transferred to your ' + this.selectedItemDetails.refundTimeLine.refundMode + ' in 2-7 working days.';
                        this.remarkMessage =
                            "Refund of " +
                                this.rupeeSymbol +
                                " " +
                                this.selectedItemDetails.customerPrice +
                                "  has been initiated and will be transferred to your " +
                                this.selectedItemDetails.refundTimeLine.refundMode +
                                " within 2-7 working days.  For any further queries, kindly reach out to your financial institution ";
                    }
                    default: {
                        //statements;
                        break;
                    }
                }
                return [this.toolTipMessage, this.remarkMessage];
            }
        }
    };
    OrderPlacedComponent.prototype.failedDeliveryResolutionRemarkMessages = function (resolutionCode) {
        switch (resolutionCode) {
            case constants_1.RESOLUTION_CODE.REATTEMPT_DELIVERY: {
                this.resolutionMessage =
                    "Your request to re-attempt the delivery has been submitted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.DELIVERY_DATE: {
                this.resolutionMessage =
                    "Your request to change the delivery date has been submitted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.DIFFERENT_ADDRESS: {
                this.resolutionMessage =
                    "Your request to change the delivery address has been submitted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.CONTACT_NUMBER: {
                this.resolutionMessage =
                    "Your request to add alternate contact number has been submitted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.SHIPMENT_RTO: {
                this.resolutionMessage =
                    "As per your request this item has been cancelled.Please do not accept if delivery is attempted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.FALSE_REASON: {
                this.resolutionMessage =
                    "Your request has been submitted.Our courier partner will re-attempt this delivery at the earliest.";
                break;
            }
            case constants_1.RESOLUTION_CODE.LANDMARK: {
                this.resolutionMessage =
                    "Your request to add landmark has been submitted.";
                break;
            }
            default: {
                this.resolutionMessage = "Your request has beeen submitted";
                break;
            }
        }
        return this.resolutionMessage;
    };
    OrderPlacedComponent.prototype.failedPickupResolutionRemarkMessages = function (resolutionCode) {
        switch (resolutionCode) {
            case constants_1.RESOLUTION_CODE.REATTEMPT_DELIVERY: {
                this.resolutionMessage =
                    "Your request to re-attempt the pickup has been submitted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.DELIVERY_DATE: {
                this.resolutionMessage =
                    "Your request to change the pickup date has been submitted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.DIFFERENT_ADDRESS: {
                this.resolutionMessage =
                    "Your request to change the pickup address has been submitted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.CONTACT_NUMBER: {
                this.resolutionMessage =
                    "Your request to add alternate contact number has been submitted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.SHIPMENT_RTO: {
                this.resolutionMessage =
                    "As per your request this item has been cancelled.Please do not accept if pickup is attempted.";
                break;
            }
            case constants_1.RESOLUTION_CODE.FALSE_REASON: {
                this.resolutionMessage =
                    "Your request has been submitted.Our courier partner will re-attempt this pickup at the earliest.";
                break;
            }
            case constants_1.RESOLUTION_CODE.LANDMARK: {
                this.resolutionMessage =
                    "Your request to add landmark has been submitted.";
                break;
            }
            default: {
                this.resolutionMessage = "Your request has beeen submitted";
                break;
            }
        }
        return this.resolutionMessage;
    };
    OrderPlacedComponent.prototype.flushSomeStorageData = function () {
        index_1.LocalStorageService.flush("returnFlowType");
        index_1.LocalStorageService.flush("actionType");
        index_1.LocalStorageService.flush("cancelItemRequest");
        index_1.LocalStorageService.flush("returnItemRequest");
        index_1.LocalStorageService.flush("returnCancelItemFormRequest");
        index_1.LocalStorageService.flush("failedDeliveryFormRequest");
        index_1.LocalStorageService.flush("exchangeOption");
        localStorage.removeItem('bulkCancel');
        localStorage.removeItem('isJumpToFirst');
    };
    OrderPlacedComponent.prototype.replaceDate = function (date) {
        date = date.replace(" ", "T");
        // let isoDate = new Date(date).toISOString();
        return this.datePipe.transform(date, this.dateFormat);
    };
    OrderPlacedComponent.prototype.replaceTime = function (time) {
        // let timeNew = time.split(" ")[1];
        time = time.replace(" ", "T");
        return this.datePipe.transform(time, " h:mm a");
    };
    OrderPlacedComponent.prototype.replaceDay = function (date) {
        date = date.replace(" ", "T");
        return this.datePipe.transform(date, " EEE, dd MMM");
    };
    /**
     ** 24-Nov-2019
     ** Move to return cancellation process
     */
    OrderPlacedComponent.prototype.moveToReturnCancellation = function (itemId) {
        this.router.navigate([
            constants_1.ROUTES.ORDER_RETURN_CANCELLATION,
            this.orderId,
            itemId
        ]);
    };
    /**
     ** 26-Nov-2019
     ** Moved to failed delivery flow
     */
    OrderPlacedComponent.prototype.moveToFailedDelivery = function (itemId) {
        index_1.LocalStorageService.flush("failedDeliveryFormRequest");
        this.router.navigate([constants_1.ROUTES.ORDER_FAILED_DELIVERY, this.orderId, itemId]);
    };
    /**
     ** 28-Nov-2019
     ** Moved to failed pickup flow
     */
    OrderPlacedComponent.prototype.moveToFailedPickup = function (itemId) {
        index_1.LocalStorageService.flush("failedPickupFormRequest");
        this.router.navigate([constants_1.ROUTES.ORDER_FAILED_PICKUP, this.orderId, itemId]);
    };
    OrderPlacedComponent.prototype.handleClick = function () {
        return true;
    };
    OrderPlacedComponent.prototype.transformDecimal = function (num) {
        return this.decimalPipe.transform(num, "2.");
    };
    OrderPlacedComponent.prototype.openUpdateModal = function (val) {
        if (document.getElementById("specificDiv")) {
            var myDiv = document.getElementById("specificDiv");
            myDiv.scrollTop = 0;
        }
        this.expandedIndex = -1;
        if (val == "cancel") {
            this.show_forward_reverse_timeline = true;
            // this.seeUpdateModifyArray([...this.selectedItemDetails.forwardTrackingTimeline.statusLog, ...this.selectedItemDetails.reverseTrackingTimeline.statusLog]);
            this.seeUpdateModifyArray(this.selectedItemDetails.forwardTrackingTimeline.statusLog);
            this.seeUpdateModifyArray(this.selectedItemDetails.reverseTrackingTimeline.statusLog, "cancel");
        }
        else {
            this.show_forward_reverse_timeline = false;
            if (val == "reverse")
                this.seeUpdateModifyArray(this.selectedItemDetails.reverseTrackingTimeline.statusLog);
            else
                this.seeUpdateModifyArray(this.selectedItemDetails.forwardTrackingTimeline.statusLog);
        }
        this.isSeeUpdatesOpen = !this.isSeeUpdatesOpen;
        this.isSeeUpdatesOpendelay = !this.isSeeUpdatesOpendelay;
        this.preventScroll.disable();
        document.querySelector("body").classList.add("stop-scrolling");
    };
    OrderPlacedComponent.prototype.closeUpdateModal = function () {
        var _this = this;
        document.querySelector("body").classList.remove("stop-scrolling");
        this.isSeeUpdatesOpen = false;
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.isSeeUpdatesOpendelay = false;
            }, 350);
        }
        else
            this.isSeeUpdatesOpendelay = false;
        this.preventScroll.disablescroll();
        this.updateclick(0);
    };
    // Help Modal
    OrderPlacedComponent.prototype.openHelpModal = function () {
        // this.preventScroll.disable();
        this.isHelpOpen = !this.isHelpOpen;
        this.isHelpOpendelay = !this.isHelpOpendelay;
        document.querySelector("body").classList.add("stop-scrolling");
    };
    OrderPlacedComponent.prototype.closeHelpModal = function () {
        var _this = this;
        // this.preventScroll.enable();
        this.isHelpOpen = false;
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.isHelpOpendelay = false;
            }, 350);
        }
        else
            this.isHelpOpendelay = false;
        document.querySelector("body").classList.remove("stop-scrolling");
    };
    OrderPlacedComponent.prototype.openSite = function (siteUrl) {
        window.open(siteUrl, "_blank");
    };
    OrderPlacedComponent.prototype.open_ace_com = function (siteUrl) {
        siteUrl = siteUrl.replace('www.', 'https://');
        window.open(siteUrl, "_blank");
    };
    // getWindowReturnDate() {
    //   // var date = new Date(this.deliveredDate); // Now
    //   // date.setDate(date.getDate() + this.userSettings.returnWindowDays); // Set now + 30 days as the new date
    //   // var d = new Date(this.deliveredDate);
    //   // console.log(this.deliveredDate);
    //   // this.windowDate = this.datePipe.transform(d.toLocaleString(), this.dateFormat);
    //   if (this.deliveredDate) {
    //     var date1: any;
    //     date1 = new Date(this.deliveredDate);
    //     var date2: any;
    //     date2 = new Date();
    //     date1.setDate(date1.getDate() + this.userSettings.returnWindowDays);
    //     let diff = (date2 - date1) / (1000 * 3600 * 24);
    //     // var date1 = new Date(this.deliveredDate);
    //     // var date2 = new Date();
    //     // // this.windowDate = this.datePipe.transform(date1);
    //     this.windowDate = this.datePipe.transform(date1, this.dateFormat);
    //     // var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    //     // var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    //     if (diff <= this.userSettings.returnWindowDays) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // }
    OrderPlacedComponent.prototype.getWindowReturnDate = function () {
        if (this.deliveredDate) {
            var deliveredDate = new Date(this.deliveredDate);
            var currentDate = new Date();
            // Calculate the return window end date
            deliveredDate.setDate(deliveredDate.getDate() + this.userSettings.returnWindowDays);
            // Compare the current date with the return window end date
            var isWithinReturnWindow = currentDate <= deliveredDate;
            // Format the window date for display
            this.windowDate = this.datePipe.transform(deliveredDate, this.dateFormat);
            return isWithinReturnWindow;
        }
        return false;
    };
    // getShipment(status) {
    //   var executed = false;
    //   console.log("selectedItemDetails.forwardTrackingTimeline-------",this.selectedItemDetails.forwardTrackingTimeline)
    //   if (!executed) {
    //     executed = true;
    //     if (this.shipped.includes(status)) {
    //       return (this.shipped_delay_message =
    //         "222Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery.");
    //     }
    //     // else if(status=='dispatched'){
    //     //   return this.shipped_delay_message = "Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery."
    //     // }
    //     else if (this.out_for_delivery.includes(status)) {
    //       return (this.shipped_delay_message =
    //         "Your item is out for delivery by our courier partner.");
    //     }
    //   }
    // }
    OrderPlacedComponent.prototype.getShipment = function (selectedItemDetails) {
        var executed = false;
        for (var i = 0; i < selectedItemDetails.forwardTrackingTimeline.statusLog.length; i++) {
            if (!executed &&
                this.shipment_delay.includes(selectedItemDetails.forwardTrackingTimeline.statusLog[i].status)) {
                executed = true;
                if (this.shipped.includes(selectedItemDetails.forwardTrackingTimeline.statusLog[i - 1].status)) {
                    return (this.shipped_delay_message =
                        "Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery.");
                }
                else if (this.out_for_delivery.includes(selectedItemDetails.forwardTrackingTimeline.statusLog[i].status)) {
                    return (this.shipped_delay_message =
                        "Your item is out for delivery by our courier partner.");
                }
            }
        }
    };
    OrderPlacedComponent.prototype.getShipmentStatus = function (status) {
        var executed = false;
        if (!executed) {
            executed = true;
            if (this.shipped.includes(status)) {
                return "Shipped";
            }
            else if (this.out_for_delivery.includes(status)) {
                return "Out For Delivery";
            }
        }
    };
    OrderPlacedComponent.prototype.getPickupDelay = function (status) {
        var executed = false;
        if (!executed) {
            executed = true;
            if (this.requested.includes(status)) {
                return "Your request to return this item has been raised successfully. You will be notified as soon as it is approved.";
            }
            else if (this.approved.includes(status)) {
                return (this.pickup_delay_message =
                    "Your request to return this item has been approved. You will be notified as the courier partner is out for pickup.");
            }
            else if (this.pickup.includes(status)) {
                return (this.pickup_delay_message =
                    "Your item is out for pickup by our courier partner.");
            }
        }
    };
    OrderPlacedComponent.prototype.getPickupDelay1 = function (status) {
        var executed = false;
        if (!executed) {
            executed = true;
            if (this.requested.includes(status)) {
                return "Return requested";
            }
            else if (this.approved.includes(status)) {
                return "Request approved";
            }
            else if (this.pickup.includes(status)) {
                return "Out for pickup";
            }
        }
    };
    OrderPlacedComponent.prototype.mouseEnter = function (value) {
        this.color = true;
    };
    OrderPlacedComponent.prototype.mouseLeave = function (value) {
        this.color = false;
    };
    OrderPlacedComponent.prototype.getStatus = function (status, timeline) {
        status = status.replace(/[0-9]/g, "");
        if (status == "created") {
            if (timeline == "forward") {
                return "Order placed";
            }
            else if (timeline == "reverse") {
                return "Return created";
            }
        }
        else if (status == "order_placed" && timeline == "forward") {
            return "Order placed";
        }
        else if (status == "accepted") {
            return "Order accepted";
        }
        else if (status == "manifested") {
            return "Order manifested";
        }
        else if (status == "packed") {
            return "Order packed";
        }
        else if ((status == "picked" || status == "picked_up") &&
            timeline == "forward") {
            return "Picked up";
        }
        else if (status == "shipped") {
            return "Order shipped";
        }
        else if (status == "ready_to_ship") {
            return "Order is ready to ship";
        }
        else if (status == "dispatched") {
            return "Order dispatched";
        }
        else if (status == "intransit") {
            return "Order intransit";
        }
        else if (status == "shipment_delayed") {
            return "Shipment has been delayed";
        }
        else if (status == "delivered") {
            return "Order delivered";
        }
        else if (status == "out_for_delivery") {
            return "Order is out for delivery";
        }
        else if (status == "ndr_resolution_submitted") {
            return "Delivery requested by customer";
        }
        else if (status == "rto" || status == "cancelled") {
            return "Order cancelled";
        }
        else if (status == "rto_intransit") {
            return "Return In-transit";
        }
        else if (status == "rto_created") {
            return "Return created";
        }
        else if (status == "rto_delivered_warehouse") {
            return "Return delivered to warehouse";
        }
        else if (status == "rto_out_for_delivery") {
            return "Return is out for delivery";
        }
        else if (status == "rto_shipment_delay") {
            return "Return shipment is delayed";
        }
        else if (status == "rto_requested") {
            return "Return requested";
        }
        else if (status == "not_serviceable") {
            return "Delivery not serviceable";
        }
        else if (status == "failed_delivery" || status == "delivery_failed") {
            if (status == "failed_delivery" && timeline == "forward") {
                return "Failed delivery attempt";
            }
            else if (status == "failed_delivery" && timeline == "reverse") {
                return "Failed pickup attempt";
            }
        }
        else if (status == "pickup_pending") {
            /*---------------------------------Return----------------------------*/
            return "Pickup pending";
        }
        else if (status == "out_for_pickup") {
            return "Out for pickup";
        }
        else if (status == "order_placed" && timeline == "reverse") {
            return " Return Order placed";
        }
        else if (status == "approved") {
            return "Return approved";
        }
        else if ((status == "picked" || status == "picked_up") &&
            timeline == "reverse") {
            return "Return picked up";
        }
        else if (status == "pickup_cancelled") {
            return "Pickup failed";
        }
        else if (status == "return_cancelled") {
            return "Return cancelled";
        }
        else if (status == "delivered_warehouse" ||
            status == "warehouse_delivered") {
            return "Delivered warehouse";
        }
        else {
            return status;
        }
    };
    OrderPlacedComponent.prototype.moveToMarkAsDeliveredPopup = function (itemId) {
        index_1.LocalStorageService.set("actionType", "markAsDelivered");
        console.log(localStorage);
        var mobileNumber = this.orderData.otpPhoneNumber;
        this.orderReturnService.generateOtp(mobileNumber).subscribe(function (data) {
            console.log(data);
        });
        this.router.navigate([constants_1.ROUTES.OTP_CONFIRMATION, this.orderId, itemId]);
        console.log(itemId);
        console.log(this.orderData);
        var item = this.orderData.items.find(function (p) { return p.orderItemID === itemId; });
        console.log(item);
        var timestamp = +Date.now();
        var cur = moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
        console.log(cur);
        var data = {
            actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail") : index_1.LocalStorageService.get('actorcutomername'),
            actorType: localStorage.getItem("clientPortalemail") ? 'seller' : 'buyer',
            status_description: "shipment_Delivered",
            waybill: item.forwardTrackingTimeline.trackingID,
            cp_name: item.forwardTrackingTimeline.courierName,
            status: "Delivered",
            location: "",
            status_code: 8,
            additional: {
                latest_status: {
                    status_description: "Delivered",
                    remark: "Customer manually delivered the order via OTP verification",
                    status: "Delivered",
                    status_code: 8,
                    location: "",
                    timestamp: cur
                }
            },
            timestamp: cur,
            remark: "Delivered"
        };
        index_1.LocalStorageService.set("markAsDeliveredFormRequest", data);
        this.isCancelOpen = true;
        this.preventScroll.disable();
        // console.log("Yayay");
    };
    // closeCancelModal() {
    //   this.isCancelOpen = false;
    //   this.preventScroll.enable();
    // }
    OrderPlacedComponent.prototype.updateclick = function (i) {
        if (this.currentindex === i) {
            this.currentindex = null;
        }
        else {
            this.currentindex = i;
        }
    };
    OrderPlacedComponent.prototype.closetosterclick = function () {
        this.CancelItemToster = false;
        localStorage.removeItem("cancelItemRequestTrue");
        index_1.LocalStorageService.flush("cancelItemRequestTrue");
        this.failedDeliveryRequest = false;
        localStorage.removeItem("failedDeliverTostermsg");
        index_1.LocalStorageService.flush("failedDeliverTostermsg");
        localStorage.removeItem("resolutionCode");
        index_1.LocalStorageService.flush("resolutionCode");
        this.returnItemToster = false;
        index_1.LocalStorageService.flush("returnRequest");
        this.editdetailsRequest = false;
        index_1.LocalStorageService.flush("editdetailsTostermsg");
    };
    OrderPlacedComponent.prototype.getStatusDate = function (value, status) {
        var statusDate;
        var statusLength;
        for (var key in value) {
            if (key.startsWith(status)) {
                statusLength = value[key].length - 1;
                statusDate = value[key][statusLength].updatedAt;
            }
        }
        return statusDate;
    };
    OrderPlacedComponent.prototype.copyInputOda = function (val) {
        var selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
    };
    OrderPlacedComponent.prototype.showEditDetails = function () {
        if (this.auth.getTokenType() == 'partial') {
            this.sendOTP();
            return;
        }
        this.alertService.openAddressPopup(true);
        this.userdetailsinfo = false;
        // OrderSidebarComponent.deliveryPopupdelay = true;
        // OrderSidebarComponent.deliveryPopup = true;
        // LocalStorageService.set("editdetails", true);
        // LocalStorageService.set("Orderdetails", this.orderData);
        // document.querySelector("body").classList.add("hide-footer");
    };
    OrderPlacedComponent.prototype.opendialog = function () {
        var draftDialogRef = this.dialog.open(information_popover_component_1.InformationPopoverComponent, {
            panelClass: ['back-confirm-dialog', 'backpop'],
        });
    };
    OrderPlacedComponent.prototype.closediaog = function () {
        this.dialog.closeAll();
    };
    OrderPlacedComponent.prototype.userdetailclick = function () {
        this.userdetailsinfo = true;
    };
    OrderPlacedComponent.prototype.userdetailclose = function (e) {
        this.userdetailsinfo = false;
    };
    OrderPlacedComponent.prototype.sendOTP = function () {
        this.router.navigate([constants_1.ROUTES.VERIFY_OTP_CONFIRMATION, this.orderId]);
    };
    return OrderPlacedComponent;
}());
exports.OrderPlacedComponent = OrderPlacedComponent;
