import { Injectable } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { delay, dematerialize, materialize, mergeMap } from "rxjs/operators";

const applyFilters = function(request, data) {
  const newData = JSON.parse(JSON.stringify(data));
  let filters = request.params.get("q");
  if (!filters) {
    return newData;
  }
  filters = JSON.parse(filters);
  const allRecords = newData.data;
  newData.data = allRecords.filter(record => {
    let addRecord = true;
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (record[key] && Array.isArray(record[key])) {
          if (!record[key].includes(filters[key])) {
            addRecord = false;
            break;
          }
        } else if (record[key] !== filters[key]) {
          addRecord = false;
          break;
        }
      }
    }
    return addRecord;
  });
  return newData;
};
@Injectable({
  providedIn: "root"
})
export class FakeBackendInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // wrap in delayed observable to simulate server api call
    return of(null).pipe(
      mergeMap(() => {
        // if (request.url.endsWith("/v1/login") && request.method === "POST") {
        //   // find if any user matches login credentials
        //   const users = usersResponse.data;
        //   const filteredUser = users
        //     .filter(user => {
        //       return (
        //         user.email === request.body.data.username &&
        //         user.password === request.body.data.password
        //       );
        //     })
        //     .shift();

        //   if (filteredUser) {
        //     // if login details are valid return 200 OK with user details and fake jwt token
        //     const body = {
        //       id: filteredUser._id,
        //       email: filteredUser.email,
        //       firstName: filteredUser.first_name,
        //       lastName: filteredUser.last_name,
        //       currency: { name: "INR", conversion_rate: 1, decimal_points: 0 },
        //       language: "EN",
        //       role: "member",
        //       token: "fake-jwt-token"
        //     };

        //     return of(new HttpResponse({ status: 200, body: { data: body } }));
        //   } else {
        //     // else return 400 bad request
        //     return throwError("Username or password is incorrect");
        //   }
        // }

        // if (
        //   request.url.endsWith("/v1/routeSummary") &&
        //   request.method === "POST"
        // ) {
        //   const summary = routeSummary.listOfRouteSummary;
        //   //return 200 OK with summary details
        //   const body = {
        //     routeSummary: summary
        //   };

        //   return of(new HttpResponse({ status: 200, body: { data: body } }));
        // }

        //   if (
        //     request.url.endsWith("/v1/simulateRoute") &&
        //     request.method === "POST"
        //   ) {
        //     const summary = simulatedSummary.routeSummaryList;
        //     //return 200 OK with summary details
        //     const body = {
        //       simulatedSummary: summary
        //     };
        //     return of(new HttpResponse({ status: 200, body: { data: body } }));
        //   }

        //   if (
        //     request.url.endsWith("/v1/startPicking") &&
        //     request.method === "POST"
        //   ) {
        //     const summary = picklistSummary.routeSummaryList;
        //     //return 200 OK with summary details
        //     const body = {
        //       picklistSummary: summary
        //     };
        //     return of(new HttpResponse({ status: 200, body: { data: body } }));
        //   }
        // Complete Consignment list Starts
        // if (
        //   request.url.endsWith("/api/Complete_listing_page") &&
        //   request.method === "POST"
        // ) {
        //   const consignmentList = completeConsignmentList;
        //   //return 200 OK with summary details
        //   const body = {
        //     completeConsignmentList: consignmentList
        //   };
        //   console.log(body, "hbdchb");
        //   return of(new HttpResponse({ status: 200, body: { data: body } }));
        // }
        // Complete Consignment list ends

        //   if (
        //     request.url.endsWith("/v1/deliveryAssociates") &&
        //     request.method === "GET"
        //   ) {
        //     const associates = deliveryAssociates.associateList;
        //     //return 200 OK with associate details
        //     const body = {
        //       associates
        //     };
        //     return of(new HttpResponse({ status: 200, body: { data: body } }));
        //   }

        //   if (request.url.includes("/v1/picklist") && request.method === "GET") {
        //     const body = picklistById;
        //     //return 200 OK with associate details
        //     return of(new HttpResponse({ status: 200, body: { data: body } }));
        //   }

        // pass through any requests not handled above
        return next.handle(request);
      }),

      // call materialize and dematerialize to ensure delay even if an error is thrown
      // (https://github.com/Reactive-Extensions/RxJS/issues/648)
      materialize(),
      delay(500),
      dematerialize()
    );
  }
}

export let fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
